import {setDashboard} from './dashboard'
import axios from  'axios'
import * as actionType from './actionTypes'

export const setErrorMessage = (data) => {
  return {
    type: actionType.SUBSCRIBE_LIST,
    payload: {
      data: data
    }
  }
}


// BugFix - To Make List of Events Screen show after deleting an Event
export const switchEventFalse = (data) => {
  return {
    type: actionType.SWITCH_EVENTFALSE,
    payload: {
      data: data
    }
  }
}



export const addList = (_id, list, email, phone, tickettype, guest_details, organization, table_row_number, seat_number) => dispatch => {
  axios.post('/api/lists/create', {_id, list, email, phone, tickettype, guest_details, organization, table_row_number, seat_number})
     .then(res => {
      // console.log("redux res")
         console.log("redux")
      console.log(res.data)
      dispatch(setDashboard(res.data))
    })
    .catch(err => {
      console.log(err.response)
      console.log(err.response.data.mesg)
      // dispatch(setDashboard(err.response))
      // dispatch(setErrorMessage(err.response))
      dispatch({
        type: actionType.SUBSCRIBE_LIST
      })
    })
    
}

export const importList = (_id, item) => dispatch => {
  dispatch({
    type: actionType.IMPORTING_START
  })
  axios.post('/api/lists/import', {_id, item})
     .then(res => {
      // console.log("redux res")
      // console.log(res)
      dispatch(setDashboard(res.data))
      dispatch({
        type: actionType.IMPORTING_FINISH
      })
    })
    .catch(err => {
      dispatch({
        type: actionType.IMPORTING_ERROR
      })
      console.log("redux error")
      console.log(err)
      console.log(err.response)
      console.log(err.response.data.mesg)
      // dispatch(setDashboard(err.response))
      // dispatch(setErrorMessage(err.response))
      // dispatch({
      //   type: actionType.SUBSCRIBE_LIST
      // })
    })
}


export const importupdate = (_id, item) => dispatch => {
  dispatch({
    type: actionType.IMPORTING_UPDATING_START
  })
  axios.put('/api/lists/importupdate', {_id, item})
     .then(res => {
      // console.log("redux res")
      // console.log(res)
      dispatch(setDashboard(res.data))
      dispatch({
        type: actionType.IMPORTING_UPDATING_FINISH
      })
    })
    .catch(err => {
      dispatch({
        type: actionType.IMPORTING_ERROR
      })
      console.log("redux error")
      console.log(err)
      console.log(err.response)
      console.log(err.response.data.mesg)
      // dispatch(setDashboard(err.response))
      // dispatch(setErrorMessage(err.response))
      // dispatch({
      //   type: actionType.SUBSCRIBE_LIST
      // })
    })
}

export const importListDynamic = (_id, item) => dispatch => {
  axios.post('/api/lists/import-dynamic', {_id, item})
     .then(res => {
      // console.log("redux res")
      // console.log(res)
      dispatch(setDashboard(res.data))
    })
    .catch(err => {
      console.log(err.response)
      console.log(err.response.data.mesg)
      // dispatch(setDashboard(err.response))
      // dispatch(setErrorMessage(err.response))
      dispatch({
        type: actionType.SUBSCRIBE_LIST
      })
    })
}

export const import_error_off = () => dispatch => {
    dispatch({
      type: actionType.IMPORTING_ERROR_OFF
    })
}

export const import_loading_off = () => dispatch => {
  dispatch({
    type: actionType.IMPORTING_FINISH
  })
  dispatch({
    type: actionType.IMPORTING_UPDATING_FINISH
  })
}


export const editList = (dashId, id,  text, email, phone, tickettype, guest_details, organization, table_row_number, seat_number, currentSortName) => dispatch => {
  axios.put('/api/lists/update/'+dashId, {
    dashId: id,
    text: text,
    email: email,
    phone: phone,
    tickettype: tickettype,
    guest_details: guest_details,
    organization: organization,
    table_row_number: table_row_number,
    seat_number: seat_number,
    currentSortName
  })
    .then(res => {
      dispatch(setDashboard(res.data))
    })
    .catch(err => {
      console.log(err.response)
    })
}

export const editListProfile = (dashId, id,  text, email, phone, tickettype, guest_details, organization, table_row_number, seat_number, status, currentSortName) => dispatch => {
  axios.put('/api/lists/updateguestprofile/'+dashId, {
    dashId: id,
    text: text,
    email: email,
    phone: phone,
    tickettype: tickettype,
    guest_details: guest_details,
    organization: organization,
    table_row_number: table_row_number,
    seat_number: seat_number,
    status: status,
    currentSortName
  })
    .then(res => {
      dispatch(setDashboard(res.data))
    })
    .catch(err => {
      console.log(err.response)
    })
}


export const deleteList = (id, listId, currentSortName) => dispatch => {
  console.log("redux event id")
  console.log(id)
  console.log("redux list id")
  console.log(listId)

  // axios.delete('/api/lists/delete/'+id+'/'+listId, {params: { sortfield: currentSortName }})
  axios.delete('/api/lists/delete/'+id+'/'+listId)
    .then(res => {
      dispatch(setDashboard(res.data))
    })
    .catch(err => {
      console.log(err.response)
    })
}



export const createListRelationship = (id, selectedguest, guestid,  relationship, relation_back) => dispatch => {
  axios.post('/api/lists/createguestrelationship/'+ id, {
    selectedguest: selectedguest,
    guestid: guestid,
    relationship: relationship,
    relation_back: relation_back
  })
    .then(res => {
      dispatch(setDashboard(res.data))
      window.location.reload()
    })
    .catch(err => {
      console.log(err.response)
      dispatch({
        type: actionType.RELATIONSHIP_CREATED
      })
    })
}

export const updateListRelationship = (id, editRelationship) => dispatch => {
  axios.put('/api/lists/updateguestrelationship/'+ id, {
    editRelationship
  })
    .then(res => {
      dispatch(setDashboard(res.data))
      window.location.reload()
    })
    .catch(err => {
      console.log(err.response)
    })
}

export const deleteListRelationship = (id, selectedguest, guestid) => dispatch => {
  console.log("Redux id")
  console.log(id)
  console.log("Redux selectedguest")
  console.log(selectedguest)
  console.log("Redux guestid")
  console.log(guestid)
  
  axios.delete('/api/lists/deleteguestrelationship/'+ id, {params: { selectedguest: selectedguest,
    guestid: guestid, }})
    .then(res => {
      dispatch(setDashboard(res.data))
    })
    .catch(err => {
      console.log(err.response)
    })
}



export const updateListStatus = (id, listId, currentSortName) => dispatch => {
  axios.put('/api/lists/updateStatus/'+id, {listId, currentSortName} )
  .then(res => {
    dispatch(setDashboard(res.data))
  })
  .catch(err => {
    console.log(err.response)
  })
}



export const clearDashmessage = (data) => {
  return {
    type: actionType.CLEAR_DASHMESSAGE,
    payload: {}
  }
}