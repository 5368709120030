import React, { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import QRCode from "react-qr-code";
import Select from "react-select";
import { Button } from "react-bootstrap";
import * as action from "../../store/actions/index";
import Modal from "react-bootstrap/Modal";
import { isEmpty } from "../../utils/Validation";
import DeleteRelationship from "./DeleteRelationship";
import {Link, useHistory} from 'react-router-dom'
// import Alert from "react-bootstrap/Alert";
import "./Guests.css";

const Guests = (props) => {
  const reduxState = useSelector((state) => state);
  const history = useHistory()
  const dispatch = useDispatch();

  // 9 Basic Guests Fields as of now - 2024
  const [formData, setFormData] = useState({
    account_id: 0,
    list: "",
    organization: "",
    phone: "",
    email: "",
    status: "",
    table_row_number: "",
    seat_number: "",
    tickettype: "",
    guest_details: "",
    checkedin_by: "",
    checkedin_by_username: "",
    _id: "",
    relationships: []
  });


  const [editRelationship, setEditRelationship] = useState({
    list: "",
    email: "",
    guestedit_id: "",
    selectededit_id: "",
    relationshiptypes: "",
    relatedrelationship: ""
  });

  const [relationshipData, setRelationshipData] = useState({
    relationship_type: "",
    relation_back: ""
  })

  const [guestEdit, setGuestEdit] = useState(false);
  const [currentSortName, setCurrentSortName] = useState("list_b");
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [selected_id, setSelected_id] = useState("");
  // const [relationship_id, setRelationship_id] = useState("");
  // const [related_id, setRelated_id] = useState("");
  const [editModalShow, setEditModalShow] = useState(false);

  const [showRelationships, setShowRelationships] = useState(false)

  const [relationship_user, setRelationship_user] = useState("")

  const [permissionoptions, setPermissionOptions] = useState([]);

  const [relationshipguests, setRelationshipguests] = useState([]);

  const [qrobject, setQrObject] = useState({
    id: props.match.params.guestId,
    dashId: props.match.params.eventId,
  });


  // const permissionoptions = [
  //   { value: "Owner", label: "Owner (Account Management)" },
  //   { value: "Admin", label: "Admin (View, Edit and Delete)" },
  //   { value: "Editor", label: "Editor (View and Edit)" },
  //   { value: "Volunteer", label: "Volunteer (View and Checkout)" },
  // ];

  useEffect(() => {

    try {
      let eventfilter = reduxState.dashboard.dashboard.filter(
        (item) => item._id === props.match.params.eventId
      );
      let guestfilter = eventfilter[0].lists.find(
        (item) => item._id === props.match.params.guestId
      );

      let guestfiltermore = eventfilter[0].lists.filter(
        (item) => item._id === props.match.params.guestId
      );

  //  console.log("guestfilter[0].relationshipss")
  //  console.log(reduxState.dashboard.dashboard.filter(
  //   (item) => item._id === props.match.params.eventId[0].relationships.map((item)=>item.related__type)))


  console.log("guestfiltermore[0].relationships")
  console.log(guestfiltermore[0].relationships.map((item)=>item.related__type))
  let relationshiptypes = guestfiltermore[0].relationships.map((item)=>item.related__type)
   
  
  console.log("eventfilter one")
   console.log(eventfilter[0].lists)

   let guestlist = eventfilter[0].lists
   console.log("guestlist")
   console.log(guestlist)

   let relationshipfilter = guestfilter.relationships.map((item)=>item.related__id)
   console.log("relationshipfilter")
   console.log(relationshipfilter)

   console.log("eventfilteragita")

   let array = []

   for(var i = 0; i< guestlist.length; i++){
     for(var j = 0; j< relationshipfilter.length; j++){
     console.log("for loopity")
     if((guestlist[i]._id == relationshipfilter[j]))
     {
      console.log("for loopity success")
       console.log(guestlist[i])
       array.push(guestlist[i])
     }
  //    if(relationshipfilter[i] )
  //  console.log(eventfilter[0].lists.find((item)=> item._id == relationshipfilter[i]))
   }
  }


  for(var k = 0; k< relationshiptypes.length; k++){
    array[k].relationshiptypes = relationshiptypes[k]
  }

  console.log("final arrayy")
  console.log(array)

  setRelationshipguests(array)

    }
    catch{
      console.log("first use effect error")
    }
    // return () => {
    //   second
    // }
  }, [props.match.params.eventId, props.match.params.guestId, reduxState.dashboard.dashboard])
  



  useEffect(() => {
    console.log("reduxState.dashboard");
    console.log(reduxState.dashboard.dashboard);
    console.log(props.match.params);

    console.log("solve for");

    try {
      let eventfilter = reduxState.dashboard.dashboard.filter(
        (item) => item._id === props.match.params.eventId
      );
      let guestfilter = eventfilter[0].lists.filter(
        (item) => item._id === props.match.params.guestId
      );


      setFormData({
        account_id: guestfilter[0].account_id,
        list: guestfilter[0].list,
        organization: guestfilter[0].organization,
        phone: guestfilter[0].phone,
        email: guestfilter[0].email,
        status: guestfilter[0].status,
        table_row_number: guestfilter[0].table_row_number,
        seat_number: guestfilter[0].seat_number,
        tickettype: guestfilter[0].tickettype,
        guest_details: guestfilter[0].guest_details,
        checkedin_by: guestfilter[0].checkedin_by,
        checkedin_by_username: guestfilter[0].checkedin_by_username,
        relationships: guestfilter[0].relationships,
        _id: guestfilter[0]._id,
      });

      console.log("guestfilter[0].relationships")
      console.log(guestfilter[0].relationships)


    } catch {
      console.log("setting error");
    }


  try {
    let eventfilter = reduxState.dashboard.dashboard.filter(
      (item) => item._id === props.match.params.eventId
    );
    let guestfilter = eventfilter[0].lists.filter(
      (item) => item._id === props.match.params.guestId
    );

    let event_guest_names = eventfilter[0].lists.map((arr=>arr.list))
    let event_guest_emails = eventfilter[0].lists.map((arr=>arr.email))
    let event_guest_ids = eventfilter[0].lists.map((arr=>arr._id))

    console.log("eventfilter")
    console.log(event_guest_names)
    console.log(event_guest_emails)

    let temporaryuserarray = []

    for(var i=0; i< event_guest_names.length; i++){
      temporaryuserarray.push({
      value: event_guest_ids[i],
      label: event_guest_names[i] + " (" +  event_guest_emails[i] +")"
    })
  }

  // Warning test this line below - Make Sure filtered guest itself at the end doesn't cause issues.
  let ownNameRelationshipfiltered = temporaryuserarray.filter((item)=>item.value != props.match.params.guestId)
  console.log(ownNameRelationshipfiltered)
  setPermissionOptions(ownNameRelationshipfiltered)

    }
    catch{
      console.log("outside try catch error")
    }

    // axios
    //   .get("/api/dashboard/")
    //   .then((res) => {
    //     console.log(res.data.dashboard);
    //     console.log(
    //     );
    //   })
    //   .catch((err) => {
    //     console.log(err.response);
    //   });
  }, [props.match.params, props.match.params.eventId, props.match.params.guestId, reduxState.dashboard.dashboard]);

  const editGuest = () => {
    setGuestEdit(true);
  };

  const cancelEditGuest = () => {
    setGuestEdit(false);
  };

  const onChange = (e) => {
    // console.log("props.editedEvent")
    // console.log(props.editedEvent[0].name)

    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  let onChangeRelationship = (e) => {

  const { name, value } = e.target;
  console.log(value)

    setRelationshipData({
      ...relationshipData,
      [name]: value,
    });
  };

  let onChangeEditRelationship = (e) => {
    const { name, value } = e.target;
    console.log(value)
  
    setEditRelationship({
        ...editRelationship,
        [name]: value,
      });
    };

  let onChangeEditStatus = (e) => {
    console.log("e.value");
    console.log(e.value);
    let timezone = e.value;
    const firstLetter = timezone.charAt(0);
    const firstLetterCap = firstLetter.toUpperCase();
    const remainingLetters = timezone.slice(1);
    const capitalizedWord = firstLetterCap + remainingLetters;

    setFormData({
      ...formData,
      status: capitalizedWord,
    });
  };

  let onChangePermissions = (e) => {
      console.log("change permissions")
      console.log(e.value)
      setRelationship_user(e.value)

  };

  const onSubmit = (e) => {
    console.log("onsubmit run");
    console.log("formData");
    console.log(formData);

    dispatch(action.editListProfile(
      props.match.params.eventId,
      props.match.params.guestId,
      formData.list,
      formData.email,
      formData.phone,
      formData.tickettype,
      formData.guest_details,
      formData.organization,
      formData.table_row_number,
      formData.seat_number,
      formData.status,
      currentSortName));
    // id: props.match.params.guestId,
    // dashId: props.match.params.eventId,
    setGuestEdit(false);
  };

  let showDeleteModal = (item) => {
    try{

    setDeleteModalShow(true);
    setSelected_id(item._id);
    console.log("delete modal initiated");
    console.log(item);
    let deleting_id = item.relationships.filter((item)=>item.related__id == props.match.params.guestId);
    let deleting_related_id = item.relationships.filter((item)=>item.related__id == props.match.params.guestId);

    // setRelationship_id(deleting_id[0]._id);
    // setRelated_id(deleting_related_id[0].related__id);
    console.log("all three");
    console.log(item._id);
    console.log(deleting_id[0]._id)
    console.log(deleting_related_id[0].related__id)
    }
    catch{
      console.log("delete_relationship_error")
    }
  };

  let deleteModalClose = () => setDeleteModalShow(false);
  let editModalClose = () => setEditModalShow(false);

  const onSubmitRelationship = (e) => {
    console.log("onsubmit relationship run");
    // console.log(relationship_type);
    console.log(relationship_user);
    console.log(relationshipData.relationship_type);
    console.log(relationshipData.relation_back);
    
    dispatch(action.createListRelationship(
      props.match.params.eventId,
      props.match.params.guestId,
      relationship_user,
      relationshipData.relationship_type,
      relationshipData.relation_back
    ));



    try {
      let eventfilter = reduxState.dashboard.dashboard.filter(
        (item) => item._id === props.match.params.eventId
      );
      let guestfilter = eventfilter[0].lists.filter(
        (item) => item._id === props.match.params.guestId
      );

      console.log("guestfilter[0].relationships")
      console.log(guestfilter[0].relationships)

    } catch {
      console.log("setting error");
    }

    setShowRelationships(false)

      const MINUTE_MS = 5000;
        setTimeout(() => {
        dispatch(action.clearDashmessage());
      }, MINUTE_MS);
  };


  let on_hide_relationships = () => {
    setShowRelationships(false)
  }


  let deleteuser = (id) => {
    console.log("deletingRelationship");
    console.log(id);
    // console.log(selected_id);
    // dispatch(actions.volunteerdelete(related_id));

    dispatch(action.deleteListRelationship(
      props.match.params.eventId,
      selected_id,
      props.match.params.guestId,
      ));

    console.log("submit response")
    console.log(props.match.params.eventId)
    console.log(selected_id)
    console.log(props.match.params.guestId)
    // console.log(relationship_id)

// 65e589d26666072d9b040c8b - Event
// 65e58a226666072d9b040c9c - Me
// 65e58a226666072d9b040ca1 - Sonya

// 65e58a3d6666072d9b040cb7

    // console.log(related_id)
    setDeleteModalShow(false);
  };

  const edit_relationship = (item) => {
    console.log("row clicked")
    console.log("item details")
    console.log(item)

    let guestid = item._id



    let eventfilter = reduxState.dashboard.dashboard.filter(
      (item) => item._id === props.match.params.eventId)

    let guestfilter = eventfilter[0].lists.filter((item)=>item._id == guestid)

    console.log("eventfilteroo")
    console.log(guestfilter)
    let relationshipfilter = guestfilter[0].relationships.filter((item)=>item.related__id)
    console.log(relationshipfilter)
    let relatedrelationship = relationshipfilter[0].related__type

    setEditRelationship({
      list: item.list,
      email: item.email,
      guestedit_id: item._id,
      relationshiptypes: item.relationshiptypes,
      selectededit_id: props.match.params.guestId,
      relatedrelationship: relatedrelationship
    });

    setEditModalShow(true)
  }

  const submitEdit = () => {
    console.log("submit edit")
    // console.log(e)
    console.log("Edit_data")
    console.log(editRelationship)


    dispatch(action.updateListRelationship(
      props.match.params.eventId,
      editRelationship
      ));
  }

  const eventchapter = [
    { value: "Unconfirmed", label: "Unconfirmed" },
    { value: "Confirmed", label: "Confirmed" },
    { value: "Checked In", label: "Checked In" },
    { value: "Declined", label: "Declined" },
  ];

  const defaulteventchapter = [{ value: "", label: "" }];

  try {
    let eventfilter = reduxState.dashboard.dashboard.filter(
      (item) => item._id === props.match.params.eventId
    );
    let guestfilter = eventfilter[0].lists.filter(
      (item) => item._id === props.match.params.guestId
    );

    defaulteventchapter[0].value = guestfilter[0].status;
    defaulteventchapter[0].label = guestfilter[0].status;
  } catch {
    console.log("setting error");
  }

  let headers = ["Relationships", "Relationship Type"];

  let relationshipstable = <h1 className="display-4 None">No Relationships.</h1>;

  const backToEvent = () => {
   history.push(`/events/${props.match.params.eventId}`)
  }
  
  if (!isEmpty(formData.relationships)) {
    // myData = filteredTasks
    relationshipstable = (
      <div>
        <table>
          <thead>
            <tr 
             className="prTableRow" style={{ color: "#404040" }}>
              {headers.map((header) => (
                <th
                  key={header}
                  style={{
                    minWidth: 65,
                    // color: "#484848",
                    color: "#FFFFFF",
                    background: "#404040",
                    paddingTop: 17,
                    paddingLeft: 5,
                    fontFamily: "Verdana",
                    border: ".9px solid darkgray",
                  }}
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {relationshipguests.map((item) => (
              <tr
                key={item._id}
            onClick={
              ()=>edit_relationship(item)}
                // onClick={(e) => editVolunteer(item._id)}
                className="prTableRow"
                style={{ color: "#404040" }}
              >
                <td
                  key={item.list}
                  className="chapterTableCell chapters_field"
                  style={{
                    borderTop: ".9px solid darkgray",
                    borderBottom: ".9px solid darkgray",
                    fontSize: "13px",
                    paddingRight: 18,
                    paddingTop: 18,
                    paddingBottom: 18,
                    paddingLeft: 10,
                    border: ".9px solid darkgray",
                    fontFamily:
                      "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                    // background: item.status === "Checked In" ? "#b9ecdb" : null,
                  }}
                >
                 <h5>
                    <Link style={{color: "darkblue", fontWeight: "bold"}} to={`/guests/${props.match.params.eventId}/${item._id}`}>{item.list}</Link>
                </h5>
                  {/* {item.list}{" "} */}
                </td>
                <td
                  key={item.relationshiptypes}
                  className="chapterTableCell chapters_field"
                  style={{
                    borderTop: ".9px solid darkgray",
                    borderBottom: ".9px solid darkgray",
                    fontSize: "13px",
                    paddingRight: 18,
                    paddingTop: 18,
                    paddingBottom: 18,
                    paddingLeft: 10,
                    border: ".9px solid darkgray",
                    fontFamily:
                      "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                    // background: item.status === "Checked In" ? "#b9ecdb" : null,
                  }}
                >
                  {item.relationshiptypes}{" "}
                </td>
    
                <td key={item.chapters_assigned}>
                  {/* marginBottom is increasing height of table cells here */}
                  <div>
                    <i
                      style={{ fontSize: 20 }}
                      className="fas fa-remove fa-xl edit_volunteers_remove"
                      onClick={(e) => {
                        showDeleteModal(item);
                        e.stopPropagation();
                        // e.preventDefault();
                      }}
                    ></i>
                  </div>
                </td>
                {/* marginBottom is increasing height of table cells here */}
                {/* <td>
                  <button
                    className="volunteerResetPassword"
                    onClick={(e) => editVolunteer(item._id)}
                  >
                    <i className="fas fa-pen fa-xl volunteerResetPasswordPen"></i>
                  </button>
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

const edit_permission_modal = 

    <div>
        <Modal
          {...props}
          show={editModalShow}
          onHide={editModalClose}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
         <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <b color="#909090">Edit Relationship</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form
              // onSubmit={submitAdd}
            >
              <div>
                <div className="form-group" style={{marginBottom:20}}>
                  <label htmlFor="searchguest">
                    <b>Guest Selected:</b>
                  </label>
                  <br/>
                  <span>{editRelationship.list} ({editRelationship.email})</span>

                  {/* <Select
                    onChange={onChangePermissions}
                    options={permissionoptions}
                    placeholder="Search Guests..."
                  /> */}
                </div>

                <div className="form-group">
                  <label htmlFor="relationship_type">
                    <b>Type Relationship to Guest. (Example: "Father" of selected guest.)</b>
                  </label>

                  <input
                    id="relationship_type"
                    type="text"
                    name="relationship_type"
                    className="form-control form-control-md"
                    style={{
                      flex: "8",
                      padding: "5px",
                      border: ".5px solid black",
                    }}
                    placeholder="Relationship..."
                    defaultValue={editRelationship.relationshiptypes}
                    onChange={onChangeEditRelationship}
                    // onBlur={onChangeRelationship}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="relation_back">
                    <b>Type the Relation the Guest has back. (Example: Son)</b>
                  </label>

                  <input
                    id="relation_back"
                    type="text"
                    name="relation_back"
                    className="form-control form-control-md"
                    style={{
                      flex: "8",
                      padding: "5px",
                      border: ".5px solid black",
                    }}
                    placeholder="Relation back..."
                    defaultValue={editRelationship.relatedrelationship}
                    // defaultValue={formData.username}
                    onChange={onChangeEditRelationship}
                    // onBlur={onChangeRelationship}
                  />
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
          <Modal.Footer>
            <Button
              style={{
                fontFamily: "Verdana, Geneva, Helvetica",
              }}
              variant="success"
              onClick={submitEdit}
            >
              Submit
            </Button>
            <Button
              style={{
                fontFamily: "Verdana, Geneva, Helvetica",
              }}
              variant="danger"
              onClick={() => setEditModalShow(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      

  return (
    <div style={{ marginBottom: 0, color: "#606060" }}>
         {/* {reduxState.dashboard.dashMessage !== "" ? (
            <Alert style={{
              marginTop: -23,
              height: 60,
              position: "fixed",
              width: "100%"
              }} variant="danger">
              <div style={{
                fontWeight: "bold",
                textAlign: "center",
                marginTop: 7,
                fontSize: 15,
                 }}>
               {reduxState.dashboard.dashMessage}
              </div>
            </Alert>
                  ) : null} */}
      <div>
        <h1
          className="eventlist_titles"
          style={{
            textAlign: "center",
            marginTop: 35,
            marginBottom: 13,
          }}
        >
          <p style={{fontWeight:"bold", fontFamily: "Verdana, Geneva, Helvetica" }}>
            Guest Profile
          </p>
        </h1>
      </div>
      <hr></hr>

      <div style={{marginTop: "1%", marginLeft: 30}}>
          <Button
            id="eventlist"
            variant="light"
            className="eventlist"
            onClick={backToEvent}
            style={{
              boxShadow: "0px 2px 5px darkgray",
              opacity: 0.8,
              padding: 5,
              border: "1px solid gray",
              borderRadius: 25,
              minWidth: 200, textDecoration: "none",
              marginTop: 5,
              marginBottom: 20
            }}
          >
            <Link
            
             
            className="nav-link" 
            style={{
            textAlign: "center",
            textDecoration: "none",
            color: "black"
              }}
            to='/'>
              <div style={{display: "flex", justifyContent: "center"}}>
              <div style={{marginTop:2}}>
            <i style={{
              color: "#505050",
              textDecoration: "none",
              fontSize: "18px",
            }}
            className="fas fa-arrow-left fa-sm">
            </i>
            </div>
            <div>
            <span style={{
              marginTop: "50px",
              fontFamily: "Verdana, Geneva",
              fontWeight: "bold",
              color: "#202020",
            }}> Back to Event</span>
            </div>
            </div>
            </Link>
            </Button>
            </div>

      {!guestEdit ? (
        <div
          style={{
            padding: "40px",
            marginLeft: "30px",
            width: "600px",
            border: "1px solid darkgray",
            borderRadius: 5,
            boxShadow: "0px 3px 8px darkgray",
          }}
        >
          <i
            style={{ padding: 0, marginTop: -8 }}
            className="fas fa-pen fa-xl edit_guest"
            onClick={() => editGuest()}
          ></i>
            <div
            onClick={() => editGuest()}
            className="inputguest"
            style={{ cursor: "pointer", display: "flex", marginBottom: 22 }}
          >
            <b>Account ID: </b>
            <div>{formData.account_id}</div>
          </div>

          <div
            onClick={() => editGuest()}
            className="inputguest"
            style={{ cursor: "pointer", display: "flex", marginBottom: 22 }}
          >
            <b>Name: </b>
            <div>{formData.list}</div>
          </div>
          <div
            onClick={() => editGuest()}
            className="inputguest"
            style={{ cursor: "pointer", display: "flex", marginBottom: 22 }}
          >
            <b>Organization: </b> {formData.organization}
          </div>
          <div
            onClick={() => editGuest()}
            className="inputguest"
            style={{ cursor: "pointer", display: "flex", marginBottom: 22 }}
          >
            <b>Phone: </b> {formData.phone}
          </div>
          <div
            onClick={() => editGuest()}
            className="inputguest"
            style={{ cursor: "pointer", display: "flex", marginBottom: 22 }}
          >
            <b>Email: </b> {formData.email}
          </div>
          <div
            onClick={() => editGuest()}
            className="inputguest"
            style={{ cursor: "pointer", display: "flex", marginBottom: 22 }}
          >
            <b>TicketType: </b> {formData.tickettype}
          </div>
          <div
            onClick={() => editGuest()}
            className="inputguest"
            style={{ cursor: "pointer", display: "flex", marginBottom: 22 }}
          >
            <b>Table/Row #: </b> {formData.table_row_number}
          </div>
          <div
            onClick={() => editGuest()}
            className="inputguest"
            style={{ cursor: "pointer", display: "flex", marginBottom: 22 }}
          >
            <b>Seat #: </b> {formData.seat_number}
          </div>
          <div
            onClick={() => editGuest()}
            className="inputguest"
            style={{ cursor: "pointer", display: "flex", marginBottom: 22 }}
          >
            <b>Status: </b> {formData.status}
          </div>
          <div
            onClick={() => editGuest()}
            className="inputguest"
            style={{ cursor: "pointer", display: "flex", marginBottom: 22 }}
          >
            <b>Additional Details: </b> {formData.guest_details}
          </div>
          <div
            onClick={() => editGuest()}
            className="inputguest"
            style={{ cursor: "pointer", display: "flex", marginBottom: 22 }}
          >
            <b>Checked In By: </b> {formData.checkedin_by == "" ? "(Not Checked In)" : formData.checkedin_by}
          </div>

          <div
            onClick={() => editGuest()}
            className="inputguest"
            style={{ cursor: "pointer", display: "flex", marginBottom: 22 }}
          >
            <b>Checked In By: </b> {formData.checkedin_by_username == "" ? "(Not Checked In)" : formData.checkedin_by_username}
          </div>


          <div
            onClick={() => editGuest()}
            className="inputguest"
            style={{ cursor: "pointer", display: "flex", marginBottom: 16 }}
          >
            <b>Relationships: </b>
            <Button 
                onClick={()=> setShowRelationships(true)}
                variant={"secondary"} style={{
                fontSize: 16,
                marginTop: -6,
              }}>
                Create Relationship
              </Button>
          </div>
          {relationshipstable}

          <div className="inputguest" style={{ marginTop: 20 }}>
            <b>Guest QR Code: </b>
          </div>
          <div
            style={{
              paddingLeft: 20,
              marginBottom: 10,
              marginTop: 30,
              paddingBottom: 20,
              fontFamily: "Georgia, Helvetica",
            }}
          >
            <QRCode
              size={160}
              value={JSON.stringify({
                qrobject,
              })}
            />


      <DeleteRelationship
          show={deleteModalShow}
          // id={activeItemId}
          onHide={deleteModalClose}
          // deletingvolunteer={related_id}
          deleteuser={deleteuser}
        />
          </div>
        </div>
      ) : (
        <div
          style={{
            padding: "40px",
            marginLeft: "30px",
            width: "600px",
            border: "1px solid darkgray",
            borderRadius: 5,
            boxShadow: "0px 3px 8px darkgray",
          }}
        >
          <i
            style={{ padding: 0, marginTop: -8 }}
            className="fas fa-remove fa-xl remove_guest"
            onClick={() => cancelEditGuest()}
          ></i>
    
            <div
            onClick={() => editGuest()}
            className="inputguest"
            style={{ cursor: "pointer", display: "flex", marginBottom: 22 }}
          >
            <b>Account ID: </b>
            <div>{formData.account_id}</div>
          </div>
          <div
            className="inputguest"
            style={{ display: "flex", marginBottom: 15 }}
          >
            <b>Name: </b>
            <div className="">
              <input
                name="list"
                className="innerinput"
                onChange={onChange}
                type="text"
                defaultValue={formData.list}
                style={{
                  border: "1px solid #888888",
                  paddingLeft: 7,
                  marginTop: -30,
                }}
              />
            </div>
          </div>
          <div
            className="inputguest"
            style={{ display: "flex", marginBottom: 16 }}
          >
            <b>Organization: </b>
            <input
              name="organization"
              className="innerinput"
              onChange={onChange}
              type="text"
              defaultValue={formData.organization}
              style={{
                border: "1px solid #888888",
                paddingLeft: 7,
                marginTop: -8,
              }}
            />
          </div>
          <div
            className="inputguest"
            style={{ display: "flex", marginBottom: 16 }}
          >
            <b>Phone: </b>
            <input
              name="phone"
              className="innerinput"
              onChange={onChange}
              type="text"
              defaultValue={formData.phone}
              style={{
                border: "1px solid #888888",
                paddingLeft: 7,
                marginTop: -8,
              }}
            />
          </div>
          <div
            className="inputguest"
            style={{ display: "flex", marginBottom: 16 }}
          >
            <b>Email: </b>
            <input
              name="email"
              className="innerinput"
              onChange={onChange}
              type="text"
              defaultValue={formData.email}
              style={{
                border: "1px solid #888888",
                paddingLeft: 7,
                marginTop: -8,
                width: formData.email.length * 10,
              }}
            />
          </div>
          <div
            className="inputguest"
            style={{ display: "flex", marginBottom: 16 }}
          >
            <b>Ticket Type: </b>
            <input
              name="tickettype"
              className="innerinput"
              onChange={onChange}
              type="text"
              defaultValue={formData.tickettype}
              style={{
                border: "1px solid #888888",
                paddingLeft: 7,
                marginTop: -8,
              }}
            />
          </div>
          <div
            className="inputguest"
            style={{ display: "flex", marginBottom: 16 }}
          >
            <b>Table/Row Number: </b>
            <input
              name="table_row_number"
              className="innerinput"
              onChange={onChange}
              type="text"
              defaultValue={formData.table_row_number}
              style={{
                border: "1px solid #888888",
                paddingLeft: 7,
                marginTop: -8,
              }}
            />
          </div>
          <div
            className="inputguest"
            style={{ display: "flex", marginBottom: 16 }}
          >
            <b>Seat #: </b>
            <input
              name="seat_number"
              className="innerinput"
              onChange={onChange}
              type="text"
              defaultValue={formData.seat_number}
              style={{
                border: "1px solid #888888",
                paddingLeft: 7,
                marginTop: -8,
              }}
            />
          </div>
          <div
            className="inputguest"
            style={{ display: "flex", marginBottom: 16 }}
          >
            <b>Status: </b>
            {/* <input
              name="name"
              className="innerinput"
              onChange={onChange}
              type="text"
              defaultValue={formData.status}
              style={{
                border: "1px solid #888888",
                paddingLeft: 7,
              }}
            /> */}
            <Select
              // isMulti
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  // borderColor: state.isFocused ? 'grey' : 'red',
                  borderColor: "gray",
                  fontSize: 16,
                  height: 0,
                  width: 180,
                  marginLeft: 0,
                  marginTop: -8,
                }),
              }}
              defaultValue={defaulteventchapter}
              onChange={onChangeEditStatus}
              options={eventchapter}
            />
          </div>

          <div
            className="inputguest"
            style={{ display: "flex", marginBottom: 16 }}
          >
            <b>Guest Details: </b>
            <input
              name="guest_details"
              className="innerinput"
              onChange={onChange}
              type="text"
              defaultValue={formData.guest_details}
              style={{
                border: "1px solid #888888",
                paddingLeft: 7,
                marginTop: -8,
              }}
            />
          </div>
          <div
            className="inputguest"
            style={{ display: "flex", marginBottom: 13 }}
          >
            <b>Checked In By: </b> {formData.checkedin_by}
          </div>
          <div
            className="inputguest"
            style={{ display: "flex", marginBottom: 13 }}
          >
            <b>Checked In By Username: </b> {formData.checkedin_by_username}
          </div>
          <div
            className="inputguest"
            style={{ marginTop: 6, display: "flex", marginBottom: 16 }}
          >
            <b style={{marginTop:9}}>Relationships: </b>
              <Button 
                onClick={()=> setShowRelationships(true)}
                variant={"secondary"} style={{
                fontSize: 16,
                marginTop: 3
              }}>
                Create Relationship
              </Button>
          </div>
          {relationshipstable}
          <div className="inputguest" style={{ marginTop: 22 }}>
            <b>Guest QR Code: </b>
          </div>
          <div
            style={{
              paddingLeft: 20,
              marginBottom: 10,
              marginTop: 30,
              paddingBottom: 20,
              fontFamily: "Georgia, Helvetica",
            }}
          >
            <QRCode
              size={160}
              value={JSON.stringify({
                qrobject,
              })}
            />
          </div>

          <Button
                // disabled={this.state.btnEnabled}
                onClick={onSubmit}
                variant="light"
                style={{
                  height: 45,
                  marginTop: -70,
                  borderRadius: 29,
                  width: "35%",
                  border: "1px solid black",
                  boxShadow: "0px 2px 4px gray",
                  float: "right",
                }}
              >
                <div id="eventcreatebutton" className="eventcreatebuttons">
                  <i style={{fontSize: 17}} className="fas fa-save fa-sm"></i>
                  <b>  Save Changes</b>
                </div>
              </Button>


              <div>
      <Modal
        {...props}
        show={showRelationships}
        onHide={on_hide_relationships}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <b color="#909090">Create a Relationship</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form
              // onSubmit={submitAdd}
            >
              <div>
                <div className="form-group">
                  <label htmlFor="searchguest">
                    <b>Select Guest</b>
                  </label>

                  <Select
                    // defaultValue={permissiondefault}
                    onChange={onChangePermissions}
                    options={permissionoptions}
                    placeholder="Search Guests..."
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="relationship_type">
                    <b>Type Relationship to Guest. (Example: "Father" of selected guest.)</b>
                  </label>

                  <input
                    id="relationship_type"
                    type="text"
                    name="relationship_type"
                    className="form-control form-control-md"
                    style={{
                      flex: "8",
                      padding: "5px",
                      border: ".5px solid black",
                    }}
                    placeholder="Relationship..."
                    // defaultValue={formData.username}
                    onChange={onChangeRelationship}
                    // onBlur={onChangeRelationship}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="relation_back">
                    <b>Type the Relation the Guest has back. (Example: Son)</b>
                  </label>

                  <input
                    id="relation_back"
                    type="text"
                    name="relation_back"
                    className="form-control form-control-md"
                    style={{
                      flex: "8",
                      padding: "5px",
                      border: ".5px solid black",
                    }}
                    placeholder="Relation back..."
                    // defaultValue={formData.username}
                    onChange={onChangeRelationship}
                    // onBlur={onChangeRelationship}
                  />
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={onSubmitRelationship}
          >
            Submit
          </Button>
          <Button variant="danger" onClick={() => on_hide_relationships()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
        </div>
      )}
      {edit_permission_modal}
    </div>
  );
};

export default Guests;
