import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap';


export class AddGuestModal extends Component {

  state = {
    name: '',
    email: '',
    phone: '',
    tickettype: '',
    guest_details: '',
    organization: '',
    table_row_number: '',
    seat_number:''
}

refreshPage() {
  window.location.reload(false);
}

onSubmit = (e) => {
    e.preventDefault();
    this.props.addGuest(this.props.id, this.state.name, this.state.email, this.state.phone, this.state.tickettype, this.state.guest_details, this.state.organization, this.state.table_row_number, this.state.seat_number);
    this.setState({ name: '', email: '', phone: '', tickettype: '', guest_details: '', organization: '', table_row_number: '', seat_number: ''})
    this.props.onHide()
    console.log("this.state")
    console.log(this.state)
}

//Single Event Handler Approach:
onChange = (e) => {
  if (this.state.name === "") {
    this.setState({name: " "});
    }

  if (this.state.email === "") {
    this.setState({email: " "});
    }

    if (this.state.phone === "") {
      this.setState({phone: " "});
      }

      if (this.state.tickettype === "") {
        this.setState({tickettype: " "});
        }
        if (this.state.guest_details === "") {
          this.setState({guest_details: " "});
          }

        if (this.state.organization === "") {
          this.setState({organization: " "});
          }
    
          if (this.state.table_row_number === "") {
            this.setState({table_row_number: " "});
            }

            if (this.state.seat_number === "") {
              this.setState({seat_number: " "});
              }

  this.setState({ [e.target.name]:
      e.target.value});

}

    render(){
        return (
            <Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <b>Add Guest</b>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className=''>
                <form onSubmit={this.onSubmit}>

<div style={{fontFamily: "Verdana"}} >
<div className="form-group">
<label for="name"><b>Name:</b></label>
<input
id="name"
className="form-control form-control-md"
    type="text"
    name="name"
    style= {{flex: '8', padding:  '5px', border: '.5px solid black'}}
    placeholder="Name..."
    onChange ={this.onChange}
    />
    </div>

    <div className="form-group">
<label for="organization"><b>Organization</b></label>
<input
id="organization"
className="form-control form-control-md"
    type="text"
    name="organization"
    style= {{flex: '8', padding:  '5px', border: '.5px solid black'}}
    placeholder="Organization..."
    onChange ={this.onChange}
    />
 </div> 

    <div className="form-group">
    <label for="email"><b>Email:</b></label>
  
<input
id="email"
className="form-control form-control-md"
    type="text"
    name="email"
    style= {{flex: '8', padding:  '5px', border: '.5px solid black'}}
    placeholder="Email..."
    onChange ={this.onChange}
    />
</div>


<div className="form-group">
<label for="phone"><b>Phone:</b></label>
<input
id="phone"
className="form-control form-control-md"
    type="text"
    name="phone"
    style= {{flex: '8', padding:  '5px', border: '.5px solid black'}}
    placeholder="Phone..."
    onChange ={this.onChange}
    />
</div>




<div className="form-group">
<label for="ticket"><b>Ticket</b></label>
<input
id="tickettype"
className="form-control form-control-md"
    type="text"
    name="tickettype"
    style= {{flex: '8', padding:  '5px', border: '.5px solid black'}}
    placeholder="Ticket Type..."
    onChange ={this.onChange}
    />
 </div>   

<div style={{display: "flex", justifyContent: "space-between"}}>
 <div style={{width: "48%"}} className="form-group">
<label for="ticket"><b>Table/Row Number</b></label>
<input id="table_row_number"
className="form-control form-control-md"
    type="text"
    name="table_row_number"
    style= {{flex: '8', padding:  '5px', border: '.5px solid black'}}
    placeholder="Table/Row #..."
    onChange ={this.onChange}
    />
 </div> 

 <div style={{width: "48%"}} className="form-group">
<label for="ticket"><b>Seat #</b></label>
<input id="seat_number"
className="form-control form-control-md"
    type="text"
    name="seat_number"
    style= {{flex: '8', padding:  '5px', border: '.5px solid black'}}
    placeholder="Seat #..."
    onChange ={this.onChange}
    />
 </div> 
 </div>

 <div className="form-group">
<label for="guest_details"><b>Additional Details</b></label>
<textarea
id="guest_details"
className="form-control form-control-md"
    type="text"
    name="guest_details"
    style= {{flex: '8', padding:  '5px', border: '.5px solid black'}}
    placeholder="Additional Details..."
    onChange ={this.onChange}
    />
 </div>  

    </div>
</form>
     </div>

            </Modal.Body>
            <Modal.Footer>
            <Button variant="success" onClick={this.onSubmit}>Submit</Button>
              <Button variant="danger" onClick={this.props.onHide}>Close</Button>
            </Modal.Footer>
          </Modal>
        );
    
    }
}

export default AddGuestModal
