import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import axios from "axios";
import * as actions from "../../store/actions/index";

const DeleteAccount = (props) => {
  const [deleteButtonDisable, setDeleteButtonDisable] = useState(true);
  const dispatch = useDispatch();

  const deleteAccount = () => {

    axios
      .delete("/api/users/deletetheaccount")
      .then((res) => {
        console.log("delete account res");
        console.log(res);
      })
      .catch((err) => {
        console.log(err.response);
      });

      dispatch(actions.logoutUser());
  };

  const handleDeleteType = (e) => {
    if (e.target.value.toLowerCase() == "delete account") {
      setDeleteButtonDisable(false);
    } else {
      setDeleteButtonDisable(true);
    }
  };

  return (
    <div>
      <Modal
        {...props}
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <b color="#909090">Delete your Account</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={deleteAccount}>
              <div>
                <div
                  style={{
                    padding: 10,
                  }}
                >
                  <h5 style={{fontFamily: "Verdana, Helvetica, Geneva"}}>
                    Are you sure you want to delete your Account and all of your
                    data? This action is <b>NOT</b> reversible! 
                    All of your Volunteers and Permissions will also be deleted.
                  </h5>
                </div>
                <div
                  style={{
                    padding: 10,
                    marginBottom: 10,
                  }}
                >
                  <h5 style={{fontFamily: "Verdana, Helvetica, Geneva"}}>
                    You must type "
                    <span style={{ fontWeight: "bold", color: "darkred" }}>
                      delete account
                    </span>
                    " in order to enable the button to delete your account.
                  </h5>
                </div>
                <div style={{ paddingLeft: 10 }} className="form-group">
                  <input
                    id="confirmdelete"
                    type="text"
                    name="confirmdelete"
                    className="form-control form-control-md"
                    style={{
                      width: "35%",
                      flex: "8",
                      paddingLeft: "5px",
                      border: ".5px solid black",
                    fontFamily: "Verdana, Helvetica, Geneva"
                    }}
                    placeholder="Delete Account"
                    //   defaultValue={formData.name}
                    onChange={handleDeleteType}
                  />
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={deleteAccount}
            disabled={deleteButtonDisable}
            style={{fontFamily: "Verdana, Geneva, Helvetica"}}
          >
            Delete Account Permanently
          </Button>
          <Button 
           style={{fontFamily: "Verdana, Geneva, Helvetica"}}
           variant="danger" onClick={() => props.onHide()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeleteAccount;
