import React, { useEffect, useState } from "react";
import Fragment from "../../utils/Fragment";
import Dashboard from "./Dashboard";
import "./Dashboard.css";
import Navbar from "../../containers/Navbar/Navbar";
import { connect } from "react-redux";
import * as action from "../../store/actions/index";
import { useDispatch, useSelector } from "react-redux";
import { Disabled } from "../Disabled/Disabled";
// import { ToastContainer, toast } from "react-toastify";

function DashboardPage() {
  const accountDetails = useSelector((state) => state);

  // Operating theory here is that passing any function into component
  // causes a React Trigger which refreshes the component.
  // Allowing to go back to the previous page and state when clicking on other links
  const [switchEvent, setSwitchEvent] = useState(false)

  const reloadDashboard = () => {
    console.log("1parent function DashboardPage.js")
    setSwitchEvent(true)
  }


  useEffect(() => {
    console.log("dashboardpage");
    console.log(accountDetails);
    console.log(accountDetails.auth);
    console.log(accountDetails.auth.isAuthenticated);
    console.log(accountDetails.auth.user.disabled_account)
  },);

  return (
    <Fragment>
         {!accountDetails.auth.user.disabled_account ? (
           <div>
      {accountDetails.auth.isAuthenticated ? (
        <div>
          <Dashboard 
          switchEvent = {()=>reloadDashboard}
          />
        </div>
      ) : (
        <div>
          <Navbar />
          <Dashboard
          switchEvent = {()=>reloadDashboard}
          />
        </div>
      )}
      </div>)  : 
      (
        <Disabled/>
      )}
    </Fragment>
  );

  
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(DashboardPage);
