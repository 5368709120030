import React from "react";
import csvDownload from "json-to-csv-export";
import { func } from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "./Reports.css";
import moment from "moment";

const CsvDownload = (props) => {
  const { dashboard, filename, eventName, ...others } = props;

  const runEventReport = () => {

    console.log("dashboard");
    console.log(dashboard);

    try {
      let eventReport = [];

      for (var i = 0; i < dashboard.length; i++) {
        eventReport.push({
          "Guest Name": "",
        });
      }

      for (var i = 0; i < dashboard.length; i++) {
        eventReport[i]["Guest Name"] = dashboard[i].list;
        eventReport[i]["Account Id"] = dashboard[i].account_id;
        eventReport[i]["Organization"] = dashboard[i].organization;
        eventReport[i]["Date Created"] = moment(dashboard[i].date).format(
          "MM/DD/YYYY"
        );
        eventReport[i]["Email"] = dashboard[i].email;
        eventReport[i]["Phone"] = dashboard[i].phone;
        eventReport[i]["Seat Number"] = dashboard[i].seat_number;
        eventReport[i]["Status"] = dashboard[i].status;
        eventReport[i]["Table Row Number"] = dashboard[i].table_row_number;
        eventReport[i]["Ticket Type"] = dashboard[i].tickettype;
        eventReport[i]["Relationships"] = dashboard[i].relationships
          .map(
            (item) =>
              item.related__type +
              " of " +
              dashboard.filter((item2) => item2._id == item.related__id)[0].list
          )
          .join(", ");
        eventReport[i]["Checked-In By"] = dashboard[i].checkedin_by;
        eventReport[i]["Checked-In By Username"] = dashboard[i].checkedin_by_username;
        eventReport[i]["Guest Id"] = dashboard[i]._id;
        eventReport[i]["Guest Details"] = dashboard[i].guest_details;
      }

      let today_date = moment(Date.now()).format("MM-D-YY");
      csvDownload(eventReport, `Event_Report_${eventName}_${today_date}.csv`);
      console.log("eventReport");
      console.log(eventReport);

      toast.success(
        <div style={{ fontSize: "16px" }}><b>Event Report Downloaded!</b></div>,
        {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    } catch {
      console.log("report download error");
    }
  };

  return (
    <div>
      <button
        style={{
          width: "100%",
          fontWeight: "bold",
          border: "1px solid darkgray",
        }}
        className="reportButton"
        onClick={() => runEventReport()}
      >
        {props.children || "Download Event Report"}
      </button>
    </div>
  );
};
export default CsvDownload;
