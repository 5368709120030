import React from 'react'
import {Redirect} from 'react-router-dom'
import { Link } from "react-router-dom";
import './Disabled.css'
export const Disabled = () => {
    const containerStyle = {
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: "20%",
        paddingRight: "20%",
        paddingTop: "10%"
      };
      const containerStyle2 = {
        display: 'flex',
        justifyContent: 'center',
      }
      const disabledTextStyle = {
        fontSize: '1.5rem',
        color: '#888',
        marginBottom: '20px',
      };
    
    
      return (
          <div>
        <div style={containerStyle}>
          <p style={disabledTextStyle}>
              <b>
            Your account is currently disabled due to suspicious activites, API Abuse or other spam.
            if this was by accident then please call customer support at 312-504-8275 or email contact@eventsensei.com in order to reactivate your account.
            If this is a technical issue, then please try to log out and log back in again and/or delete any browser cookies.
            </b>
          </p>
          {/* <button style={buttonStyle} disabled={disabled}> */}
        {/* <button onClick={contactEventSensei()} style={buttonStyle}>
            <b>Contact EventSensei</b>
          </button> */}
<br/><br></br>
   </div>
   <div style={containerStyle2}>
   <Link to="/contact" className="btn">
                <button
                  className="contactButton"
                  style={
                    {
                      // background: "linear-gradient(to right, #F67280 , #F75D59)",
                      // background: "linear-gradient(to bottom right, #F67280 , #CC0000)",
                      // border: "none",
                      // color: "white",
                      // padding: 15,
                      // fontSize: 15,
                      // width: "18%",
                      // fontFamily: "Verdana"
                    }
                  }
                >
                  Contact EventSensei
                </button>
              </Link>
              </div>
            </div>
      );
    };
