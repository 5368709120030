import React, { useState, useEffect, MouseEventHandler } from "react";
import * as _ from "lodash"
import './Scroll.scss'

const content = [
    {
      title: "Page One",
      subtitle: "Scroll down ⬇"
    },
    {
      title: "Page Two",
      subtitle: "Hi I'm a full page parallax scroller"
    },
    {
      title: "Page Three",
      subtitle: "And you're beautiful"
    }
  ];
  
  const transitionDuration: number = 600;
  
  const Scroll = () => {
    const [isBusy, setIsBusy] = React.useState(false);
    const [slideIdx, setSlideIdx] = React.useState<number>(0);
    const totalSlideNumber = content.length;
  
    const slideDurationTimeout = (slideDuration: number) => {
      setTimeout(() => {
        setIsBusy(false);
      }, slideDuration);
    };
  
    const parallaxScroll = _.throttle((e: React.WheelEvent<HTMLDivElement>) => {
      const isWheelingDown = -e.deltaY <= 0;
  
      if (isWheelingDown && !isBusy) {
        setIsBusy(true);
        if (slideIdx !== totalSlideNumber - 1) {
          scrollDown();
        }
        slideDurationTimeout(transitionDuration);
      }
  
      if (!isWheelingDown && !isBusy) {
        setIsBusy(true);
        if (slideIdx !== 0) {
          scrollUp();
        }
        slideDurationTimeout(transitionDuration);
      }
    });
  
    const scrollDown = (): void => setSlideIdx((prevIdx) => prevIdx + 1);
  
    const scrollUp = (): void => setSlideIdx((prevIdx) => prevIdx - 1);
  
    return (
      <div className="app" onWheel={parallaxScroll}>
        {content.map((c, i) => {
          const classNames = [
            "section",
            i <= slideIdx - 1 ? "down-scroll" : "",
            i !== totalSlideNumber - 1 && i >= slideIdx ? "up-scroll" : ""
          ]
            .join(" ")
            .trim();
  
          return (
            <section className={classNames}>
              <div className="parallax-wrapper">
                <div className="content">
                  <h1 className="title">{c.title}</h1>
                  <h3 className="subtitle">{c.subtitle}</h3>
                </div>
              </div>
            </section>
          );
        })}
      </div>
    );
  };
  
export default Scroll;
  