import React, { useState, useRef, useEffect } from "react";
import Fragment from "../../../utils/Fragment";
import "./DashbboardList.css";
// import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
// import axios from 'axios'
// import { isEmpty } from "../../../utils/Validation";
// import * as actions from "../../../store/actions/index";

const DashboardLists = (
  props,
  { CSStype, height, width, chartId, chartURL }
) => {
  const [lodaded, setLoaded] = useState();
  const [reduxloaded, setReduxLoaded] = useState();

  const accountDetails = useSelector((state) => state);
  const dispatch = useDispatch();

  let windowSize = useRef([window.innerWidth, window.innerHeight]);
  let windowWidth = windowSize.current[0];
  let windowHeight = windowSize.current[1];

  let adjustedWindowWidth = windowSize.current[0];
  let adjustedWindowHeight = windowSize.current[1];
  adjustedWindowHeight = adjustedWindowHeight * 3;

  if (windowWidth < 800) {
    adjustedWindowHeight = adjustedWindowHeight * 1;
    adjustedWindowWidth = adjustedWindowWidth * 1.7;
  }

  const sdk = new ChartsEmbedSDK({
    baseUrl: "https://charts.mongodb.com/charts-event-sensei-rvjum",
  });
  const chartDiv1 = useRef(null);
  const chartDiv2 = useRef(null);
  const chartDiv3 = useRef(null);
  const chartDiv4 = useRef(null);

  const [rendered, setRendered] = useState(false);

  // Careful here with filters. _id is the filed and '$oid' is the data type fed.
  const filter = { _id: { $oid: props.match.params.eventId } };

  const [chart1] = useState(
    sdk.createChart({
      chartId: "65dbf0bb-432f-4394-8abe-0d9cf06c243e",
      filter: filter,
      height: adjustedWindowHeight / 4.8,
      width: adjustedWindowWidth / 2.35,
      theme: "dark",
    })
  );
  // chart1.setAutoRefresh(true);

  const [chart2] = useState(
    sdk.createChart({
      chartId: "65dbdf2e-096d-4944-89e8-3051f1ca61d0",
      filter: filter,
      height: adjustedWindowHeight / 4.8,
      width: adjustedWindowWidth / 2.35,
      theme: "dark",
    })
  );
  // chart2.setAutoRefresh(true);

  const [chart3] = useState(
    sdk.createChart({
      chartId: "65dc006d-6d36-43b6-8ce3-048c3d0682a4",
      filter: filter,
      height: adjustedWindowHeight / 4.8,
      width: adjustedWindowWidth / 2.35,
      theme: "dark",
    })
  );
  // chart3.setAutoRefresh(true);

  const [chart4] = useState(
    sdk.createChart({
      chartId: "65dc0bf0-4a68-4ad2-89d8-ce9759b4e80b",
      filter: filter,
      height: adjustedWindowHeight / 4.8,
      width: adjustedWindowWidth / 2.35,
      theme: "dark",
    })
  );
  // chart4.setAutoRefresh(true);

  useEffect(() => {

    try{
    console.log("props.match.params");
    console.log(props.match.params.eventId);
    console.log(accountDetails.dashboard);

    chart1
      .render(chartDiv1.current)
      .then(() => setRendered(true))
      .catch((err) => console.log("Error during Charts rendering.", err));
    chart2
      .render(chartDiv2.current)
      .then(() => setRendered(true))
      .catch((err) => console.log("Error during Charts rendering.", err));
    chart3
      .render(chartDiv3.current)
      .then(() => setRendered(true))
      .catch((err) => console.log("Error during Charts rendering.", err));
    chart4
      .render(chartDiv4.current)
      .then(() => setRendered(true))
      .catch((err) => console.log("Error during Charts rendering.", err));
    }
    catch(e){console.log(e)}
  }, [accountDetails.dashboard, chart1, chart2, chart3, chart4, props.match.params.eventId]);

  let analytics = (
    <div>
      <div
        style={{
          textAlign: "center",
          alignContent: "center",
          alignSelf: "center",
        }}
      >
        <div>
          <h1
            className="eventlist_titles"
            style={{
              textAlign: "center",
              marginTop: 35,
              marginBottom: 15,
            }}
          >
            <b style={{ fontFamily: "Verdana, Geneva, Helvetica" }}>
              Analytics Dashboard
            </b>
          </h1>
        </div>
        <hr></hr>
        <div
          className="row"
          style={{
            margin: 30,
            textAlign: "center",
            alignContent: "center",
            alignSelf: "center",
          }}
        >
          <div
            className={CSStype}
            ref={chartDiv1}
            style={{
              marginLeft: 50,
              marginBottom: 40,
              boxShadow: "2px 8px 10px darkgray",
            }}
          />
          <div
            className={CSStype}
            ref={chartDiv2}
            style={{
              marginLeft: 50,
              marginBottom: 40,
              boxShadow: "2px 8px 10px darkgray",
            }}
          />
        </div>
        <div
          className="row"
          style={{
            margin: 30,
            textAlign: "center",
            alignContent: "center",
            alignSelf: "center",
          }}
        >
          <div
            className={CSStype}
            ref={chartDiv3}
            style={{
              marginLeft: 50,
              marginBottom: 40,
              boxShadow: "2px 8px 10px darkgray",
            }}
          />
          <div
            className={CSStype}
            ref={chartDiv4}
            style={{
              marginLeft: 50,
              marginBottom: 40,
              boxShadow: "2px 8px 10px darkgray",
            }}
          />
        </div>
      </div>
    </div>
  );

  return <Fragment>{analytics}</Fragment>;
};

export default DashboardLists;
