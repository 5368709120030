import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Processordonate from './Processordonate'
import React from "react";

const stripePromise = loadStripe('pk_test_51I2P5DAZzCAMTEUQBQVU4xfRDLMkBD6aUUD1LVQX8RckfEZDIQgKSgaqIT9t3T4z5InPXqyhrKmak9p5EgMAJklk00aAnRo80c');

const PaymentsParentdonate = () => {
  return (

<div className='container'>
<div className="jumbotron">
<h1>Make a One Time Donation.</h1>
<h1>$10</h1>
<Elements stripe={stripePromise}>
  <div style={{width:"20px"}}>
      <Processordonate />
      </div>
    </Elements>

</div>
</div>
  );
};

export default PaymentsParentdonate