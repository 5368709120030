export const LOADING = 'LOADING';
export const EDITAGO = 'EDITAGO';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_ROLE_USER = 'REGISTER_ROLE_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_REGISTER_SUCCESS = 'AUTH_REGISTER_SUCCESS';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_SET_CURRENT_USER = 'AUTH_SET_CURRENT_USER';
export const AUTH_USER_LOGOUT = 'AUTH_USER_LOGOUT';
export const DASHBOARD_CREATE = 'DASHBOARD_CREATE';
export const DASHBOARD_EDIT = 'DASHBOARD_EDIT';
export const IMPORTING_START = 'IMPORTING_START';
export const IMPORTING_FINISH = 'IMPORTING_FINISH';
export const IMPORTING_UPDATING_START = 'IMPORTING_UPDATING_START';
export const IMPORTING_UPDATING_FINISH = 'IMPORTING_UPDATING_FINISH';
export const IMPORTING_ERROR = 'IMPORTING_ERROR';
export const IMPORTING_ERROR_OFF = 'IMPORTING_ERROR_OFF';
export const DASHBOARD_DELETE = 'DASHBOARD_DELETE';
export const USER_DATA = 'USER_DATA'
export const SUBSCRIBE_LIST = 'SUBSCRIBE_LIST'
export const SUBSCRIBE_EVENT = 'SUBSCRIBE_EVENT'
export const RELATIONSHIP_CREATED = 'RELATIONSHIP_CREATED'
export const GUEST_ADDED = 'GUEST_ADDED'
export const SWITCH_EVENTFALSE = 'SWITCH_EVENTFALSE'
export const CLEAR_DASHMESSAGE = 'CLEAR_DASHMESSAGE'
export const CLEAR_DASHMESSAGESUCCESS = 'CLEAR_DASHMESSAGE'
export const AUTH_UPDATE = 'AUTH_UPDATE'
export const AUTH_MESSAGE_CLEAR = 'AUTH_MESSAGE_CLEAR'

