import React, { useState, useEffect } from "react";
import {
  Button,
  container,
  Form,
  Message,
  Segment,
  Dimmer,
  Loader,
} from "semantic-ui-react";
// import vid from '../../Assets/bc.mp4'
import "./Reset.css";
import axios from "axios";
import { floor } from "lodash";
import { Alert } from "@mui/material";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [successAlertmessage, setSuccessAlertmessage] = useState("");
  const [errorAlertmessage, setErrorAlertmessage] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(false);

  const onChange = (e) => {
    console.log("email inputted");
    console.log(e.target.value);
    setEmail(e.target.value);
  };

  useEffect(() => {
	setDisableSubmit(false)
  }, [])
  

  const onSubmit = (e) => {
    e.preventDefault();
	setDisableSubmit(true)
	setSuccessAlertmessage("");
	setErrorAlertmessage("");

    let forgotpasswordemail = async () => {
		setLoading(true);
      try {
        // history.push(`/login`);
        axios
          .post(`/api/users/request/${email}`)
          .then((res) => {
            console.log("response Data");
            console.log(res);

            if (res.data.success === true) {
              setSuccessAlertmessage(
                "A password reset link has been sent to your email!"
              );
              setLoading(false);
            } else if (res.data.noemail === true) {
              setErrorAlertmessage("This email does not exist in our system");
              setLoading(false);
            }
			else{
				setErrorAlertmessage("An unknown error has occurred");
				setLoading(false);
			  }
          })
          .catch((err) => {
            console.log("axios error");
            console.log(err);
          });
      } catch (error) {
        console.log("axios error");
        console.log(error);
      }
    };
    forgotpasswordemail();

	
  };

  return (
    <div>
      <div
        style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
      >
        <h1
          style={{
            textAlign: "center",
            marginTop: "3.5%",
            fontFamily: "Georgia, Helvetica",
          }}
        >
          <b
            style={{
              fontFamily: "Verdana, Geneva, Helvetica",
			  color: "#404040"
            }}
          >
            Forgot Password
          </b>
        </h1>
      </div>
      {/* <div dangerouslySetInnerHTML={{ __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          src="${vid}"
          class="Videostyle"
        />,
      ` }}></div> */}
      <div
        style={{
          background: "#F8F8F8",
          width: "600px",
          marginLeft: "7%",
		  border: "1px solid gray"
        }}
        className="jumbotron"
      >
        <div className="main">
          <container text>
            <h2
              className="innerdivtitles"
              style={{
                fontSize: "24px",
				marginTop: -15,
                marginBottom: 20,
              }}
            >
              Enter your email to get a password reset link
            </h2>
            {!successAlertmessage == "" ? (
              <Alert
                style={{
                  zIndex: 1,
                  border: ".9px solid darkgreen",
                  fontSize: 14,
                  marginBottom: 12,
				  marginRight: 11
                }}
                severity="success"
              >
                <b>{successAlertmessage}</b>
              </Alert>
            ) : null}

            {!errorAlertmessage == "" ? (
              <Alert
                style={{
                  zIndex: 1,
                  border: ".9px solid darkred",
                  fontSize: 14,
                  marginBottom: 12,
                }}
                severity="error"
              >
                <b>{errorAlertmessage}</b>
              </Alert>
            ) : null}

            <Form onSubmit={onSubmit} className="submitForm">
              {loading ? (
                <Dimmer active inverted size="massive">
                  <Loader inverted>Loading...</Loader>
                </Dimmer>
              ) : null}
              <Segment
                style={{
                  border: ".9px solid gray",
                  width: "530px",
                }}
              >
                <Form.Input
                  fluid
                  required
                  icon="mail"
                  iconPosition="left"
                  label="Email"
                  placeholder="Email..."
                  name="email"
                  type="email"
                  value={email}
                  onChange={onChange}
                />
                <Button
				  disabled={disableSubmit}
                  style={{ float: "right" }}
                  primary
                  icon="signup"
                  type="submit"
                  content="Submit"
                />
              </Segment>
            </Form>
          </container>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
