import React from 'react'
import "./SearchBox.css"
function SearchBox(props) {
    return (
        <div>
            <input
            id='searchboxdashboard'
            onChange={props.handleInput} type ="text"
            style= {{borderRadius: '5px', height: '35px', padding:'8px', border: '.5px solid darkgray'}}
            placeholder="🔎 Search Name, Phone, Email or Org..."/>
        </div>
    )
}

export default SearchBox;