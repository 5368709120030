import React, {Component} from 'react'
import {connect} from 'react-redux'
import * as action from '../../../store/actions/index'
import moment from 'moment'
import Fragment from '../../../utils/Fragment'

class DashHeading extends Component {

  constructor(){
    super();
    this.state = {
      dashHeadingSwitch: true,
      error: null
    }
  }


render(){


  let dashheading = null

  if(this.state.dashHeadingSwitch){
    dashheading = 
    <div>
    <div style={{color: "#505050", marginLeft: 5, fontFamily: "verdana",}}>
      <div 
        style={{ fontSize: "14px" }}>
        <b style={{color:"#505050"}}>Event Name:</b> <span style={{top:0, fontSize: "14px", fontFamily: "Verdana"}}>{this.props.dashHeadingName}</span></div>
        <small style={{fontSize: 11}}>
        <b>Created:</b> {moment(this.props.dashHeadingTime).format('MM/D/YY, h:mm A')} {' '}
          {/* {moment(this.props.dashHeadingTime).format('dddd')}, {' '}
          {moment(this.props.dashHeadingTime).format('Do')} {' '}
        <i>{moment(this.props.dashHeadingTime).format('MMMM')}</i>*/}</small> 
  
    </div>

    </div>
  } else {
    dashheading =
      <div className="input-group DashInput">
   
    </div>
  }

  return (
    <Fragment>  
      {dashheading}
    </Fragment>
  )}
}



export default DashHeading;