import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export class DeleteMailSettings extends Component {
  onSubmit = (e) => {
    e.preventDefault();

    try {
      this.props.deleteapikey();

      toast.warning(
        <div style={{ fontSize: "16px" }}>
          <b>API Key deleted!</b>
        </div>,
        {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    } catch {
      toast.error(
        <div style={{ fontSize: "16px" }}>
          <b>An error occurred deleting this Mail Setting.</b>
        </div>,
        {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
    this.props.onHide();
  };

  closeDeleteAPIModal = () => {
    this.props.onHide();
  };

  render() {
    let onSubmit = this.onSubmit.bind(this);

    return (
      <div>
        <ToastContainer
          position="top-left"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Delete Mail Settings
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <form style={{ display: "flex" }}>
                <p>
                  <b>Are you Sure you would like to Delete your emailing account settings?</b>
                  <br />
                  (This Action cannot be Undone!)
                </p>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={onSubmit}>
              Confirm
            </Button>
            <Button variant="danger" onClick={this.closeDeleteAPIModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default DeleteMailSettings;
