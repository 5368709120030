import React, { Component } from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import axios from "axios";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import './Contact.css'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  // align-items: center;
  padding: 20px;
  padding-left: 100px;
  font-family: Verdana, Geneva, Helvetica, sans-serif;
`;

const ContactInfo = styled.div`
  margin-bottom: 20px;
  h1 {
    font-size: 24px;
  }
  p {
    font-size: 16px;
  }
`;

const ContactForm = styled.form`
  width: 100%;
  max-width: 400px;
  align-items: left;
  label {
    font-size: 18px;
    margin-bottom: 8px;
  }
  input,
  textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
  }
`;

class ContactPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      message: "",
    };
  }

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    // You can add code here to handle form submission, e.g., send the data to a server or display a thank you message.

    axios
      .post("/api/users/contact", this.state)
      .then((res) => {
      console.log(res);

      if(res.data.success == true){
      toast.success(
        <div style={{ fontSize: "16px" }}><b>Contact Message has been Received</b></div>,
        {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
      }
      else{
        toast.error(
          <div style={{ fontSize: "16px" }}><b>An unknown error has occurred</b></div>,
          {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
      })
      .catch((err) => {
        console.log(err.response);
        toast.error(
          <div style={{ fontSize: "16px" }}><b>An unknown error has occurred</b></div>,
          {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      });
  };

  render() {
    return (
      <ContactPageWrapper>
   <ToastContainer
          position="top-left"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div>
          <h1
            className="eventlist_titles"
            style={{
              textAlign: "center",
              marginTop: 35,
              marginBottom: 15,
            }}
          >
            <b style={{
              fontFamily:"Verdana, Helvetica, sans-serif",
              fontWeight: "bold"}}>Contact Us</b>
          </h1>

          <hr className="solid"></hr>
        </div>
        <ContactInfo>
          <p>
            <h4 style={{fontFamily:"Verdana, Helvetica, sans-serif", fontSize: "18px"}}><b>Reach out to us for any questions or suggestions.</b></h4>
          </p>

          <hr className="solid"></hr>
          <p>
            <b>Email:</b> contact@eventsensei.com
          </p>
          <p>
            <b>Office Phone:</b> 773-934-5554
          </p>
        </ContactInfo>
        <ContactForm onSubmit={this.handleSubmit}>
          <label htmlFor="name">
            <b>Name:</b>
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={this.state.name}
            onChange={this.handleInputChange}
            required
          />
          <label htmlFor="email">
            <b>Email:</b>
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={this.state.email}
            onChange={this.handleInputChange}
            required
          />
          <label htmlFor="message">
            <b>Message:</b>
          </label>
          <textarea
            id="messagaga"
            name="message"
            value={this.state.message}
            onChange={this.handleInputChange}
            rows="5"
            required
          ></textarea>
          <button id="contactsubmit" className="contactsubmit" type="submit">Submit</button>
        </ContactForm>
      </ContactPageWrapper>
    );
  }
}

export default ContactPage;
