import React, { useState, useEffect } from "react";
import "./Office.css";
import axios from "axios";
import moment from "moment";
import * as actions from "../../store/actions/index";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import csvDownload from 'json-to-csv-export'

const Office = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [masterUsername, setMasterUsername] = useState("");
  const [resetUsername, setResetUsername] = useState("");
  const [resetPassword, setResetPassword] = useState("");
  const [disableMasterLogin, setDisableMasterLogin] = useState(false);
  const [disableMasterReset, setDisableMasterReset] = useState(false);
  const accountDetails = useSelector((state) => state);

  const handleMasterLoginUsernameChange = (event) => {
    setMasterUsername(event.target.value);
  };

  const handleResetUsernameChange = (event) => {
    setResetUsername(event.target.value);
  };

  const handleResetPasswordChange = (event) => {
    setResetPassword(event.target.value);
  };

  const runReport = () => {

   let today_date = moment(Date.now()).format("MM-D-YY")
    console.log("run report")
      csvDownload(allUsers, `Customer_Report_${today_date}.csv` )
      toast.success(
        <div style={{ fontSize: "16px" }}><b>Customer Report Downloaded!</b></div>,
        {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }

  const dispatch = useDispatch();

  const history = useHistory();

  const submitMasterPassword = (event) => {
    event.preventDefault();
    // Add your authentication logic here
    console.log("Username:", masterUsername);
    let userData = {};
    userData.email = masterUsername;
    userData.username = masterUsername;
    console.log("UserData");
    console.log(userData);
    dispatch(actions.masterloginUser(userData, history));
    console.log("frontend call finished");
    setDisableMasterLogin(true);

    toast.success(
      <div style={{ fontSize: "16px" }}><b>Successfully Impersonated User</b></div>,
      {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );
  };

  const submitResetPassword = (event) => {
    event.preventDefault();
    console.log("Username:", masterUsername);
    let resetpasswordData = {};

    resetpasswordData.email = resetUsername;
    resetpasswordData.username = resetUsername;
    resetpasswordData.password = resetPassword;
    resetpasswordData.confirmPassword = resetPassword;

    axios
      .post("/api/users/masterreset-password/", resetpasswordData)
      .then((res) => {
        // console.log(res);

        toast.success(
          <div style={{ fontSize: "16px" }}><b>Successfully reset user's password</b></div>,
          {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      })
      .catch((err) => {
        console.log(err);
        toast.success(
          <div style={{ fontSize: "16px" }}><b>An error occurred resetting this user's password.</b></div>,
          {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      });

    setDisableMasterReset(true);
  };

  useEffect(() => {
    console.log("command api call");
    console.log(accountDetails);
    console.log("accountDetails");
  //   console.log(allUsers.map((item) => (
  // item.state)))
  // console.log([...new Set(allUsers.map((item) => (
  //   item.state)))].length)

    axios
      .get("/api/users/getallusers/")
      .then((res) => {
        console.log("res from Office");
        console.log(res.data);
        console.log(res.data.user.length);
        setAllUsers(res.data.user);
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, [accountDetails]);

  let authRedirect = null;
  if (!accountDetails.auth.isAuthenticated) {
    authRedirect = <Redirect to="/" />;
  }

  // Redirect to Home if a regular customer tries accessing corporate resources
  let authRedirectOffice = null;
  if(accountDetails.auth.user.superuser !== true){
    authRedirectOffice = <Redirect to="/" />;
  }

  let headers = [
    "Name",
    "Email",
    "Phone",
    "Role",
    "Subscription",
    "Address",
    "City",
    "State",
    "Country",
    "Registration Date",
    "Dashboard API Calls",
    "Dashboard API Fetch",
    "User API Calls",
    "User API Fetch",
  ];

  const masterlogin = (
    <div style={styles.masterlogin}>
      <h2 style={styles.headingmasterlogin}>Impersonate Login</h2>
      <form onSubmit={submitMasterPassword} style={styles.form}>
        <div>
          <label style={styles.label}>
            <span> Email or Username</span>
            <input
              type="text"
              value={masterUsername}
              onChange={handleMasterLoginUsernameChange}
              style={styles.input}
            />
          </label>
        </div>
        <br />
        <div style={{ textAlign: "center" }}>
          <button
            disabled={disableMasterLogin}
            type="submit"
            style={styles.masterloginbutton}
          >
            Login
          </button>
        </div>
      </form>
    </div>
  );

  const resetuserpasswords = (
    <div style={styles.masterlogin}>
      <h2 style={styles.headingresetpassword}>Reset User Passwords</h2>
      <form onSubmit={submitResetPassword} style={styles.form}>
        <div>
          <label style={styles.label}>
            <span> Email or Username</span>
            <input
              type="text"
              value={resetUsername}
              onChange={handleResetUsernameChange}
              style={styles.input}
            />
          </label>
        </div>
        <br />
        <div>
          <label style={styles.label}>
            <span> Password</span>
            <input
              type="text"
              value={resetPassword}
              onChange={handleResetPasswordChange}
              style={styles.input}
            />
          </label>
        </div>
        <br />
        <div style={{ textAlign: "center" }}>
          <button
            disabled={disableMasterReset}
            type="submit"
            style={styles.resetpasswordbutton}
          >
            Reset Password
          </button>
        </div>
      </form>
    </div>
  );

  const userBullets = (
    <div style={styles.userbullets}>
      <h2 style={styles.headingclientstats}>Client Statistics</h2>
      <ul style={{width: 320}}>
        <li style={{fontSize: "21px"}}>
          Number of Users: {allUsers.length}
        </li>
        <li style={{fontSize: "21px"}}>
           Number of States: {[...new Set(allUsers.map((item) => (
    item.state)))].length}
        </li>
        {/* <div style={{fontSize: "10px"}}>
     {[...new Set(allUsers.map((item) => (
    item.state + ", ")))]}
        </div> */}
        <li style={{fontSize: "21px"}}>
           Number of Cities: {[...new Set(allUsers.map((item) => (
    item.city)))].length}
        </li>
        {/* <div style={{fontSize: "10px"}}>
     {[...new Set(allUsers.map((item) => (
    `${item.city}-${item.state}, `)))]}
        </div> */}
      </ul>
    </div>
  )


  const userBulletsLocations = (
    
    <div style={styles.userbullets}>
      <h2 style={styles.headingclientlocations}>Customer Locations</h2>
   
        <div style={{fontSize: "14px"}}>
      <li>
     {[...new Set(allUsers.map((item) => (
    `${item.city}-${item.state}` )))]}
    </li>
        </div>
    </div>
  )

  const userReportButton = (
    <button
    onClick={runReport}
className="userReportButton"
style={{
  // background: "linear-gradient(to right, #F67280 , #F75D59)",
  // background: "linear-gradient(to bottom right, #F67280 , #CC0000)",
  // border: "none",
  // color: "white",
  // padding: 15,
  // fontSize: 15,
  // width: "18%",
  // fontFamily: "Verdana"

}}>Download Customer Report</button>
  )

  const lists = (
    
    <div
      className="officelistcontainer"
      style={{ paddingRight: -9, background: "white" }}
    >
      
      <table className="prTable" style={{ marginRight: -5 }}>
        {/* <thead style={{backgroundColor: "#F8F8F8"}}> */}
        <thead>
          <tr className="prTableHeader">
            {headers.map((header) => (
              <th
                style={{
                  minWidth: 65,
                  color: "#484848",
                  paddingTop: 17,
                  paddingLeft: 5,
                  border: ".9px solid darkgray",
                }}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody style={{}}>
          {allUsers.map((item) => (
            <tr className="prTableRow" style={{border: ".9px solid darkgray", color: "#404040" }}>
              <td
                className="prTableCell"
                style={{
                  borderTop: ".9px solid darkgray",
                  borderBottom: ".9px solid darkgray",
                  fontSize: "13px",
                  paddingRight: 18,
                  paddingTop: 18,
                  paddingBottom: 18,
                  paddingLeft: 10,
                  border: ".9px solid darkgray",
                  fontFamily:
                    "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                  background: item.status === "Checked In" ? "#b9ecdb" : null,
                }}
              >
                <div onClick={(e) => console.log(item)}>{item.name}</div>{" "}
              </td>
              <td
                className="prTableCell"
                style={{
                  wordWrap: "break-word",
                  maxWidth: "220px",
                  borderTop: ".9px solid darkgray",
                  borderBottom: ".9px solid darkgray",
                  fontSize: "13px",
                  paddingRight: 18,
                  paddingTop: 18,
                  paddingBottom: 18,
                  paddingLeft: 10,
                  border: ".9px solid darkgray",
                  fontFamily:
                    "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                  background: item.status === "Checked In" ? "#b9ecdb" : null,
                }}
              >
                <div onClick={(e) => console.log(item)}>{item.email}</div>{" "}
              </td>
              <td
                className="prTableCell"
                style={{
                  borderTop: ".9px solid darkgray",
                  borderBottom: ".9px solid darkgray",
                  fontSize: "12px",
                  paddingRight: 18,
                  paddingTop: 18,
                  paddingBottom: 18,
                  paddingLeft: 10,
                  border: ".9px solid darkgray",
                  fontFamily:
                    "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                  background: item.status === "Checked In" ? "#b9ecdb" : null,
                }}
              >
                <div onClick={(e) => console.log(item)}>{item.phone}</div>{" "}
              </td>
              <td
                className="prTableCell"
                style={{
                  borderTop: ".9px solid darkgray",
                  borderBottom: ".9px solid darkgray",
                  fontSize: "13px",
                  paddingRight: 18,
                  paddingTop: 18,
                  paddingBottom: 18,
                  paddingLeft: 10,
                  border: ".9px solid darkgray",
                  width: 200,
                  fontFamily:
                    "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                  background: item.status === "Checked In" ? "#b9ecdb" : null,
                }}
              >
                <div onClick={(e) => console.log(item)}>{item.role}</div>{" "}
              </td>

              <td
                className="prTableCell"
                style={{
                  borderTop: ".9px solid darkgray",
                  borderBottom: ".9px solid darkgray",
                  fontSize: "13px",
                  paddingRight: 18,
                  paddingTop: 18,
                  paddingBottom: 18,
                  paddingLeft: 10,
                  border: ".9px solid darkgray",
                  width: 200,
                  fontFamily:
                    "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                  background: item.status === "Checked In" ? "#b9ecdb" : null,
                }}
              >
                <div onClick={(e) => console.log(item)}>
                  {item.subscription ? "true" : "false"}
                </div>{" "}
              </td>
              <td
                className="prTableCell"
                style={{
                  borderTop: ".9px solid darkgray",
                  borderBottom: ".9px solid darkgray",
                  fontSize: "13px",
                  paddingRight: 18,
                  paddingTop: 18,
                  paddingBottom: 18,
                  paddingLeft: 10,
                  border: ".9px solid darkgray",
                  width: 200,
                  fontFamily:
                    "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",

                  background: item.status === "Checked In" ? "#b9ecdb" : null,
                }}
              >
                <div onClick={(e) => console.log(item)}>{item.address}</div>{" "}
              </td>
              <td
                className="prTableCell"
                style={{
                  borderTop: ".9px solid darkgray",
                  borderBottom: ".9px solid darkgray",
                  fontSize: "13px",
                  paddingRight: 18,
                  paddingTop: 18,
                  paddingBottom: 18,
                  paddingLeft: 10,
                  border: ".9px solid darkgray",
                  width: 200,
                  fontFamily:
                    "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                  background: item.status === "Checked In" ? "#b9ecdb" : null,
                  color: item.status === "Checked In" ? "darkgreen" : null,
                  fontWeight: item.status === "Checked In" ? "bold" : null,
                }}
              >
                <div onClick={(e) => console.log(item)}>
                  {item.city}
                  {item.status === "Checked In" ? " ☑️" : null}
                  {/* {item.status === "Checked In" ? " ✅" : null} */}
                </div>
              </td>
              <td
                className="prTableCell"
                style={{
                  borderTop: ".9px solid darkgray",
                  borderBottom: ".9px solid darkgray",
                  fontSize: "13px",
                  paddingRight: 18,
                  paddingTop: 18,
                  paddingBottom: 18,
                  paddingLeft: 10,
                  border: ".9px solid darkgray",
                  width: 200,
                  fontFamily:
                    "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                  background: item.status === "Checked In" ? "#b9ecdb" : null,
                  color: item.status === "Checked In" ? "darkgreen" : null,
                  fontWeight: item.status === "Checked In" ? "bold" : null,
                }}
              >
                <div onClick={(e) => console.log(item)}>{item.state}</div>
              </td>
              <td
                className="prTableCell"
                style={{
                  borderTop: ".9px solid darkgray",
                  borderBottom: ".9px solid darkgray",
                  fontSize: "13px",
                  paddingRight: 18,
                  paddingTop: 18,
                  paddingBottom: 18,
                  paddingLeft: 10,
                  border: ".9px solid darkgray",
                  width: 200,
                  fontFamily:
                    "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                  background: item.status === "Checked In" ? "#b9ecdb" : null,
                  color: item.status === "Checked In" ? "darkgreen" : null,
                  fontWeight: item.status === "Checked In" ? "bold" : null,
                }}
              >
                <div onClick={(e) => console.log(item)}>{item.country}</div>
              </td>
              <td
                className="prTableCell"
                style={{
                  borderTop: ".9px solid darkgray",
                  borderBottom: ".9px solid darkgray",
                  fontSize: "13px",
                  paddingRight: 18,
                  paddingTop: 18,
                  paddingBottom: 18,
                  paddingLeft: 10,
                  border: ".9px solid darkgray",
                  width: 200,
                  minWidth: 160,
                  fontFamily:
                    "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                  background: item.status === "Checked In" ? "#b9ecdb" : null,
                  color: item.status === "Checked In" ? "darkgreen" : null,
                  fontWeight: item.status === "Checked In" ? "bold" : null,
                }}
              >
                <div onClick={(e) => console.log(item)}>
                  {moment(item.registration_date).format("MM/D/YY, h:mm A")}
                </div>
              </td>

            {/* User Dashboard API Count */}
              <td
                className="prTableCell"
                style={{
                  borderTop: ".9px solid darkgray",
                  borderBottom: ".9px solid darkgray",
                  fontSize: "13px",
                  paddingRight: 18,
                  paddingTop: 18,
                  paddingBottom: 18,
                  paddingLeft: 10,
                  border: ".9px solid darkgray",
                  minWidth: 100,
                  fontFamily:
                    "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                  background: item.status === "Checked In" ? "#b9ecdb" : null,
                  color: item.status === "Checked In" ? "darkgreen" : null,
                  fontWeight: item.status === "Checked In" ? "bold" : null,
                }}
              >
                <div onClick={(e) => console.log(item)}>{item.dashboard_api_calls}</div>
              </td>
                {/* User Dashboard API Count - Fetch */}
              <td
                className="prTableCell"
                style={{
                  borderTop: ".9px solid darkgray",
                  borderBottom: ".9px solid darkgray",
                  fontSize: "13px",
                  paddingRight: 18,
                  paddingTop: 18,
                  paddingBottom: 18,
                  paddingLeft: 10,
                  border: ".9px solid darkgray",
                  width: 200,
                  minWidth: 100,
                  fontFamily:
                    "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                  background: item.status === "Checked In" ? "#b9ecdb" : null,
                  color: item.status === "Checked In" ? "darkgreen" : null,
                  fontWeight: item.status === "Checked In" ? "bold" : null,
                }}
              >
                <div onClick={(e) => console.log(item)}>{item.dashboard_fetch_calls}</div>
              </td>

                {/* User API Counts */}
              <td
                className="prTableCell"
                style={{
                  borderTop: ".9px solid darkgray",
                  borderBottom: ".9px solid darkgray",
                  fontSize: "13px",
                  paddingRight: 18,
                  paddingTop: 18,
                  paddingBottom: 18,
                  paddingLeft: 10,
                  border: ".9px solid darkgray",
                  width: 200,
                  minWidth: 100,
                  fontFamily:
                    "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                  background: item.status === "Checked In" ? "#b9ecdb" : null,
                  color: item.status === "Checked In" ? "darkgreen" : null,
                  fontWeight: item.status === "Checked In" ? "bold" : null,
                }}
              >
                <div onClick={(e) => console.log(item)}>{item.user_api_calls}</div>
              </td>
               {/* User API Counts - Fetch */}
              <td
                className="prTableCell"
                style={{
                  borderTop: ".9px solid darkgray",
                  borderBottom: ".9px solid darkgray",
                  fontSize: "13px",
                  paddingRight: 18,
                  paddingTop: 18,
                  paddingBottom: 18,
                  paddingLeft: 10,
                  border: ".9px solid darkgray",
                  width: 200,
                  minWidth: 100,
                  fontFamily:
                    "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                  background: item.status === "Checked In" ? "#b9ecdb" : null,
                  color: item.status === "Checked In" ? "darkgreen" : null,
                  fontWeight: item.status === "Checked In" ? "bold" : null,
                }}
              >
                <div onClick={(e) => console.log(item)}>{item.user_fetch_calls}</div>
              </td>
              {/* <td style={{background: item.status === "Checked In" ? '#b9ecdb': null}}>
 className="fas fa-pen fa-xl volunteerResetPasswordPen"></>
          
            </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div>
      {authRedirect}
      {authRedirectOffice}
      <ToastContainer
          position="top-left"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      <h1
        style={{
          textAlign: "center",
          marginTop: 25,
          fontFamily: "Verdana, Georgia",
          fontWeight: "bold",
          color: "#505050",
        }}
      >
        Command Center
      </h1>
      <div
        style={{
          display: "flex",
        }}
      >
        {masterlogin}
        {resetuserpasswords}
        {userBullets}
        {userBulletsLocations}
      </div>
      {userReportButton}
      <hr></hr>
      {lists}
    </div>
  );
};

const styles = {
  masterlogin: {
    maxWidth: "400px",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    marginTop: "3%",
    marginLeft: "3%",
    boxShadow: "1px 1px 1px 1px darkgray",
  },
  userbullets: {
    maxWidth: "400px",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    marginTop: "3%",
    marginLeft: "3%",
    boxShadow: "1px 1px 1px 1px darkgray",
    color: "#585858",
    fontFamily: "Verdana"
  },
  headingmasterlogin: {
    textAlign: "center",
    color: "dimgray",
    textShadow: "1px 1px 1px #505050",
  },
  headingresetpassword: {
    textAlign: "center",
    color: "dimgray",
    textShadow: "1px 1px 1px #505050",
  },
  headingclientstats: {
    marginBottom: "15px",
    textAlign: "center",
    color: "dimgray",
    textShadow: "1px 1px 1px #505050",
    width: 400
  },
  headingclientlocations: {
  marginBottom: "15px",
  textAlign: "center",
  color: "dimgray",
  textShadow: "1px 1px 1px #505050",
  width: 300
},
  form: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
  },
  label: {
    color: "#555",
    fontWeight: "bold",
    fontFamily: "Verdana",
  },
  input: {
    padding: "8px",
    fontSize: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    marginBottom: "4px",
    fontFamily: "Verdana, Georgia",
    marginLeft: 10,
    width: "300px",
    marginTop: "10px",
  },
  masterloginbutton: {
    backgroundColor: "#00674b",
    color: "white",
    padding: "10px",
    fontSize: "14px",
    width: "70%",
    borderRadius: "30px",
    cursor: "pointer",
    fontWeight: "bold",
    fontFamily: "Verdana",
  },
  resetpasswordbutton: {
    backgroundColor: "#005A9C",
    color: "white",
    padding: "10px",
    fontSize: "14px",
    width: "70%",
    borderRadius: "30px",
    cursor: "pointer",
    fontWeight: "bold",
    fontFamily: "Verdana",
  },
};

export default Office;


// import React, { useState, useEffect } from "react";
// import "./Office.css";
// import axios from "axios";
// import moment from "moment";
// import * as actions from "../../store/actions/index";
// import { useDispatch, useSelector } from "react-redux";
// import { useHistory, useParams } from "react-router-dom";
// import { Redirect } from "react-router-dom";
// import { ToastContainer, toast } from "react-toastify";
// import csvDownload from 'json-to-csv-export'
// import {Bar} from 'react-chartjs-2';


// const Office = () => {
//   const [allUsers, setAllUsers] = useState([]);
//   const [apiTracking, setApiTracking] = useState([]);
//   const [masterUsername, setMasterUsername] = useState("");
//   const [resetUsername, setResetUsername] = useState("");
//   const [resetPassword, setResetPassword] = useState("");
//   const [disableMasterLogin, setDisableMasterLogin] = useState(false);
//   const [disableMasterReset, setDisableMasterReset] = useState(false);
//   const accountDetails = useSelector((state) => state);

//   const handleMasterLoginUsernameChange = (event) => {
//     setMasterUsername(event.target.value);
//   };

//   const handleResetUsernameChange = (event) => {
//     setResetUsername(event.target.value);
//   };

//   const handleResetPasswordChange = (event) => {
//     setResetPassword(event.target.value);
//   };

//   const runReport = () => {

//    let today_date = moment(Date.now()).format("MM-D-YY")
//     console.log("run report")
//       csvDownload(allUsers, `Customer_Report_${today_date}.csv` )
//       toast.success(
//         <div style={{ fontSize: "16px" }}><b>Customer Report Downloaded!</b></div>,
//         {
//           position: "top-right",
//           autoClose: 2500,
//           hideProgressBar: false,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "light",
//         }
//       );
//     }

//   const dispatch = useDispatch();

//   const history = useHistory();

//   const submitMasterPassword = (event) => {
//     event.preventDefault();
//     // Add your authentication logic here
//     console.log("Username:", masterUsername);
//     let userData = {};
//     userData.email = masterUsername;
//     userData.username = masterUsername;
//     console.log("UserData");
//     console.log(userData);
//     dispatch(actions.masterloginUser(userData, history));
//     console.log("frontend call finished");
//     setDisableMasterLogin(true);

//     toast.success(
//       <div style={{ fontSize: "16px" }}><b>Successfully Impersonated User</b></div>,
//       {
//         position: "top-right",
//         autoClose: 2500,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "light",
//       }
//     );
//   };

//   const submitResetPassword = (event) => {
//     event.preventDefault();
//     console.log("Username:", masterUsername);
//     let resetpasswordData = {};

//     resetpasswordData.email = resetUsername;
//     resetpasswordData.username = resetUsername;
//     resetpasswordData.password = resetPassword;
//     resetpasswordData.confirmPassword = resetPassword;

//     axios
//       .post("/api/users/masterreset-password/", resetpasswordData)
//       .then((res) => {
//         // console.log(res);

//         toast.success(
//           <div style={{ fontSize: "16px" }}><b>Successfully reset user's password</b></div>,
//           {
//             position: "top-right",
//             autoClose: 2500,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: "light",
//           }
//         );
//       })
//       .catch((err) => {
//         console.log(err);
//         toast.success(
//           <div style={{ fontSize: "16px" }}><b>An error occurred resetting this user's password.</b></div>,
//           {
//             position: "top-right",
//             autoClose: 2500,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: "light",
//           }
//         );
//       });

//     setDisableMasterReset(true);
//   };

//   useEffect(() => {
//     console.log("command api call");
//     console.log(accountDetails);
//     console.log("accountDetails");
//   //   console.log(allUsers.map((item) => (
//   // item.state)))
//   // console.log([...new Set(allUsers.map((item) => (
//   //   item.state)))].length)

//     axios
//       .get("/api/users/getallusers/")
//       .then((res) => {
//         console.log("res from Office users");
//         console.log(res.data);
//         console.log(res.data.user.length);
//         setAllUsers(res.data.user);
//       })
//       .catch((err) => {
//         console.log(err.response);
//       });

//       console.log("secondary api-------------");

//       axios
//       .get("/api/users/getallapis/")
//       .then((res) => {
//         console.log("res from Office apis");
//         console.log(res.data.apitracking);
//         setApiTracking(res.data.apitracking);
//       })
//       .catch((err) => {
//         console.log(err.response);
//       });
//   }, [accountDetails]);

//   let authRedirect = null;
//   if (!accountDetails.auth.isAuthenticated) {
//     authRedirect = <Redirect to="/" />;
//   }

//   // Redirect to Home if a regular customer tries accessing corporate resources
//   let authRedirectOffice = null;
//   if(!accountDetails.auth.user.superuser){
//     authRedirectOffice = <Redirect to="/" />;
//   }

//   let headers = [
//     "Name",
//     "Email",
//     "Phone",
//     "Role",
//     "Subscription",
//     "Address",
//     "City",
//     "State",
//     "Country",
//     "Registration Date",
//     "Dashboard API Calls",
//     "Dashboard API Fetch",
//     "User API Calls",
//     "User API Fetch",
//   ];

//   const masterlogin = (
//     <div style={styles.masterlogin}>
//       <h2 style={styles.headingmasterlogin}>Impersonate Login</h2>
//       <form onSubmit={submitMasterPassword} style={styles.form}>
//         <div>
//           <label style={styles.label}>
//             <span> Email or Username</span>
//             <input
//               type="text"
//               value={masterUsername}
//               onChange={handleMasterLoginUsernameChange}
//               style={styles.input}
//             />
//           </label>
//         </div>
//         <br />
//         <div style={{ textAlign: "center" }}>
//           <button
//             disabled={disableMasterLogin}
//             type="submit"
//             style={styles.masterloginbutton}
//           >
//             Login
//           </button>
//         </div>
//       </form>
//     </div>
//   );

//   const resetuserpasswords = (
//     <div style={styles.masterlogin}>
//       <h2 style={styles.headingresetpassword}>Reset User Passwords</h2>
//       <form onSubmit={submitResetPassword} style={styles.form}>
//         <div>
//           <label style={styles.label}>
//             <span> Email or Username</span>
//             <input
//               type="text"
//               value={resetUsername}
//               onChange={handleResetUsernameChange}
//               style={styles.input}
//             />
//           </label>
//         </div>
//         <br />
//         <div>
//           <label style={styles.label}>
//             <span> Password</span>
//             <input
//               type="text"
//               value={resetPassword}
//               onChange={handleResetPasswordChange}
//               style={styles.input}
//             />
//           </label>
//         </div>
//         <br />
//         <div style={{ textAlign: "center" }}>
//           <button
//             disabled={disableMasterReset}
//             type="submit"
//             style={styles.resetpasswordbutton}
//           >
//             Reset Password
//           </button>
//         </div>
//       </form>
//     </div>
//   );

//   const userBullets = (
//     <div style={styles.userbullets}>
//       <h2 style={styles.headingclientstats}>Client Statistics</h2>
//       <ul style={{width: 320}}>
//         <li style={{fontSize: "21px"}}>
//           Number of Users: {allUsers.length}
//         </li>
//         <li style={{fontSize: "21px"}}>
//            Number of States: {[...new Set(allUsers.map((item) => (
//     item.state)))].length}
//         </li>
//         {/* <div style={{fontSize: "10px"}}>
//      {[...new Set(allUsers.map((item) => (
//     item.state + ", ")))]}
//         </div> */}
//         <li style={{fontSize: "21px"}}>
//            Number of Cities: {[...new Set(allUsers.map((item) => (
//     item.city)))].length}
//         </li>
//         {/* <div style={{fontSize: "10px"}}>
//      {[...new Set(allUsers.map((item) => (
//     `${item.city}-${item.state}, `)))]}
//         </div> */}
//       </ul>
//     </div>
//   )


//   const userBulletsLocations = (
    
//     <div style={styles.userbullets}>
//       <h2 style={styles.headingclientlocations}>Customer Locations</h2>
   
//         <div style={{fontSize: "10px"}}>
//      {[...new Set(allUsers.map((item) => (
    
//     `${item.city}-${item.state}, ` )))]}
  
//         </div>
//     </div>
//   )

//   const userReportButton = (
//     <button
//     onClick={runReport}
// className="userReportButton"
// style={{
//   // background: "linear-gradient(to right, #F67280 , #F75D59)",
//   // background: "linear-gradient(to bottom right, #F67280 , #CC0000)",
//   // border: "none",
//   // color: "white",
//   // padding: 15,
//   // fontSize: 15,
//   // width: "18%",
//   // fontFamily: "Verdana"

// }}>Download Customer Report</button>
//   )

//   const lists = (
    
//     <div
//       className="officelistcontainer"
//       style={{ paddingRight: -9, background: "white" }}
//     >
      
//       <table className="prTable" style={{ marginRight: -5 }}>
//         {/* <thead style={{backgroundColor: "#F8F8F8"}}> */}
//         <thead>
//           <tr className="prTableHeader">
//             {headers.map((header) => (
//               <th
//                 style={{
//                   minWidth: 65,
//                   color: "#484848",
//                   paddingTop: 17,
//                   paddingLeft: 5,
//                   border: ".9px solid darkgray",
//                 }}
//               >
//                 {header}
//               </th>
//             ))}
//           </tr>
//         </thead>
//         <tbody style={{}}>
//           {allUsers.map((item) => (
//             <tr className="prTableRow" style={{border: ".9px solid darkgray", color: "#404040" }}>
//               <td
//                 className="prTableCell"
//                 style={{
//                   borderTop: ".9px solid darkgray",
//                   borderBottom: ".9px solid darkgray",
//                   fontSize: "13px",
//                   paddingRight: 18,
//                   paddingTop: 18,
//                   paddingBottom: 18,
//                   paddingLeft: 10,
//                   border: ".9px solid darkgray",
//                   fontFamily:
//                     "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
//                   background: item.status === "Checked In" ? "#b9ecdb" : null,
//                 }}
//               >
//                 <div onClick={(e) => console.log(item)}>{item.name}</div>{" "}
//               </td>
//               <td
//                 className="prTableCell"
//                 style={{
//                   wordWrap: "break-word",
//                   maxWidth: "220px",
//                   borderTop: ".9px solid darkgray",
//                   borderBottom: ".9px solid darkgray",
//                   fontSize: "13px",
//                   paddingRight: 18,
//                   paddingTop: 18,
//                   paddingBottom: 18,
//                   paddingLeft: 10,
//                   border: ".9px solid darkgray",
//                   fontFamily:
//                     "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
//                   background: item.status === "Checked In" ? "#b9ecdb" : null,
//                 }}
//               >
//                 <div onClick={(e) => console.log(item)}>{item.email}</div>{" "}
//               </td>
//               <td
//                 className="prTableCell"
//                 style={{
//                   borderTop: ".9px solid darkgray",
//                   borderBottom: ".9px solid darkgray",
//                   fontSize: "12px",
//                   paddingRight: 18,
//                   paddingTop: 18,
//                   paddingBottom: 18,
//                   paddingLeft: 10,
//                   border: ".9px solid darkgray",
//                   fontFamily:
//                     "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
//                   background: item.status === "Checked In" ? "#b9ecdb" : null,
//                 }}
//               >
//                 <div onClick={(e) => console.log(item)}>{item.phone}</div>{" "}
//               </td>
//               <td
//                 className="prTableCell"
//                 style={{
//                   borderTop: ".9px solid darkgray",
//                   borderBottom: ".9px solid darkgray",
//                   fontSize: "13px",
//                   paddingRight: 18,
//                   paddingTop: 18,
//                   paddingBottom: 18,
//                   paddingLeft: 10,
//                   border: ".9px solid darkgray",
//                   width: 200,
//                   fontFamily:
//                     "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
//                   background: item.status === "Checked In" ? "#b9ecdb" : null,
//                 }}
//               >
//                 <div onClick={(e) => console.log(item)}>{item.role}</div>{" "}
//               </td>

//               <td
//                 className="prTableCell"
//                 style={{
//                   borderTop: ".9px solid darkgray",
//                   borderBottom: ".9px solid darkgray",
//                   fontSize: "13px",
//                   paddingRight: 18,
//                   paddingTop: 18,
//                   paddingBottom: 18,
//                   paddingLeft: 10,
//                   border: ".9px solid darkgray",
//                   width: 200,
//                   fontFamily:
//                     "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
//                   background: item.status === "Checked In" ? "#b9ecdb" : null,
//                 }}
//               >
//                 <div onClick={(e) => console.log(item)}>
//                   {item.subscription ? "true" : "false"}
//                 </div>{" "}
//               </td>
//               <td
//                 className="prTableCell"
//                 style={{
//                   borderTop: ".9px solid darkgray",
//                   borderBottom: ".9px solid darkgray",
//                   fontSize: "13px",
//                   paddingRight: 18,
//                   paddingTop: 18,
//                   paddingBottom: 18,
//                   paddingLeft: 10,
//                   border: ".9px solid darkgray",
//                   width: 200,
//                   fontFamily:
//                     "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",

//                   background: item.status === "Checked In" ? "#b9ecdb" : null,
//                 }}
//               >
//                 <div onClick={(e) => console.log(item)}>{item.address}</div>{" "}
//               </td>
//               <td
//                 className="prTableCell"
//                 style={{
//                   borderTop: ".9px solid darkgray",
//                   borderBottom: ".9px solid darkgray",
//                   fontSize: "13px",
//                   paddingRight: 18,
//                   paddingTop: 18,
//                   paddingBottom: 18,
//                   paddingLeft: 10,
//                   border: ".9px solid darkgray",
//                   width: 200,
//                   fontFamily:
//                     "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
//                   background: item.status === "Checked In" ? "#b9ecdb" : null,
//                   color: item.status === "Checked In" ? "darkgreen" : null,
//                   fontWeight: item.status === "Checked In" ? "bold" : null,
//                 }}
//               >
//                 <div onClick={(e) => console.log(item)}>
//                   {item.city}
//                   {item.status === "Checked In" ? " ☑️" : null}
//                   {/* {item.status === "Checked In" ? " ✅" : null} */}
//                 </div>
//               </td>
//               <td
//                 className="prTableCell"
//                 style={{
//                   borderTop: ".9px solid darkgray",
//                   borderBottom: ".9px solid darkgray",
//                   fontSize: "13px",
//                   paddingRight: 18,
//                   paddingTop: 18,
//                   paddingBottom: 18,
//                   paddingLeft: 10,
//                   border: ".9px solid darkgray",
//                   width: 200,
//                   fontFamily:
//                     "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
//                   background: item.status === "Checked In" ? "#b9ecdb" : null,
//                   color: item.status === "Checked In" ? "darkgreen" : null,
//                   fontWeight: item.status === "Checked In" ? "bold" : null,
//                 }}
//               >
//                 <div onClick={(e) => console.log(item)}>{item.state}</div>
//               </td>
//               <td
//                 className="prTableCell"
//                 style={{
//                   borderTop: ".9px solid darkgray",
//                   borderBottom: ".9px solid darkgray",
//                   fontSize: "13px",
//                   paddingRight: 18,
//                   paddingTop: 18,
//                   paddingBottom: 18,
//                   paddingLeft: 10,
//                   border: ".9px solid darkgray",
//                   width: 200,
//                   fontFamily:
//                     "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
//                   background: item.status === "Checked In" ? "#b9ecdb" : null,
//                   color: item.status === "Checked In" ? "darkgreen" : null,
//                   fontWeight: item.status === "Checked In" ? "bold" : null,
//                 }}
//               >
//                 <div onClick={(e) => console.log(item)}>{item.country}</div>
//               </td>
//               <td
//                 className="prTableCell"
//                 style={{
//                   borderTop: ".9px solid darkgray",
//                   borderBottom: ".9px solid darkgray",
//                   fontSize: "13px",
//                   paddingRight: 18,
//                   paddingTop: 18,
//                   paddingBottom: 18,
//                   paddingLeft: 10,
//                   border: ".9px solid darkgray",
//                   width: 200,
//                   minWidth: 160,
//                   fontFamily:
//                     "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
//                   background: item.status === "Checked In" ? "#b9ecdb" : null,
//                   color: item.status === "Checked In" ? "darkgreen" : null,
//                   fontWeight: item.status === "Checked In" ? "bold" : null,
//                 }}
//               >
//                 <div onClick={(e) => console.log(item)}>
//                   {moment(item.registration_date).format("MM/D/YY, h:mm A")}
//                 </div>
//               </td>

//             {/* User Dashboard API Count */}
//               <td
//                 className="prTableCell"
//                 style={{
//                   borderTop: ".9px solid darkgray",
//                   borderBottom: ".9px solid darkgray",
//                   fontSize: "13px",
//                   paddingRight: 18,
//                   paddingTop: 18,
//                   paddingBottom: 18,
//                   paddingLeft: 10,
//                   border: ".9px solid darkgray",
//                   minWidth: 100,
//                   fontFamily:
//                     "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
//                   background: item.status === "Checked In" ? "#b9ecdb" : null,
//                   color: item.status === "Checked In" ? "darkgreen" : null,
//                   fontWeight: item.status === "Checked In" ? "bold" : null,
//                 }}
//               >
//                 <div onClick={(e) => console.log(item)}>{item.dashboard_api_calls}</div>
//               </td>
//                 {/* User Dashboard API Count - Fetch */}
//               <td
//                 className="prTableCell"
//                 style={{
//                   borderTop: ".9px solid darkgray",
//                   borderBottom: ".9px solid darkgray",
//                   fontSize: "13px",
//                   paddingRight: 18,
//                   paddingTop: 18,
//                   paddingBottom: 18,
//                   paddingLeft: 10,
//                   border: ".9px solid darkgray",
//                   width: 200,
//                   minWidth: 100,
//                   fontFamily:
//                     "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
//                   background: item.status === "Checked In" ? "#b9ecdb" : null,
//                   color: item.status === "Checked In" ? "darkgreen" : null,
//                   fontWeight: item.status === "Checked In" ? "bold" : null,
//                 }}
//               >
//                 <div onClick={(e) => console.log(item)}>{item.dashboard_fetch_calls}</div>
//               </td>

//                 {/* User API Counts */}
//               <td
//                 className="prTableCell"
//                 style={{
//                   borderTop: ".9px solid darkgray",
//                   borderBottom: ".9px solid darkgray",
//                   fontSize: "13px",
//                   paddingRight: 18,
//                   paddingTop: 18,
//                   paddingBottom: 18,
//                   paddingLeft: 10,
//                   border: ".9px solid darkgray",
//                   width: 200,
//                   minWidth: 100,
//                   fontFamily:
//                     "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
//                   background: item.status === "Checked In" ? "#b9ecdb" : null,
//                   color: item.status === "Checked In" ? "darkgreen" : null,
//                   fontWeight: item.status === "Checked In" ? "bold" : null,
//                 }}
//               >
//                 <div onClick={(e) => console.log(item)}>{item.user_api_calls}</div>
//               </td>
//                {/* User API Counts - Fetch */}
//               <td
//                 className="prTableCell"
//                 style={{
//                   borderTop: ".9px solid darkgray",
//                   borderBottom: ".9px solid darkgray",
//                   fontSize: "13px",
//                   paddingRight: 18,
//                   paddingTop: 18,
//                   paddingBottom: 18,
//                   paddingLeft: 10,
//                   border: ".9px solid darkgray",
//                   width: 200,
//                   minWidth: 100,
//                   fontFamily:
//                     "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
//                   background: item.status === "Checked In" ? "#b9ecdb" : null,
//                   color: item.status === "Checked In" ? "darkgreen" : null,
//                   fontWeight: item.status === "Checked In" ? "bold" : null,
//                 }}
//               >
//                 <div onClick={(e) => console.log(item)}>{item.user_fetch_calls}</div>
//               </td>
//               {/* <td style={{background: item.status === "Checked In" ? '#b9ecdb': null}}>
//  className="fas fa-pen fa-xl volunteerResetPasswordPen"></>
          
//             </td> */}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
//   const state = {
//     labels: ['January', 'February', 'March',
//              'April', 'May'],
//     datasets: [
//       {
//         label: 'Rainfall',
//         backgroundColor: 'rgba(75,192,192,1)',
//         borderColor: 'rgba(0,0,0,1)',
//         borderWidth: 2,
//         data: [65, 59, 80, 81, 56]
//       }
//     ]
//   }
//   return (
//     <div>
//       <div>
//       <div>

//       </div>
//       </div>
//     </div>
//   );
// };

// const styles = {
//   masterlogin: {
//     maxWidth: "400px",
//     padding: "20px",
//     border: "1px solid #ccc",
//     borderRadius: "8px",
//     marginTop: "3%",
//     marginLeft: "3%",
//     boxShadow: "1px 1px 1px 1px darkgray",
//   },
//   userbullets: {
//     maxWidth: "400px",
//     padding: "20px",
//     border: "1px solid #ccc",
//     borderRadius: "8px",
//     marginTop: "3%",
//     marginLeft: "3%",
//     boxShadow: "1px 1px 1px 1px darkgray",
//     color: "#585858",
//     fontFamily: "Verdana"
//   },
//   headingmasterlogin: {
//     textAlign: "center",
//     color: "dimgray",
//     textShadow: "1px 1px 1px #505050",
//   },
//   headingresetpassword: {
//     textAlign: "center",
//     color: "dimgray",
//     textShadow: "1px 1px 1px #505050",
//   },
//   headingclientstats: {
//     marginBottom: "15px",
//     textAlign: "center",
//     color: "dimgray",
//     textShadow: "1px 1px 1px #505050",
//     width: 400
//   },
//   headingclientlocations: {
//   marginBottom: "15px",
//   textAlign: "center",
//   color: "dimgray",
//   textShadow: "1px 1px 1px #505050",
//   width: 300
// },
//   form: {
//     display: "flex",
//     flexDirection: "column",
//     marginTop: "20px",
//   },
//   label: {
//     color: "#555",
//     fontWeight: "bold",
//     fontFamily: "Verdana",
//   },
//   input: {
//     padding: "8px",
//     fontSize: "16px",
//     border: "1px solid #ccc",
//     borderRadius: "4px",
//     marginBottom: "4px",
//     fontFamily: "Verdana, Georgia",
//     marginLeft: 10,
//     width: "300px",
//     marginTop: "10px",
//   },
//   masterloginbutton: {
//     backgroundColor: "#00674b",
//     color: "white",
//     padding: "10px",
//     fontSize: "14px",
//     width: "70%",
//     borderRadius: "30px",
//     cursor: "pointer",
//     fontWeight: "bold",
//     fontFamily: "Verdana",
//   },
//   resetpasswordbutton: {
//     backgroundColor: "#005A9C",
//     color: "white",
//     padding: "10px",
//     fontSize: "14px",
//     width: "70%",
//     borderRadius: "30px",
//     cursor: "pointer",
//     fontWeight: "bold",
//     fontFamily: "Verdana",
//   },
// };

// export default Office;
