import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import "bootstrap/dist/css/bootstrap.min.css";

export class CancelSubscription extends Component {

  onSubmit = (e) => {
    e.preventDefault();
    this.props.cancel();
  }

  render() {

    let onSubmit = this.onSubmit.bind(this);

    return (

      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Cancel Subscription
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container'>
            <form style={{ display: 'flex' }}>
              <p><b>Are you Sure you want to cancel your subscription?</b><br/>
              - You will no longer be charged monthly after cancellation.<br/>
              - Your account will be downgraded at the end of the month.</p>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onSubmit}>Cancel Subscription</Button>
          <Button variant="success" onClick={this.props.onHide}>Stay Subscribed</Button>
        </Modal.Footer>
      </Modal>
    );

  }
}

export default CancelSubscription





// import React, { Component } from 'react'
// import { Modal, Button } from 'react-bootstrap';
// import axios from 'axios';
// import "bootstrap/dist/css/bootstrap.min.css";

// export class DelGuestModal extends Component {

//   onSubmit = () => {
//     // e.preventDefault();
//     this.props.onClick();
//   }

//   onSubmit2 = () => {
//     // e.preventDefault();
//     this.props.onClick2();
//   }

//   render() {

//     // let onSubmit = this.onSubmit.bind(this);

//     return (

//       <Modal
//         {...this.props}
//         size="lg"
//         aria-labelledby="contained-modal-title-vcenter"
//         centered
//       >
//         <Modal.Header closeButton>
//           <Modal.Title id="contained-modal-title-vcenter">
//             Cancel Monthly Subscription
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <div className='container'>
//             <form style={{ display: 'flex' }}>
//               <p><b>Are you Sure you want to cancel your subscription?</b><br/>
//               - You will no longer be charged monthly after cancellation.<br/>
//               - Your account will be downgraded at the end of the month.</p>
//             </form>
//           </div>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="danger" onClick={()=>this.onSubmit()}>Cancel Subscription</Button>
//           <Button variant="success" onClick={()=>this.onSubmit2()}>Stay Subscribed</Button>
//         </Modal.Footer>
//       </Modal>
//     );

//   }
// }

// export default DelGuestModal
