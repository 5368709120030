import React, { Component } from 'react'
import QRCode from "react-qr-code";
export default class GuestTicket extends Component {
  render() {
    return (
    <div>
        <div style={{paddingLeft: 100, paddingBottom:10,paddingTop: 10}}>
        <h4 style={{fontFamily: "Georgia, Helvetica"}}><b>Guest Ticket Details</b></h4>
        </div>

        <div style={{paddingLeft: 10, marginBottom:10, fontFamily: "Georgia, Helvetica"}}>
        <b>Named:</b> {this.props.guest.list}
        </div>

        <div style={{paddingLeft: 10, marginBottom:10, fontFamily: "Georgia, Helvetica"}}>
        <b>Organization:</b> {this.props.guest.organization}
        </div>
        <div style={{paddingLeft: 10, marginBottom:10, fontFamily: "Georgia, Helvetica"}}>
        <b>Table #:</b> {this.props.guest.table_row_number}
        </div>
        <div style={{paddingLeft: 10, marginBottom:10, fontFamily: "Georgia, Helvetica"}}>
        <b>Seat #:</b> {this.props.guest.seat_number}
        </div>
        <div style={{paddingLeft: 10, marginBottom:10, fontFamily: "Georgia, Helvetica"}}>
        <b>Ticket Type:</b> {this.props.guest.tickettype}
        </div>
        <div style={{paddingLeft: 10, marginBottom:10, fontFamily: "Georgia, Helvetica"}}>
        <b>Additional Details:</b> {this.props.guest.guest_details}
        </div>
        <div style={{paddingLeft: 10, marginBottom:10, fontFamily: "Georgia, Helvetica"}}>
        <b>Confirmation Number:</b> {this.props.guest.id}
        </div>
        <hr></hr>
        <div style={{paddingLeft: 130, marginBottom:10, paddingBottom: 20, fontFamily: "Georgia, Helvetica"}}>
        <QRCode size={130} value={this.props.guest.id} />
        </div>
    </div>
    )
  }
}
