import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { isEmpty } from "../../utils/Validation";
import DashboardList from "./DashboardLists/DashboardLists";
import Fragment from "../../utils/Fragment";
import "./Dashboard.css";
import * as action from "../../store/actions/index";
import Button from "react-bootstrap/Button";
// import Alert from "react-bootstrap/Alert";
import "./Invite.css"
import axios from "axios"
import { Link } from "react-router-dom";
import moment from "moment";
class Invite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventName: "",
      addModalShow: false,
      activeItemName: "22", //state property to hold item name
      activeItemId: 333, //state property to hold item id
      addModalShow2: false,
      loading: false
    };
  }

  componentDidMount() {
    this.props.dashboards.switchEvent = false;
    console.log("Dashboard Did mount");
    this.setState({ loading: true });
    axios
      .get("/api/dashboard/")
      .then((res) => {
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log(err.response);
      });
    console.log("Dashboard Did mount");
    console.log(this.props.state);
    console.log(this.props.dashboards.dashboard);
    console.log(JSON.stringify(this.props.dashboards.dashboard));
    console.log(this.props);

    if (this.props.dashboards.eventName === "") {
      // this.props.dashboards.switchEvent = false
      // this.state.switchEvent = false
    } else {
      // this.props.dashboards.switchEvent = true
      // this.state.switchEvent = true
    }
    console.log("SWITCH TEST");
    console.log(this.props.dashboards);

    // const MINUTE_MS = 60000;
    // const interval = setInterval(() => {
    //   this.props.fetchDashboard();
    //   this.props.clearDashmessage();
    // }, MINUTE_MS);

    // return () => clearInterval(interval);
  }

  showEvent = (itemid) => {
    this.props.fetchDashboard();
    this.setState({ eventName: itemid });
    this.props.dashboards.eventName = itemid;
    this.props.dashboards.switchEvent = true;
    // this.state.switchEvent = true
  };

  noshowEvent = (itemid) => {
    this.props.fetchDashboard();
    this.props.dashboards.switchEvent = false;
  };

  onClickTest(ItemId) {
    this.props.fetchDashboard();
    console.log("test onclick");
    console.log("ItemId");
    console.log(ItemId);
    this.setState({
      activeItemId: ItemId,
    });
    this.setState({
      activeItemName: ItemId,
    });
    this.setState({
      addModalShow: true,
    });
  }

  sendInvites() {
  axios
  .post("/api/users/qremail", this.props.dashboards.eventName)
  .then((res) => {})
  .catch((err) => {
    console.log(err.response);
  });
  }
  //  onClick =(id) => {
  //   this.props.deleteDashboard(id)
  //   this.setState({addModalShow2: false})
  //   // this.props.switchEventFalsy(id)
  //   // this.props.dashboards.switchEvent = false
  // }

  render() {
    let addModalClose = () => this.setState({ addModalShow: false });
    let addModalClose2 = () => this.setState({ addModalShow2: false });

    let authRedirect = null;
    if (!this.props.isAuthenticated) {
      authRedirect = <Redirect to="/" />;
    }

    let dashboard = this.props.dashboards;
    let dashboardItems = null;
    if (isEmpty(dashboard.dashboard)) {
      dashboardItems = (
        <Fragment>
          <div className="col-sm-6 offset-sm-3">
            <div className="card Empty">
              <h5 className="card-header">
                {dashboard.mesg ? dashboard.mesg.message : ""}
              </h5>
              <div></div>
            </div>
          </div>
          <div className="col-sm-3"></div>
        </Fragment>
      );
    } else {
      dashboardItems = dashboard.dashboard
        .filter((book) => book._id === this.props.dashboards.eventName)
        .map((dashItem, i) => {
          return <DashboardList noshowEvent={this.noshowEvent} key={dashItem._id} dashItem={dashItem} />;
        });
    }

    let events = (
      <div className="noevents" style={{ textAlign: "center", padding: 30 }}>
        <h2 style={{ marginLeft: 20 }} className="noevents">
          No Events Created or Accesible...
        </h2>
      </div>
    );

    if (!isEmpty(this.props.dashboards.dashboard)) {
      events = <h2 className="display-4 None">Not Empty.</h2>;

      events = this.props.dashboards.dashboard.map((item) => (
        <div style={{ margin: 10, paddingLeft: 10  }}>
          <Button
       id="eventlist"
       variant="light"
       className="eventlist"
       style={{
         boxShadow: "0px 3px 10px black",
         opacity: 0.8,
         paddingRight: 10,
         paddingLeft: 15,
         paddingBottom: 10,
         border: "1px solid black",
         borderRadius: 10,
         minWidth: 300
            }}
            onClick={() => this.showEvent(item._id)}
          >
            {/* <div style={{ }} onClick={(e) => {
            e.stopPropagation(); 
            this.props.dashboards.eventid = item._id
           }}>
            <i className="delete_event fas fa-remove fa-xl" onClick={() => this.setState({ addModalShow2: true })}></i>
            </div> */}

              <div style={{ fontSize: 13 }}>
              <b>Event Name::</b> {item.name}
            </div>
            <div style={{ fontSize: 13 }}>
              <b>Location:</b> {item.location}
            </div>

            {/* <div onClick={(e) => {
             e.stopPropagation(); 
             this.onClickTest(item._id)
            }}>
            <i style={{paddingTop: 12}} className="edit_event fas fa-pen fa-xl" onClick={() => this.setState({ addModalShow: true })}></i>
            </div> */}

           {item.startdate == null ? (
              <div style={{ fontSize: 13 }}>
                <b>Start Date:</b>
              </div>
            ) : (
              <div style={{ fontSize: 13 }}>
                <b>Start Date:</b>{" "}
                {moment(item.startdate).utc().format("MM/D/YY")}
              </div>
            )}
            {item.enddate == null ? (
              <div style={{ fontSize: 13 }}>
                <b>End Date:</b>
              </div>
            ) : (
              <div style={{ fontSize: 13 }}>
                <b>End Date:</b> {moment(item.enddate).utc().format("MM/D/YY")}
              </div>
            )}
            {item.starttime == "" ? (
              <div style={{ fontSize: 13 }}>
                <b>Event Time:</b>{" "}
              </div>
            ) : (
              <div style={{ fontSize: 13 }}>
                <b>Event Time:</b>{" "}
                {moment(item.starttime, "HH:mm").format("hh:mm A")}
              </div>
            )}

            <div style={{ fontSize: 13 }}>
              <b>Timezone:</b> {item.timezone.slice(0, 33) + "..."}
            </div> 
          </Button>
        </div>
      ));
    }


    let eventtickets = (
      <div className="noevents" style={{ textAlign: "center", padding: 30 }}>
        <h2 style={{ marginLeft: 20 }} className="noevents">
          No Events Created or Accesible...
        </h2>
      </div>
    );

    if (!isEmpty(this.props.dashboards.dashboard)) {
      eventtickets = <h2 className="display-4 None">Not Empty.</h2>;

    eventtickets = this.props.dashboards.dashboard.map((item) => (
      <div style={{ margin: 10, paddingLeft: 10  }}>
           <Link to={`/printtickets/${item._id}`}>
        <Button
     id="eventlist"
     variant="light"
     className="eventlist"
     style={{
       boxShadow: "0px 3px 10px black",
       opacity: 0.8,
       paddingRight: 10,
       paddingLeft: 15,
       paddingBottom: 10,
       border: "1px solid black",
       borderRadius: 10,
       minWidth: 300
          }}
          onClick={() => this.showEvent(item._id)}
        >
            <div style={{ fontSize: 13 }}>
            <b>Event Name::</b> {item.name}
          </div>
          <div style={{ fontSize: 13 }}>
            <b>Location:</b> {item.location}
          </div>

         {item.startdate == null ? (
            <div style={{ fontSize: 13 }}>
              <b>Start Date:</b>
            </div>
          ) : (
            <div style={{ fontSize: 13 }}>
              <b>Start Date:</b>{" "}
              {moment(item.startdate).utc().format("MM/D/YY")}
            </div>
          )}
          {item.enddate == null ? (
            <div style={{ fontSize: 13 }}>
              <b>End Date:</b>
            </div>
          ) : (
            <div style={{ fontSize: 13 }}>
              <b>End Date:</b> {moment(item.enddate).utc().format("MM/D/YY")}
            </div>
          )}
          {item.starttime == "" ? (
            <div style={{ fontSize: 13 }}>
              <b>Event Time:</b>{" "}
            </div>
          ) : (
            <div style={{ fontSize: 13 }}>
              <b>Event Time:</b>{" "}
              {moment(item.starttime, "HH:mm").format("hh:mm A")}
            </div>
          )}

          <div style={{ fontSize: 13 }}>
            <b>Timezone:</b> {item.timezone.slice(0, 33) + "..."}
          </div> 
        </Button>
        </Link>
      </div>
    ));
  }

    return (
      <div>
            {authRedirect}
        {this.props.user.toptier_subscription ?
          
        <div>
          {/* {this.props.dashboards.dashMessage !== "" ? (
            <Alert variant="danger">
              <div style={{ textAlign: "center" }}>
                {this.props.dashboards.dashMessage}
              </div>
            </Alert>
          ) : null} */}

          {!this.props.dashboards.switchEvent ? (
            <div>
              {this.props.user.role === "user" || "admin" ? (
                <div>
                  <h1
                    className="eventlist_titles"
                    style={{
                      textAlign: "center",
                      marginTop: 35,
                      marginBottom: 15,
                    }}
                  >
                    <b style={{fontFamily:"Verdana, Geneva, Helvetica"}}>Send Event Invitations</b>
                  </h1>
                </div>
              ) : 
              
 null
              }
              <hr className="solid"></hr>
              {/* <div style={{ textAlign: "center" }}>
                <h1 className="eventlist_titles">
                  <b>Choose an Event</b>
                </h1>
                <br />
              </div> */}
              <div className="">
                <div
                  style={{ paddingLeft: 40, paddingTop: 20, paddingBottom: 20 }}
                >
                  <h3 >
                   <b>Email</b> Event invitations with QR codes to your list of guests.
                  </h3>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  marginLeft: "20px"
                }}
              >
                {events} </div>

                <div style={{marginTop: "20px"}}>
                <h3 style={{marginLeft: "40px"}}>
                  <hr></hr>
                <b>Download or Print</b> Event invitations with QR codes to your list of guests.
                </h3>
                <div
                style={{
                  marginLeft: "20px",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {eventtickets}
                </div>
                </div>
            </div>
          ) : (
            <div>{dashboardItems}</div>
          )}
        </div>

        :

        <div style={{ marginTop: "6%", marginBottom: "5%", marginLeft: "5%"}}>
        <h3 className="profiletitlesub">
          <h4>You are Not currently subscribed to the <b>Professional Plan</b> to use the Invitation feature.</h4>
        </h3>

        <Link className="nav-link" to="/subscription">
          <button
            className="subscribebutton"
            style={{
              marginTop: 15,
            }}
          >
            Subscribe Now
          </button>
        </Link>

      </div>
      }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    dashboards: state.dashboard,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
};

const mapFetchToProps = (dispatch) => {
  return {
    fetchDashboard: () => {
      dispatch(action.fetchDashboard());
    },
    clearDashmessage: () => {
      dispatch(action.clearDashmessage());
    },
    deleteDashboard: (id) => {
      dispatch(action.deleteDashboard(id));
    },
  };
};

export default connect(mapStateToProps, mapFetchToProps)(Invite);
