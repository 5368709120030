import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Fragment from '../../../utils/Fragment'
// import {validation} from '../../../utils/Validation'
import * as actions from '../../../store/actions/index'
import './Signup.css'
import vid from '../../Assets/abstractgray.mp4'
import {useHistory, useParams} from 'react-router-dom'

    
const Signup = (props) => {
  let userdetails = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory()

  const [validname, setValidName] = useState(false)
  const [validemail, setValidEmail] = useState(false)
  const [validpassword, setValidpassword] = useState(false)
  
  const [agreeterms, setAgreeterms] = useState(false)
  
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });

  useEffect(() => {
    console.log("history")
    console.log(history)

    // Lets run a method to remove the alert authMessage: {}
    // Such as username already exists from login page.
    dispatch(actions.auth_message_clear(history));
    // return () => {
    //   second
    // }
  }, [])
  
  const onChange = (e) => {
    const { name, value } = e.target;
        
    setFormData({
        ...formData,
        [name]: value,
      });

      var hasNumber = /\d/;   

     if(formData.password.length > 4 && hasNumber.test(formData.password)){
      setValidpassword(false)
     }

     if(formData.first_name.length && formData.last_name.length> 3){
      setValidName(false)
    }

    if(formData.email.includes("@")){
      setValidEmail(false)
    }

    console.log(e.target.value)
    console.log(e.target.name)

    if(e.target.value.includes("@") && e.target.name === "email"){
      setValidEmail(false)
    }

  }
  const onBlurName = () => {
    if(formData.first_name.length && formData.last_name.length < 5){
      setValidName(true)
    }

  }

  const onBlurEmail = () => {
    if(!formData.email.includes("@")){
      setValidEmail(true)
    }
    if(formData.email.includes("@")){
      setValidEmail(false)
    }
  }

  const onBlurPassword = () => {
    var hasNumber = /\d/;   
    if(formData.password.length < 5){
      setValidpassword(true)
      console.log("true no less 4")
    }
    else{
      console.log("false no less 4")
    }

    if(!hasNumber.test(formData.password)){
      setValidpassword(true)
      console.log("true no number")
    }
    else{
      console.log("false yes number")
    }

    if(formData.password.length > 5 && hasNumber.test(formData.password)){
      console.log(true)
      setValidpassword(false)
     }
  }

  let signup_button_disabled = true
  // Do passwords not match?
  if(formData.confirmpassword !== formData.password
    && formData.confirmpassword != null
    && agreeterms != false
    ){
    signup_button_disabled = true
  }
  else if (formData.confirmpassword === formData.password
    && formData.confirmpassword != null
    && agreeterms != false) {
    signup_button_disabled = false
  }

  const onSubmit = (e) => {
    e.preventDefault(); 
    dispatch(actions.onloading());
    
    const newUser = {
      email: formData.email.toLowerCase(),
      password: formData.password,
      confirmpassword: formData.confirmpassword,
      first_name: formData.first_name,
      last_name: formData.last_name,
      associatedemail: formData.email.toLowerCase(),
      phone: formData.phone,
      address: formData.address,
      city: formData.city,
      state: formData.state,
      zip: formData.zip,
      country: formData.country,
      register_source: "Website"
    }
    dispatch(actions.registerUser(newUser));

    setTimeout(() => {
      console.log("Delayed for 1 second.");
      const loginUser = {
      email: formData.email.toLowerCase(),
      password: formData.password,
    }

    dispatch(actions.loginUser(loginUser));

  }, 500);
  }

    const countries = [
      "",
      "United States of America",
      "Canada",
      "Afghanistan", 
      "Albania", 
      "Algeria", 
      "Andorra", 
      "Angola", 
      "Antigua and Barbuda", 
      "Argentina", 
      "Armenia", 
      "Australia", 
      "Austria", 
      "Azerbaijan", 
      "Bahamas", 
      "Bahrain", 
      "Bangladesh", 
      "Barbados", 
      "Belarus", 
      "Belgium", 
      "Belize", 
      "Benin", 
      "Bhutan", 
      "Bolivia", 
      "Bosnia and Herzegovina", 
      "Botswana", 
      "Brazil", 
      "Brunei", 
      "Bulgaria", 
      "Burkina Faso", 
      "Burundi", 
      "Côte d'Ivoire", 
      "Cabo Verde", 
      "Cambodia", 
      "Cameroon", 
      "Central African Republic", 
      "Chad", 
      "Chile", 
      "China", 
      "Colombia", 
      "Comoros", 
      "Congo (Congo-Brazzaville)", 
      "Costa Rica", 
      "Croatia", 
      "Cuba", 
      "Cyprus", 
      "Czechia (Czech Republic)", 
      "Democratic Republic of the Congo", 
      "Denmark", 
      "Djibouti", 
      "Dominica", 
      "Dominican Republic", 
      "Ecuador", 
      "Egypt", 
      "El Salvador", 
      "Equatorial Guinea", 
      "Eritrea", 
      "Estonia", 
      "Eswatini/Swaziland", 
      "Ethiopia", 
      "Fiji", 
      "Finland", 
      "France", 
      "Gabon", 
      "Gambia", 
      "Georgia", 
      "Germany", 
      "Ghana", 
      "Greece", 
      "Grenada", 
      "Guatemala", 
      "Guinea", 
      "Guinea-Bissau", 
      "Guyana", 
      "Haiti", 
      "Holy See", 
      "Honduras", 
      "Hungary", 
      "Iceland", 
      "India", 
      "Indonesia", 
      "Iran", 
      "Iraq", 
      "Ireland", 
      "Israel", 
      "Italy", 
      "Jamaica", 
      "Japan", 
      "Jordan", 
      "Kazakhstan", 
      "Kenya", 
      "Kiribati", 
      "Kuwait", 
      "Kyrgyzstan", 
      "Laos", 
      "Latvia", 
      "Lebanon", 
      "Lesotho", 
      "Liberia", 
      "Libya", 
      "Liechtenstein", 
      "Lithuania", 
      "Luxembourg", 
      "Madagascar", 
      "Malawi", 
      "Malaysia", 
      "Maldives", 
      "Mali", 
      "Malta", 
      "Marshall Islands", 
      "Mauritania", 
      "Mauritius", 
      "Mexico", 
      "Micronesia", 
      "Moldova", 
      "Monaco", 
      "Mongolia", 
      "Montenegro", 
      "Morocco", 
      "Mozambique", 
      "Myanmar (formerly Burma)", 
      "Namibia", 
      "Nauru", 
      "Nepal", 
      "Netherlands", 
      "New Zealand", 
      "Nicaragua", 
      "Niger", 
      "Nigeria", 
      "North Korea", 
      "North Macedonia", 
      "Norway", 
      "Oman", 
      "Pakistan", 
      "Palau", 
      "Palestine State", 
      "Panama", 
      "Papua New Guinea", 
      "Paraguay", 
      "Peru", 
      "Philippines", 
      "Poland", 
      "Portugal", 
      "Qatar", 
      "Romania", 
      "Russia", 
      "Rwanda", 
      "Saint Kitts and Nevis", 
      "Saint Lucia", 
      "Saint Vincent and the Grenadines", 
      "Samoa", 
      "San Marino", 
      "Sao Tome and Principe", 
      "Saudi Arabia", 
      "Senegal", 
      "Serbia", 
      "Seychelles", 
      "Sierra Leone", 
      "Singapore", 
      "Slovakia", 
      "Slovenia", 
      "Solomon Islands", 
      "Somalia", 
      "South Africa", 
      "South Korea", 
      "South Sudan", 
      "Spain", 
      "Sri Lanka", 
      "Sudan", 
      "Suriname", 
      "Sweden", 
      "Switzerland", 
      "Syria", 
      "Tajikistan", 
      "Tanzania", 
      "Thailand", 
      "Timor-Leste", 
      "Togo", 
      "Tonga", 
      "Trinidad and Tobago", 
      "Tunisia", 
      "Turkey", 
      "Turkmenistan", 
      "Tuvalu", 
      "Uganda", 
      "Ukraine", 
      "United Arab Emirates", 
      "United Kingdom", 
      "United States of America", 
      "Uruguay", 
      "Uzbekistan", 
      "Vanuatu", 
      "Venezuela", 
      "Vietnam", 
      "Yemen", 
      "Zambia", 
      "Zimbabwe", ]
  

        let authRedirect = null
        if(userdetails.auth.isAuthenticated){
          history.push('/dashboard')
        }
          // let btnDisable = []
          // for(let i in this.state){
          //   btnDisable.push(this.state[i].valid)
          // }

let signup = <div >
        <div dangerouslySetInnerHTML={{ __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          src="${vid}"
          class="VideostyleBanner2"
        />,
      ` }}></div>
      <div  id="signupbox" className='signupbox agree_terms'
      style={{opacity: "80%"}}>
              <div className="Signup">
                <form onSubmit={(e)=>onSubmit(e)}>
                  <div style={{color: "#505050"}} className="form-group">
                    <h5 className="signuptitle">Sign Up
                      <small className="form-text text-muted">
                        {userdetails.auth.authMessage.mesg ? userdetails.auth.authMessage.mesg : ''}
                      </small>
                    </h5>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{width: "48%"}}  className="form-group">
                    <input 
                      type="first_name" 
                      name="first_name"
                      className="form-control" 
                      placeholder="First Name"
                      // value={formData.name.value}
                      onBlur={onBlurName}
                      onChange={(e)=>onChange(e, 'first_name')} />
                    <small style={{color: "darkred"}} className="">
                      {validname ? 'Name must be atleast 5 characters.' : null}
                      </small>
                  </div>
                  <div style={{width: "48%"}} className="form-group">
                    <input 
                      type="last_name" 
                      name="last_name"
                      className="form-control" 
                      placeholder="Last Name"
                      // value={formData.name.value}
                      onBlur={onBlurName}
                      onChange={(e)=>onChange(e, 'last_name')} />
                    <small style={{color: "darkred"}} className="">
                      {validname ? 'Name must be atleast 5 characters.' : null}
                      </small>
                  </div>
                  </div>
                    <input 
                      type="email" 
                      name="email"
                      className="form-control" 
                      placeholder="Email"
                      // value={formData.email.value}
                      onBlur={onBlurEmail}
                      onChange={(e)=>onChange(e, 'email')} />
                    <small style={{color: "darkred"}} className="">
                      {validemail ? 'Please enter a valid email' : null}
                    </small>
                  </div>
                  <div className="form-group">
                    <input 
                      type="password" 
                      name="password"
                      className="form-control" 
                      placeholder="Password"
                      // value={formData.password.value}
                      onChange={(e)=>onChange(e, 'password')} 
                      onBlur={onBlurPassword}
                      />
                    <small style={{color: "darkred"}} className="">
                      {validpassword ? 'Password must be atleast 6 characters including atleast one number.' : null}
                      </small>
                  </div>

                  <div className="form-group">
                    <input 
                      type="password" 
                      name="confirmpassword"
                      className="form-control" 
                      placeholder="Confirm Password"
                      // value={formData.confirmpassword.value}
                      onChange={(e)=>onChange(e, 'confirmpassword')} />
                    <small style={{color: "darkred"}} className="">
                      {formData.confirmpassword !== formData.password && formData.confirmpassword != null ? 'Passwords must match!' : null}
                      </small>
                  </div>

                  <div className="form-group">
                    <input 
                      type="phone" 
                      name="phone"
                      className="form-control" 
                      placeholder="Phone"
                      // value={formData.phone.value}
                      onChange={(e)=>onChange(e, 'phone')} />
                  </div>
                  <div className="form-group">
                    <input 
                      type="address" 
                      name="address"
                      className="form-control" 
                      placeholder="Address"
                      // value={formData.address.value}
                      onChange={(e)=>onChange(e, 'address')} />
                  </div>
                  <div style={{display: "flex", justifyContent: "space-between"}}>
                  <div style={{width: "48%"}} className="form-group">
                    <input
                      type="city" 
                      name="city"
                      className="form-control" 
                      placeholder="City"
                      // value={formData.city.value}
                      onChange={(e)=>onChange(e, 'city')} />
                  </div>
                  <div style={{width: "48%"}}  className="form-group">
                    <input 
                      type="state" 
                      name="state"
                      className="form-control" 
                      placeholder="State"
                      // value={formData.state.value}
                      onChange={(e)=>onChange(e, 'state')} />
                  </div>
                  </div>
                  <div className="form-group">
                    <input 
                      type="zip" 
                      name="zip"
                      className="form-control" 
                      placeholder="Zip Code"
                      // value={formData.zip.value}
                      onChange={(e)=>onChange(e, 'zip')} />
                  </div>
                  <div className="form-group">
            <select
            type="country" 
            name="country"
            className="form-control" 
           placeholder="Country"
           style={{color: "#707070", border: "1px solid #909090"}}
           defaultValue={""}
          //  value={formData.country.value}
           onChange={(e)=>onChange(e, 'country')}
            >
              <option value="" default selected>Select Country..</option>
            {countries.map((option) => (
              <option  style={{color: "black"}} value={option}>{option}</option>
            ))}
          </select>
                  </div>

     <div className="form-group">
  <div style={{marginLeft: 2}} class="form-check">
    <label class="form-check-label">
      <input type="checkbox"
      onClick={()=>setAgreeterms(!agreeterms)}
      name="termscheckbox"
      className="form-check-input"
       style={{marginTop:-5}}/>
      <b className="agree_terms" style={{marginLeft: 5}}>I agree to the Terms and Conditions and Privacy Policy</b>
    </label>
  </div>
</div>
                  <div className="col text-center">
                    <button 
                      disabled={signup_button_disabled}
                      type="submit" 
                      id = "signupbutton"
                      className="btn"><b>Submit</b></button>
                  </div>
                </form>
              </div>
              </div>
              <div className="col-sm-4"></div>
            </div>    

    return (
      <Fragment>
        {authRedirect}
        {signup}
      </Fragment>
    )
}


export default Signup