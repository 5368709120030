import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as action from "../../../store/actions/index";
import Fragment from "../../../utils/Fragment";
import EditGuestModal from "./EditGuestModal";
import DeleteListModal from "./DeleteListModal";
import "./List.css";

const List = (props) => {
  const [editSwitch, setEditSwitch] = useState(false);
  const [listItem, setListItem] = useState("");
  const [editGuestModal, setEditGuestModal] = useState(false);
  const [deleteGuestModal, setDeleteGuestModal] = useState(false);
  const [currentSortName, setCurrentSortName] = useState("lista");

  const dispatch = useDispatch();
  const reduxState = useSelector((state) => state);

  useEffect(() => {
    setListItem(props.list);
  }, []);

  const dashBoardSwitch = () => {
    setEditSwitch(!editSwitch);
  };

  const deleteGuest = (dashId, id) => {
    dispatch(action.deleteList(dashId, id, currentSortName));
  };

  const editDashBoard = (
    dashId,
    id,
    text,
    email,
    phone,
    tickettype,
    guest_details,
    organization,
    table_row_number,
    seat_number
  ) => {
    dispatch(
      action.editList(
        dashId,
        id,
        text,
        email,
        phone,
        tickettype,
        guest_details,
        organization,
        table_row_number,
        seat_number,
        currentSortName
      )
    );
  };

  const onChange = (e) => {
    setListItem(e.target.value);
  };

  const submit = (dashId, id) => {
    dispatch(action.editList(dashId, id, listItem));
    dashBoardSwitch();
  };

  const changeStatus = (dashId, id) => {
    dispatch(action.updateListStatus(dashId, id));
  };

  let addModalClose = () => setEditGuestModal(false);
  let addModalClose2 = () => setDeleteGuestModal(false);

  let listItems = [
    "list-group-item list-group-item-action flex-column align-items-start",
  ];
  if (props.status === "done") {
    listItems.push(" complete ");
  }

  let classItems = ["mb-1 "];
  if (props.status === "done") {
    classItems.push(" done");
  }

  let list = (
    <li
      style={{
        background: props.status === "Checked In" ? "#B9EBDB" : "white",
      }}
      id={listItems}
      className={listItems}
      key={classItems.join(" ")}
    >
      <p
        className={classItems}
        onClick={() => changeStatus(props.dashId, props.listId)}
        // onClick={this.dashBoardSwitch}
      >
        <b>Name:</b> {props.list}
      </p>

      <p
        className={classItems}
        onClick={() => changeStatus(props.dashId, props.listId)}
        // onClick={this.dashBoardSwitch}
      >
         <b>Organization:</b> {props.organization}
      </p>

      <p
        className={classItems}
        onClick={() => changeStatus(props.dashId, props.listId)}
        // onClick={this.dashBoardSwitch}
      >
        <b>Email:</b> {props.email}
      </p>

      <p
        className={classItems}
        onClick={() => changeStatus(props.dashId, props.listId)}
        // onClick={this.dashBoardSwitch}
      >
        <b>Phone:</b> {props.phone}
      </p>

      <p
        className={classItems}
        onClick={() => changeStatus(props.dashId, props.listId)}
        // onClick={this.dashBoardSwitch}
      >
        <b>Ticket Type:</b> {props.tickettype}
      </p>
      <p
        className={classItems}
        onClick={() => changeStatus(props.dashId, props.listId)}
        // onClick={this.dashBoardSwitch}
      >
        <b>Additional details:</b> {props.guest_details}
      </p>

      <p
        className={classItems}
        onClick={() => changeStatus(props.dashId, props.listId)}
        // onClick={this.dashBoardSwitch}
      >
        <b>Table/Row #:</b> {props.table_row_number}
        <b style={{marginLeft: 20}}>Seat #:</b> {props.seat_number}
      </p>


      <div className="d-flex justify-content-start Icons">
        <small>
          <b>Created:</b> {moment(props.listDate).format("MM/D/YY, h:mm A")}
        </small>
        {/* <i 
              className="fas fa-trash-alt fa-xs"
              onClick={()=>this.deleteGuest(props.dashId, props.listId)}></i>
             */}

      {reduxState.auth.user.role === "Owner Original" || reduxState.auth.user.role === "Owner" || reduxState.auth.user.role === "Admin" || reduxState.auth.user.role === "Editor" ? (
          <i
            className="fas fa-pen fa-xl"
            style={{ color: "#6699FF" }}
            onClick={() => setEditGuestModal(true)}
          ></i>
        ) : null}
        <EditGuestModal
          show={editGuestModal}
          onHide={addModalClose}
          dashId={props.dashId}
          id={props.listId}
          email={"Sddsdds"}
          phone={props.phone}
          tickettype={props.tickettype}
          guest_details={props.guest_details}
          organization={props.organization}
          table_row_number={props.table_row_number}
          seat_number = {props.seat_number}
          editDashBoard={editDashBoard}
          list={props.list}
        />

        {/* // if (!isEmpty(props.lists)) {
    //   // myData = filteredTasks
    //   lists = filteredTasks
    //     .sort((a, b) => a.list.localeCompare(b.list))
    //     .map((list) => {
    //       return (
    //         <List
    //           dashId={props.dashId}
    //           key={list._id}
    //           status={list.status}
    //           list={list.list}
    //           email={list.email}
    //           phone={list.phone}
    //           tickettype={list.tickettype}
    //           organization={list.organization}
    //           table_row_number={list.table_row_number}
    //           seat_number={list.seat_number}
    //           listId={list._id}
    //           listDate={list.date}
    //         />
    //       );
    //     });
    // } */}

{reduxState.auth.user.role === "Owner Original" || reduxState.auth.user.role === "Owner" || reduxState.auth.user.role === "Admin" ? (
          <i
            onClick={() => setDeleteGuestModal(true)}
            className="fas fa-trash-alt fa-md"
            style={{ color: "#C00000" }}
            aria-label="Close"
          ></i>
        ) : null}
        <DeleteListModal
          deleteGuest={deleteGuest}
          show={deleteGuestModal}
          dashId={props.dashId}
          id={props.listId}
          onHide={addModalClose2}
        />
      </div>
    </li>
  );

  //Delete Code Below
  if (editSwitch) {
    list = (
      <div className="input-group DashInput">
        <input
          type="text"
          className="form-control"
          value={listItem}
          onChange={(e) => onChange(e)}
        />
        <div className="input-group-append">
          <button
            className="btn btn-outline-secondary"
            type="button"
            aria-haspopup="true"
            onClick={() => submit(props.dashId, props.listId)}
          >
            <i className="fas fa-plus fa-xs"></i>
          </button>
        </div>
      </div>
    );
  }

  return <Fragment>{list}</Fragment>;
};

export default List;
