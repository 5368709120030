import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import * as action from "../../store/actions/index";
// import { isEmpty } from "../../utils/Validation";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions/index";
import { Redirect } from "react-router-dom";

const EditEventModal = (props) => {

  const [formData, setFormData] = useState({
    eventname: "",
    location: "",
    startdate: "",
    enddate: "",
    starttime: "",
    endtime: "",
    timezone: "",
    chapter_event: "",
    additionaldetails: "",
    chapter_dashboard: []
  });

  const userdetails = useSelector((state) => state);
  const dispatch = useDispatch();
  const [redirectChapter, setRedirectChapter] = useState(false)

useEffect(() => {

  try{
  setFormData({
    eventname: props.editedEvent[0].name,
    location: props.editedEvent[0].location,
    startdate: props.editedEvent[0].startdate,
    enddate: props.editedEvent[0].enddate,
    starttime: props.editedEvent[0].starttime,
    endtime: props.editedEvent[0].endtime,
    timezone: props.editedEvent[0].timezone,
    chapter_event: props.editedEvent[0].chapter_dashboard,
    additionaldetails: props.editedEvent[0].additionaldetails
  });
}
catch{
  console.log("setting error")
}
  
}, [props.editedEvent])




const eventchapter = [{ value: "All Users", label: "All Users" }];

if(userdetails.auth.user.chapter !== undefined || null || ""){
let chapters_created = userdetails.auth.user.chapter.map(
  (arr) => arr.chapter_names
);

try {
  for(let i = 0; i < chapters_created.length; i++) {
    eventchapter.push({
      value: chapters_created[i],
      label: chapters_created[i],
    });
  }
  eventchapter.push({
    value: "-Create New Chapter-",
    label: "-Create New Chapter-",
  });
} catch {
  console.log("error");
}
}


  //Single Event Handler Approach:
  const onChange = (e) => {

      console.log("props.editedEvent")
      console.log(props.editedEvent[0].name)

    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

  };

  let onChangeEditTimezone = (e) => {
    console.log("e.value");
    console.log(e.value);
    let timezone = e.value;
    const firstLetter = timezone.charAt(0);
    const firstLetterCap = firstLetter.toUpperCase();
    const remainingLetters = timezone.slice(1);
    const capitalizedWord = firstLetterCap + remainingLetters;

    setFormData({
      ...formData,
      timezone: capitalizedWord,
    });
  };


  // let onChangeEditChapter = (e) => {
  //   console.log("e.value");
  //   console.log(e.value);
  //   setFormData({
  //     ...formData,
  //     chapter_event: e.value,
  //   });
  // };

  let onChangeEditChapter = (selectedOption) => {
    console.log("onChangeAddChapter");
    console.log(selectedOption);
    console.log(selectedOption.length);


    for(var i = 0; i < selectedOption.length; i++){
    if(selectedOption[i].value.includes("-Create New Chapter-")){
      setRedirectChapter(true);
    }
  }

    let reformattedchapters = [];

    try {
      for (var i = 0; i < selectedOption.length; i++) {
        reformattedchapters.push({
          chapter_dash_names: selectedOption[i].value,
        });
      }
    } catch {
      console.log("push error - reformattedchapters");
    }

    console.log("reformattedchapters");
    console.log(reformattedchapters);

    setFormData({
      ...formData,
      chapter_dashboard: reformattedchapters,
    });

    console.log("past it");
    // this.setState({ selectedOption });
  };

  let chapterpage_redirect = null;
  if (redirectChapter) {
    chapterpage_redirect = <Redirect to="/chapters" />;
  }

  let onSubmit = (e) => {
    e.preventDefault();

    try {
      // console.log("--------formData---------")
      // console.log(formData)
      dispatch(actions.editDashboard(
        props.itemId,
        formData.eventname,
        formData.location,
        formData.startdate,
        formData.enddate,
        formData.starttime,
        formData.endtime,
        formData.timezone,
        formData.additionaldetails,
        formData.chapter_dashboard
      ));

      console.log("edit frontend submit")
      console.log("edit itemId")
      console.log(props.itemId)
      console.log("edit eventname")
      console.log(formData.eventname)
      console.log("edit location")
      console.log(formData.location)
      console.log("edit startdate")
      console.log(formData.startdate)
      console.log("edit enddate")
      console.log(formData.enddate)
      console.log("edit starttime")
      console.log(formData.starttime)
      console.log("edit endtime")
      console.log(formData.endtime)
      console.log("edit timezone")
      console.log(formData.timezone)
      console.log("edit chapter_event")
      console.log(formData.chapter_dashboard)
      console.log("edit additionaldetails")
      console.log(formData.additionaldetails)

      toast.success(
        <div style={{ fontSize: "16px" }}>
          <b>Event Edited!</b>
        </div>,
        {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    } catch {
      toast.error(
        <div style={{ fontSize: "15px" }}>
          <b>An error has occured with editing the event.</b>
        </div>,
        {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
    props.onHide();
  };

    // Handling Selects
    let timezone_default = []
    
    try {
        timezone_default.push({
          value: props.editedEvent[0].timezone,
          label: props.editedEvent[0].timezone,
        });
    } catch {
      console.log("error");
    }

    const timezones = [
      "Mountain Time (US and Canada) - America/Denver (UTC-07:00)",
      "Central Time (US and Canada) - America/Chicago (UTC-06:00)",
      "Eastern Time (US and Canada) - America/New_York (UTC-05:00)",
      "International Date Line West - Etc/GMT+12 (UTC-12:00)",
      "Coordinated Universal Time-11 - Etc/GMT+11 (UTC-11:00)",
      "Hawaii - Pacific/Honolulu (UTC-10:00)",
      "Alaska - America/Anchorage (UTC-09:00)",
      "Baja California - America/Santa_Isabel (UTC-08:00)",
      "Pacific Time (US and Canada) - America/Los_Angeles (UTC-08:00)",
      "Chihuahua, La Paz, Mazatlan - America/Chihuahua (UTC-07:00)",
      "Arizona - America/Phoenix (UTC-07:00)",
      "Central America - America/Guatemala (UTC-06:00)",
      "Saskatchewan - America/Regina (UTC-06:00)",
      "Guadalajara, Mexico City, Monterey - America/Mexico_City (UTC-06:00)",
      "Bogota, Lima, Quito - America/Bogota (UTC-05:00)",
      "Indiana (East) - America/Indiana/Indianapolis (UTC-05:00)",
      "Caracas - America/Caracas (UTC-04:30)",
      "Atlantic Time (Canada) - America/Halifax (UTC-04:00)",
      "Asuncion - America/Asuncion (UTC-04:00)",
      "Georgetown, La Paz, Manaus, San Juan - America/La_Paz (UTC-04:00)",
      "Cuiaba - America/Cuiaba (UTC-04:00)",
      "Santiago - America/Santiago (UTC-04:00)",
      "Newfoundland - America/St_Johns (UTC-03:30)",
      "Brasilia - America/Sao_Paulo (UTC-03:00)",
      "Greenland - America/Godthab (UTC-03:00)",
      "Cayenne, Fortaleza - America/Cayenne (UTC-03:00)",
      "Buenos Aires - America/Argentina/Buenos_Aires (UTC-03:00)",
      "Montevideo - America/Montevideo (UTC-03:00)",
      "Coordinated Universal Time-2 - Etc/GMT+2 (UTC-02:00)",
      "Cape Verde - Atlantic/Cape_Verde (UTC-01:00)",
      "Azores - Atlantic/Azores (UTC-01:00)",
      "Casablanca - Africa/Casablanca (UTC+00:00)",
      "Monrovia, Reykjavik - Atlantic/Reykjavik (UTC+00:00)",
      "Dublin, Edinburgh, Lisbon, London - Europe/London (UTC+00:00)",
      "Coordinated Universal Time - Etc/GMT (UTC+00:00)",
      "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna - Europe/Berlin (UTC+01:00)",
      "Brussels, Copenhagen, Madrid, Paris - Europe/Paris (UTC+01:00)",
      "West Central Africa - Africa/Lagos (UTC+01:00)",
      "Belgrade, Bratislava, Budapest, Ljubljana, Prague - Europe/Budapest (UTC+01:00)",
      "Sarajevo, Skopje, Warsaw, Zagreb - Europe/Warsaw (UTC+01:00)",
      "Windhoek - Africa/Windhoek (UTC+01:00)",
      "Athens, Bucharest, Istanbul - Europe/Istanbul (UTC+02:00)",
      "Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius - Europe/Kiev (UTC+02:00)",
      "Cairo - Africa/Cairo (UTC+02:00)",
      "Damascus - Asia/Damascus (UTC+02:00)",
      "Amman - Asia/Amman (UTC+02:00)",
      "Harare, Pretoria - Africa/Johannesburg (UTC+02:00)",
      "Jerusalem - Asia/Jerusalem (UTC+02:00)",
      "Beirut - Asia/Beirut (UTC+02:00)",
      "Baghdad - Asia/Baghdad (UTC+03:00)",
      "Minsk - Europe/Minsk (UTC+03:00)",
      "Kuwait, Riyadh - Asia/Riyadh (UTC+03:00)",
      "Nairobi - Africa/Nairobi (UTC+03:00)",
      "Tehran - Asia/Tehran (UTC+03:30)",
      "Moscow, St. Petersburg, Volgograd - Europe/Moscow (UTC+04:00)",
      "Tbilisi - Asia/Tbilisi (UTC+04:00)",
      "Yerevan - Asia/Yerevan (UTC+04:00)",
      "Abu Dhabi, Muscat - Asia/Dubai (UTC+04:00)",
      "Baku - Asia/Baku (UTC+04:00)",
      "Port Louis - Indian/Mauritius (UTC+04:00)",
      "Kabul - Asia/Kabul (UTC+04:30)",
      "Tashkent - Asia/Tashkent (UTC+05:00)",
      "Islamabad, Karachi - Asia/Karachi (UTC+05:00)",
      "Sri Jayewardenepura Kotte - Asia/Colombo (UTC+05:30)",
      "Chennai, Kolkata, Mumbai, New Delhi - Asia/Kolkata (UTC+05:30)",
      "Kathmandu - Asia/Kathmandu (UTC+05:45)",
      "Astana - Asia/Almaty (UTC+06:00)",
      "Dhaka - Asia/Dhaka (UTC+06:00)",
      "Yekaterinburg - Asia/Yekaterinburg (UTC+06:00)",
      "Yangon - Asia/Yangon (UTC+06:30)",
      "Bangkok, Hanoi, Jakarta - Asia/Bangkok (UTC+07:00)",
      "Novosibirsk - Asia/Novosibirsk (UTC+07:00)",
      "Krasnoyarsk - Asia/Krasnoyarsk (UTC+08:00)",
      "Ulaanbaatar - Asia/Ulaanbaatar (UTC+08:00)",
      "Beijing, Chongqing, Hong Kong, Urumqi - Asia/Shanghai (UTC+08:00)",
      "Perth - Australia/Perth (UTC+08:00)",
      "Kuala Lumpur, Singapore - Asia/Singapore (UTC+08:00)",
      "Taipei - Asia/Taipei (UTC+08:00)",
      "Irkutsk - Asia/Irkutsk (UTC+09:00)",
      "Seoul - Asia/Seoul (UTC+09:00)",
      "Osaka, Sapporo, Tokyo - Asia/Tokyo (UTC+09:00)",
      "Darwin - Australia/Darwin (UTC+09:30)",
      "Adelaide - Australia/Adelaide (UTC+09:30)",
      "Hobart - Australia/Hobart (UTC+10:00)",
      "Yakutsk - Asia/Yakutsk (UTC+10:00)",
      "Brisbane - Australia/Brisbane (UTC+10:00)",
      "Guam, Port Moresby - Pacific/Port_Moresby (UTC+10:00)",
      "Canberra, Melbourne, Sydney - Australia/Sydney (UTC+10:00)",
      "Vladivostok - Asia/Vladivostok (UTC+11:00)",
      "Solomon Islands, New Caledonia - Pacific/Guadalcanal (UTC+11:00)",
      "Coordinated Universal Time+12 - Etc/GMT-12 (UTC+12:00)",
      "Fiji, Marshall Islands - Pacific/Fiji (UTC+12:00)",
      "Magadan - Asia/Magadan (UTC+12:00)",
      "Auckland, Wellington - Pacific/Auckland (UTC+12:00)",
      "Nuku'alofa - Pacific/Tongatapu (UTC+13:00)",
      "Samoa - Pacific/Apia (UTC+13:00)",
    ];

    const timezone_list = [];
    
    try {
      for(let j = 0; j < timezones.length; j++) {
        timezone_list.push({
          value: timezones[j],
          label: timezones[j],
        });
      }
    } catch {
      console.log("error");
    }

    console.log("userdetails.dashboard.eventdetails");
    console.log(userdetails.dashboard.eventdetails);



const chapterassigned = [];

console.log("propz")
  console.log(props)

let chapter_dash_names = []

if(props.editedEvent[0] != undefined
  || props.editedEvent[0] != null){
chapter_dash_names = props.editedEvent[0].chapter_dashboard

try{
  for(var k = 0; k < chapter_dash_names.length; k++){
  chapterassigned.push({
      value: chapter_dash_names[k].chapter_dash_names,
      label: chapter_dash_names[k].chapter_dash_names
  })
}
  }
  catch{
    console.log("chapter setting error")
  }
}
    return (
      <div>
        {chapterpage_redirect}
        <ToastContainer
          position="top-left"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ fontFamily: "Georgia" }}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <span style={{fontFamily:"Verdana, Geneva, Helvetica", fontWeight: "bold"}}>Edit Event</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={onSubmit}>
            {props.editedEvent.map((item) => (
              <div style={{fontFamily:"Verdana, Geneva, Helvetica"}}>
                <div className="form-group">
                  <label htmlFor="eventname">
                    <b>Event Name</b>
                  </label>
                  <input
                    id="eventname"
                    type="text"
                    name="eventname"
                    className="form-control form-control-md"
                    style={{
                      flex: "8",
                      padding: "5px",
                      border: ".5px solid black",
                      color: "black",
                    }}
                    placeholder="Annual Gala..."
                    defaultValue={item.name}
                    onChange={onChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="location">
                    <b>Location </b>
                  </label>
                  <input
                    id="location"
                    type="text"
                    name="location"
                    className="form-control form-control-md"
                    style={{
                      flex: "8",
                      padding: "5px",
                      border: ".5px solid black",
                      color: "black",
                    }}
                    placeholder="5555 Gilmore St. Chicago, IL 60626..."
                    defaultValue={item.location}
                    onChange={onChange}
                  />
                </div>
                <div className="row">
                  <div
                    className="form-group"
                    style={{ marginLeft: "15px", marginRight: "15px" }}
                  >
                    <label htmlFor="startdate">
                      <b>Start Date</b>
                    </label>
                    <input
                      id="startdate"
                      type="date"
                      className="form-control form-control-md"
                      name="startdate"
                      style={{
                        flex: "8",
                        padding: "5px",
                        border: ".5px solid black",
                        color: "black",
                      }}
                      defaultValue={moment(
                        item.startdate
                      )
                        .utc()
                        .format("YYYY-MM-DD")}
                      onChange={onChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="enddate">
                      <b>End Date</b>
                    </label>
                    <input
                      id="enddate"
                      type="date"
                      name="enddate"
                      className="form-control form-control-md"
                      style={{
                        flex: "8",
                        padding: "5px",
                        border: ".5px solid black",
                        color: "black",
                      }}
                      defaultValue={moment(item.enddate)
                        .utc()
                        .format("YYYY-MM-DD")}
                      onChange={onChange}
                    />
                  </div>
                </div>

                <div className="row">
                  <div
                    className="form-group"
                    style={{ marginLeft: "15px", marginRight: "15px" }}
                  >
                    <label htmlFor="starttime">
                      <b>Start Time</b>
                    </label>
                    <input
                      id="starttime"
                      type="time"
                      className="form-control form-control-md"
                      name="starttime"
                      style={{
                        flex: "8",
                        padding: "5px",
                        border: ".5px solid black",
                        color: "black",
                      }}
                      // placeholder="Phone..."
                      defaultValue={item.starttime}
                      onChange={onChange}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="endtime">
                      <b>End Time</b>
                    </label>
                    <input
                      id="endtime"
                      type="time"
                      name="endtime"
                      className="form-control form-control-md"
                      style={{
                        flex: "8",
                        padding: "5px",
                        border: ".5px solid black",
                        color: "black",
                      }}
                      // placeholder="Regular Ticket..."
                      defaultValue={item.endtime}
                      onChange={onChange}
                    />
                  </div>
                  <div className="form-group"></div>
                </div>
          

              {/* <label className="" for="eventname">
                <b>Timezone</b>
              </label>
              <select
                id="timezone"
                className="form-control mr-sm-2"
                name="timezone"
                style={{ width: "50%", marginBottom: 20, color: "black" }}
                placeholder="Enter Timezone"
                onChange={(e) => onChange(e)}
                defaultValue={userdetails.dashboard.eventdetails.timezone}
              >
                <option selected="selected"></option>
                {timezones.map((x, y) => (
                  <option key={y}>{x}</option>
                ))}
              </select> */}


              <div style={{marginBottom: 10}}>
                
              <label className="" htmlFor="eventname">
                <b>Timezone</b>
              </label>
              <Select
                      // isMulti
                      defaultValue={timezone_default}
                      onChange={onChangeEditTimezone}
                      options={timezone_list}
                    />
              </div>

                  <div style={{marginBottom: 10}}>
              <label className="" htmlFor="eventname">
                <b>Chapter</b>
              </label>
              <Select
                      isMulti
                      defaultValue={chapterassigned}
                      onChange={onChangeEditChapter}
                      options={eventchapter}
                    />
              </div>


              <div className="form-group">
                <label htmlFor="additionaldetails">
                  <b>Additional Details</b>
                </label>
                <textarea
                  id="additionaldetails"
                  type="text"
                  name="additionaldetails"
                  className="form-control form-control-md"
                  style={{
                    flex: "8",
                    padding: "5px",
                    border: ".5px solid black",
                    color: "black",
                  }}
                  placeholder="..."
                  defaultValue={item.additionaldetails}
                  onChange={onChange}
                />
              </div>
              </div>
                ))}
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{
                borderRadius: 20,
                marginRight: 15,
                width: "17%",
                border: "1px solid DimGray",
                fontSize: "14px",
                fontWeight: "bold",
                fontFamily:"Verdana, Geneva, Helvetica"
              }}
              variant="success"
              onClick={onSubmit}
            >
              Submit
            </Button>
            <Button
              style={{
                borderRadius: 20,
                width: "17%",
                border: "1px solid DimGray",
                fontSize: "13px",
                fontWeight: "bold",
                fontFamily:"Verdana, Geneva, Helvetica"
              }}
              variant="danger"
              onClick={props.onHide}
              className="edit_confirm"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
}

const mapStateToProps = (state, ownProps) => {
  return {
    state: state,
    dashboards: state.dashboard,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
};

const mapFetchToProps = (dispatch) => {
  return {
    editDashboard: (
      id,
      name,
      location,
      startdate,
      enddate,
      starttime,
      endtime,
      timezone,
      additionaldetails
    ) => {
      dispatch(
        action.editDashboard(
          id,
          name,
          location,
          startdate,
          enddate,
          starttime,
          endtime,
          timezone,
          additionaldetails
        )
      );
    },
    fetchDashboard: () => {
      dispatch(action.fetchDashboard());
    },
    clearDashmessage: () => {
      dispatch(action.clearDashmessage());
    },
  };
};

export default EditEventModal;
