import React, { useState, useEffect, MouseEventHandler } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { RootStateOrAny, useDispatch } from "react-redux"
import * as actions from "../../../store/actions/index";

type Props = {
    chaptersarray: any;
    chapternames: any;
    edititemid: any;
    chapterdescriptions: any;
    onHide: (() => void)
    show: boolean | undefined;
    
}

const EditChapter = (props: Props) => {

    // const accountDetails = useSelector((state: RootStateOrAny) => state.auth)
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        chapter_name: props.chapternames,
        chapter_description: props.chapterdescriptions,
      });


useEffect(() => {

    setFormData({
        chapter_name: props.chapternames,
        chapter_description: props.chapterdescriptions,
    })
  
//   return () => {
    
//   }
}, [ props.chapterdescriptions, props.chapternames])


    const onChange= (e: { target: { name: any; value: any; }; }) => {

        const { name, value } = e.target;
        
        setFormData({
            ...formData,
            [name]: value,
          });
    
          console.log("formData")
          console.log(formData)
      };

      const onSubmit = () => {

        let editData = {
        edititemid: props.edititemid,
        chapter_name: formData.chapter_name,
        chapter_description: formData.chapter_description
        }

    dispatch(actions.editChapters(editData));

    props.onHide()
      }


  return (
        <div>
                <Modal
                show={props.show}
                onHide={props.onHide}
                // {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Chapter
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=''>
                        <form 
                        onSubmit={onSubmit}
                        >
                            <div >
                                <div className="form-group">
                                    <label htmlFor="chapter_name"><b>Chapter Name:</b></label>
                                    <input
                                    defaultValue={props.chapternames}
                                        id="chapter_name"
                                        className="form-control form-control-md"
                                        type="text"
                                        name="chapter_name"
                                        style={{ flex: '8', padding: '5px', border: '.5px solid black' }}
                                        placeholder="Chapter_name..."
                                        onChange={onChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="chapter_description"><b>Chapter Description:</b></label>
                                    <input
                                    defaultValue={props.chapterdescriptions}
                                        id="chapter_description"
                                        className="form-control form-control-md"
                                        type="text"
                                        name="chapter_description"
                                        style={{ flex: '8', padding: '5px', border: '.5px solid black' }}
                                        placeholder="Chapter_description..."
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success"
                    onClick={onSubmit}
                    >Submit</Button>
                    <Button variant="danger"
                    onClick={props.onHide}
                    >Close</Button>
                </Modal.Footer>
            </Modal>
    </div>
  )
}

export default EditChapter