import React, { useState, useEffect, CSSProperties } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import Button from "react-bootstrap/Button";
// import * as actions from "../../../store/actions/index";
import { Redirect, useHistory } from "react-router-dom";
// import axios from 'axios';
import RingLoader from "react-spinners/RingLoader";
import axios from 'axios';

// const override: CSSProperties = {
//   display: "block",
//   margin: "0 auto",
//   borderColor: "red",
// };

const CreateVolunteer = (props) => {

  const userdetails = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    chapter: "",
    permission: "Volunteer",
    password: "",
    associatedemail: userdetails.auth.user.email,
    phone: userdetails.auth.user.phone,
    address: userdetails.auth.user.address,
    city: userdetails.auth.user.city,
    state: userdetails.auth.user.state,
    zip: userdetails.auth.user.zip,
    country: userdetails.auth.user.country
  });

  const [usernameerror, setUsernameerror] = useState(false)
  const [startsearch, setStartsearch] = useState(false)

  const [showPassword, setShowPassword] = useState(false)
  const [usernameAvailable, setUsernameAvailable] = useState(false)
  const [usernamecharerror, setUsernamecharerror] = useState(false)
  const [redirect, setRedirect] = useState(false)

  const [passwordLength, setPasswordLength] = useState(false)
  const [passwordNumber, setPasswordNumber] = useState(false)
  const [passwordUppercase, setPasswordUppercase] = useState(false)
  const [typingPassword, setTypingPassword] = useState(false)

  const [usernameCriteria, setUsernameCriteria] = useState(true)
  const [passwordCriteria, setPasswordCriteria] = useState(true)
  const [first_nameCriteria, setFirst_nameCriteria] = useState(true)
  const [last_nameCriteria, setLast_nameCriteria] = useState(true)
  
  const [firstNameError, setFirstNameError] = useState(false)
  const [lastNameError, setLastNameError] = useState(false)
  const [ChapterError, setChapterError] = useState(false)

  const [enableSubmit, setEnableSubmit] = useState(true)

  useEffect(() => {
    if(usernameCriteria === false
      && passwordCriteria === false 
      && first_nameCriteria === false
      && last_nameCriteria === false
      ){
      setEnableSubmit(false) 
    }
    else{
        setEnableSubmit(true) 
    }

    
  }, [passwordCriteria, usernameCriteria, first_nameCriteria, last_nameCriteria])
  

  // Permission Levels Strict Answers
  const permissionoptions = [
    { value: "Owner", label: "Owner (Account Management)" },
    { value: "Admin", label: "Admin (View, Edit and Delete)" },
    { value: "Editor", label: "Editor (View and Edit)" },
    { value: "Volunteer", label: "Volunteer (View and Checkout)" },
  ];

   // Permission Levels Default Answer
  const permissiondefault = [
    { value: "volunteer", label: "Volunteer (View only)" },
  ];

    // Permission Levels All Events Addition to Chapters Created
  const chapterdefaultoption = [
    { value: "All Events", label: "All Events" },
  ];

  let [loading, setLoading] = useState(true);
  // let [color, setColor] = useState("#ffffff");

  useEffect(() => {
  // console.log("userdetails")
  // console.log(userdetails.auth.user)
  // setUsernameAvailable(false)
  // // if()
  // // setUsernameCriteria(false)
  // return () => {
  //   setUsernameAvailable(false)
  // }
  if(usernameerror === true){
    setUsernameAvailable(false)
}
  }, [usernameAvailable, usernameerror])

  let onChangeAddFirstName = (e) => {

    const { name, value } = e.target;
    
    setFormData({
        ...formData,
        [name]: value,
      });

      console.log("formData")
      console.log(formData)

     if(e.target.value.length > 0){
       setFirstNameError(false)
       setFirst_nameCriteria(false)
     }
     else{
      setFirstNameError(true)
      setFirst_nameCriteria(true)
     }

     


  };

  let onChangeAddLastName = (e) => {

    const { name, value } = e.target;
    
    setFormData({
        ...formData,
        [name]: value,
      });

      console.log("formData")
      console.log(formData)

      if(e.target.value.length > 0){
        setLastNameError(false)
        setLast_nameCriteria(false)
      }
      else{
        setLastNameError(true)
        setLast_nameCriteria(true)
      }
  };

  let onChangePassword = (e) => {

    const { name, value } = e.target;
    
    setFormData({
        ...formData,
        [name]: value,
      });

      console.log("formData")
      console.log(formData)

      // Show error messages under password
      if(e.target.value.length > 0){
        setTypingPassword(true)
        }
        else{
          setTypingPassword(false)
        }

    // Is password over 5 characters
      if(e.target.value.length > 5){
      setPasswordLength(true);
      }
      else{
        setPasswordLength(false);
      }

      let hasNumber = /\d/;

   // Does the Password Have a Number?
    if (
      hasNumber.test(e.target.value) === true
    ) {
      setPasswordNumber(true);
    } 
    else if (hasNumber.test(e.target.value) === false) {
      setPasswordNumber(false);
    }

    
    // Does the Password Have an UpperCase Character?
    function hasUpperCase(str) {
      return str !== str.toLowerCase();
      }
    if (
      hasUpperCase(e.target.value) === true
    ) {
      setPasswordUppercase(true)

    } else if (hasUpperCase(e.target.value) === false) {
      setPasswordUppercase(false)
    }

    // Do All 3 Match? Disable Submit Button
    if (
      e.target.value.length > 5 &&
      hasNumber.test(e.target.value) === true &&
      hasUpperCase(e.target.value) === true) {
      setPasswordCriteria(false)
    } 
    else {
      setPasswordCriteria(true)
    }
    
  };

  let onChangeUsernameAdd = (e) => {
    console.log("username add")
    e.persist()
    const { name, value } = e.target;

    setUsernameAvailable(false)

      console.log("formData")
      console.log(formData)

      console.log("e.target.value")
      console.log(e.target.value)

      setUsernamecharerror(true)

      if(e.target.value.length<6){
        setUsernamecharerror(true)
      }
      else{
        setUsernamecharerror(false)
        setStartsearch(true)

      axios.get('/api/users/getallusernames')
      .then(res => {
          // setChapters_array(res.data.user.chapter)
          console.log("axios responsess11")
          console.log(res.data.usernamestaken)
          if(res.data.usernamestaken.toLocaleString().toLowerCase().split(',').includes(e.target.value.toLowerCase())){
            console.log("username exists!")
            setUsernameerror(true)
            setStartsearch(false)
            setUsernameAvailable(false)
            setUsernameCriteria(true)
          }
          else if (!res.data.usernamestaken.toLocaleString().toLowerCase().split(',').includes(e.target.value.toLowerCase())){
            console.log("username available")

            setFormData({
              ...formData,
              [name]: value.toLowerCase(),
            });

            setUsernameCriteria(false)
            setUsernameerror(false)
            setTimeout(() => {
              setStartsearch(false)
              setUsernameAvailable(true)
            }, 1000);
          }

          if(usernameerror === true){
              setUsernameAvailable(false)
          }
      })
      .catch(err => {
          console.log(err.response)
      })
  };

}

  let onChangePermissions = (e) => {
    setFormData({
      ...formData,
      permission: e.value
    });
      
      console.log(e.value)

  };

  let onChangeChapters = (e) => {
    try{
    if(e[0].value === "- Create New Chapter -"){
      setRedirect(true)
    }
  }
  catch{
    console.log("No redirect attempt")
  }

    // console.log("heraga")
    // console.log(e[0].value)

    setFormData({
      ...formData,
      chapter: e
    });
    // setChapterPermissions(e)
      console.log(e)

      if(e.length > 0){
        setChapterError(false)
      }
      else if(e.length === 0){
        setChapterError(true)
      }

  };

  let chapterpage_redirect = null;
  if (redirect) {
    chapterpage_redirect = <Redirect to="/chapters" />;
  }

  const closeModal = () => {
    props.onHide()
    setShowPassword(false)
    setUsernameAvailable(false)
    setUsernameerror(false)
    setUsernamecharerror(false)
  }

  let submitAdd = () => {
    console.log("formData")
    console.log(formData)
    // console.log(chapterPermissions)

    // dispatch(actions.registerAdminUser(formData));

    props.create_volunteer(formData)

    // axios.post('/api/users/adminregister/', formData)
    // .then(res => {
    //   console.log("axios response")
    //    console.log(res.data.user)
    //  })
    //  .catch(err=> {
    //    console.log("axios frontend error")
    //    console.log(err)
    //  })
    setShowPassword(false)
    setUsernameAvailable(false)
    props.onHide()
  }


  // In order to push existing chapter permssions into list of chosen chapter positions.
  try{
  let chapter_permissions = userdetails.auth.user.chapter.map((arr)=>arr.chapter_names)
 
    for(var i=0; i< chapter_permissions.length; i++){
        chapterdefaultoption.push({
      value: chapter_permissions[i],
      label: chapter_permissions[i]
    })
  }
  chapterdefaultoption.push({
    value: "- Create New Chapter -",
    label: "- Create New Chapter -",
  });
}
catch{
    console.log("push error - chapter permissions")
}

  return (
    <div>
      {chapterpage_redirect}
      <Modal
        {...props}
        show={props.show}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <b color="#909090">Create a Volunteer</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form
              onSubmit={submitAdd}
            >
              <div>
              <div style={{display: "flex", justifyContent: "space-between"}}>
                <div style={{width: "48%"}} className="form-group">
                  <label htmlFor="first_name">
                    <b>First Name</b>
                  </label>
                  <input
                    id="first_name"
                    type="text"
                    name="first_name"
                    className="form-control form-control-md"
                    style={{
                      flex: "8",
                      padding: "5px",
                      border: ".5px solid black",
                    }}
                    placeholder="First Name..."
                    //   defaultValue={formData.name}
                    onChange={onChangeAddFirstName}
                    onBlur={(e)=>{
                      console.log("e.target.value")
                      console.log(e.target.value)
                      if(e.target.value === ""){
                        setFirstNameError(true)
                      }
                      else{
                        setFirstNameError(false)
                      }
                    }}
                    // onBlur={onChangeAdd}
                  />
                  {firstNameError ?
                  <div style={{marginTop: 2}}>
                <span style={{
                  marginLeft: 2,
                  fontSize:"13px",
                  fontWeight: "bold",
                  color: "darkred"
                  }}>First name can't be blank</span>
                  </div>
                  : null}
                </div>


                <div style={{width: "48%"}} className="form-group">
                  <label htmlFor="name">
                    <b>Last Name</b>
                  </label>
                  <input
                    id="last_name"
                    type="text"
                    name="last_name"
                    className="form-control form-control-md"
                    style={{
                      flex: "8",
                      padding: "5px",
                      border: ".5px solid black",
                    }}
                    onBlur={(e)=>{
                      console.log("e.target.value")
                      console.log(e.target.value)
                      if(e.target.value === ""){
                        setLastNameError(true)
                      }
                      else{
                        setLastNameError(false)
                      }
                    }}
                    placeholder="Last Name..."
                    //   defaultValue={formData.name}
                    onChange={onChangeAddLastName}
                    // onBlur={onChangeAdd}
                  />
                  {lastNameError ? 
                <div style={{marginTop: 2}}>
                <span style={{
                  marginLeft: 2,
                  fontSize:"13px",
                  fontWeight: "bold",
                  color: "darkred"
                  }}>Last name can't be blank</span>
                  </div>
                  : null }
                </div>
                
                
              </div>
                <div className="form-group">
                  <div style={{display: "flex"}}>
                  <label htmlFor="email">
                    <b >Username      </b>
                    </label>
                    {startsearch ?
                    <div style={{display: "flex"}}>
                    <span style={{color: "#073980"}}>Checking for Availability.. </span>
                  <RingLoader
                  color={"#073980"}
                  loading={loading}
                  size={20}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  speedMultiplier={1}
                  margin={0}
                />
                </div>
                : null}
                {usernameAvailable ? 
                  <span style={{color: "darkgreen"}}><b>Username Available</b> </span>
                  : null}
                {usernamecharerror ? 
                  <span style={{color: "darkred"}}><b>Username must be over 5 characters</b> </span>
                  : null}
                </div>
       
                  <input
                    id="username"
                    type="text"
                    name="username"
                    className="form-control form-control-md"
                    style={{
                      flex: "8",
                      padding: "5px",
                      border: ".5px solid black",
                    }}
                    placeholder="Username..."
                    //   defaultValue={formData.username}
                    onChange={onChangeUsernameAdd}
                    // onBlur={onChangeUsernameAdd}
                  />
                  {usernameerror ?
                      <div style={{color: "darkred", marginTop:5, marginLeft: 5, fontWeight: "bold"}}>
                        Username not available! Please Choose another Username.</div>
                        : null }
                     </div>
                

                <div className="form-group">
                  <label htmlFor="permission">
                    <b>Permission </b>
                  </label>

                  <Select
                    defaultValue={permissiondefault}
                    onChange={onChangePermissions}
                    options={permissionoptions}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="chapter">
                    <b>Chapter </b>
                  </label>

                  <Select
                    isMulti
                    // defaultValue={permissionAssigned}
                    onChange={onChangeChapters}
                    options={chapterdefaultoption}
                  />
                  {ChapterError ? 
                <div style={{marginTop: 2}}>
                <span style={{
                  marginLeft: 2,
                  fontSize:"13px",
                  fontWeight: "bold",
                  color: "darkred"
                  }}>Chapter can't be blank</span>
                  </div>
                  : null}
                </div>


                <div className="form-group">
                  <label htmlFor="password">
                    <b>Password</b>
                  </label>
                  <div style={{display: "flex"}}>
                  <input
                    id="name"
                    name="password"
                    className="form-control form-control-md"
                    type={showPassword ? "text" : "password"}
                    style={{
                      flex: "8",
                      padding: "5px",
                      border: ".5px solid black",
                    }}
                    placeholder="Password..."
                    //   defaultValue={formData.name}
                    onChange={onChangePassword}
                  />
                    <div
                      onClick={() => setShowPassword (true)}
                      style={{
                        textAlign: "center",
                        cursor: "pointer",
                        border: ".5px solid black",
                        padding: 4,
                        fontFamily: "Verdana, Geneva, Helvetica",
                        width: "60px",
                      }}
                    >
                      Show
                    </div>
                  </div>


              {typingPassword ?
                <div style={{marginTop:13, marginBottom:-10}}>
                  {passwordLength ? (
                  <div
                    style={{
                      color: "darkgreen",
                      fontWeight: "bold",
                      fontFamily: "Verdana, Geneva, Helvetica",
                    }}
                  >
                    <i className="fas fa-check fa-xl"></i> Atleast 5 Characters
                  </div>
                ) : (
                  <div
                    style={{
                      color: "darkred",
                      fontWeight: "bold",
                      fontFamily: "Verdana, Geneva, Helvetica",
                    }}
                  >
                    <i className="fas fa-remove fa-xl"></i> Atleast 5 Characters
                  </div>
                )}

                {passwordNumber ? (
                  <div
                    style={{
                      color: "darkgreen",
                      fontWeight: "bold",
                      fontFamily: "Verdana, Geneva, Helvetica",
                    }}
                  >
                    <i className="fas fa-check fa-xl"></i> Includes Number
                  </div>
                ) : (
                  <div
                    style={{
                      color: "darkred",
                      fontWeight: "bold",
                      fontFamily: "Verdana, Geneva, Helvetica",
                    }}
                  >
                    <i className="fas fa-remove fa-xl"></i> Includes Number
                  </div>
                )}

      {passwordUppercase ? (
        <div
          style={{
            color: "darkgreen",
            fontWeight: "bold",
            fontFamily: "Verdana, Geneva, Helvetica",
          }}
        >
          <i className="fas fa-check fa-xl"></i> Includes Uppercase
        </div>
      ) : (
        <div
          style={{
            color: "darkred",
            fontWeight: "bold",
            fontFamily: "Verdana, Geneva, Helvetica",
          }}
        >
          <i className="fas fa-remove fa-xl"></i> Includes Uppercase
        </div>
      )}
</div>
: null}


                </div>
                
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={submitAdd}
            disabled={enableSubmit}
          >
            Submit
          </Button>
          <Button variant="danger" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateVolunteer;
