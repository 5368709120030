import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "../../../store/actions/index";
import Fragment from "../../../utils/Fragment";
import DashHeading from "../../../components/Dashboards/Dashboard/DashHeading";
import styled from "@emotion/styled";
// import UseCSV from "@usecsv/react";
// import ImportCsv from "../Lists/ImportCsv";
import { Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./DashbboardList.css";
import axios from "axios"
import qrvector from './qrvectorimage.png'; 
import { isEmpty } from "../../../utils/Validation";

const CardElementcontainer = styled.div`
  width: 99%;
  margin-top: 2%;
  margin-left: 0.5%;

  @media screen and (max-width: 900px) {
    width: 99%;
    margin-top: 2%;
    margin-left: 0.5%;
  }

  @media screen and (max-width: 400px) {
    width: 99%;
    margin-top: 2%;
    margin-left: 0.5%;
  }
`;

class DashboardLists extends Component {
  constructor() {
    super();
    this.state = {
      listItem: "",
      items: [],
      addModalShow2: false,
      addModalShow: false,
      disableSendInvitation: false,
      messageSubject: "",
      inviteMessage: "",
      inviteSignature: "", 
      inviteSalutation: "",
      invitePreview: "",
      emailPreview: "",
      inviteMessageServer: "",
      inviteSignatureServer: "",
      salutationPreview: ""
    };

    this.onClick = this.onClick.bind(this);
  }


  onClick = (id) => {
    this.props.deleteDashboard(id);
    this.props.switchEventFalsy(id);
    // this.props.dashboards.switchEvent = false
  };

  componentDidMount(){
    console.log("component did mount")
    console.log("redux state")
    console.log(this.props.dashboards)
    console.log(this.props.dashboards.eventName)
    // console.log(this.props.dashboards.dashboard[0].lists[0].list)
  }

  sendInvites() {

    let eventData = {
      eventId: this.props.dashboards.eventName
    }

    eventData["messageSubject"] = this.state.messageSubject
    eventData["inviteMessage"] = this.state.inviteMessage
    eventData["inviteSalutation"] = this.state.inviteSalutation
    eventData["inviteSignature"] = this.state.inviteSignature
    eventData["inviteMessageServer"] = this.state.inviteMessageServer
    eventData["inviteSignatureServer"] = this.state.inviteSignatureServer
    
    // axios
    // .post("/api/users/qremail", eventData)
    // .then((res) => {})
    // .catch((err) => {
    //   console.log(err.response);
    // });
    // this.setState({disableSendInvitation: true})

    axios.post('/api/users/nodemailer/', eventData)
    .then(res => {
       console.log(res.data)
     })
     .catch(err=> {
       console.log("nodemailer frontend error")
       console.log(err)
     })
    
    }


    handleSubjectChange = (event) => {
      const { name, value } = event.target;
    this.setState({ [name]: value });

    // consoles messageSubject
    console.log(name)
    console.log(value)
    };

    handleSalutation = (event) => {
      const { name, value } = event.target;
    this.setState({ [name]: value });
    console.log(name)
    console.log(value)
    let guestPreview=" " + this.props.dashboards.dashboard[0].lists[0].list + ","
    this.setState({ salutationPreview: guestPreview});

    };



    handleMessageChange = (event) => {
      // this.setState({messageSubject: id})
      const { name, value } = event.target;


      this.setState({
        [name]: value
      }, () => {
        this.setState({ [name]: value });;
      });

    // consoles inviteMessage
      console.log(name)
      console.log(value)

      let addingBreaksMessage = value
      addingBreaksMessage = addingBreaksMessage.replace(/(?:\r\n|\r|\n)/g, '</br>');
      this.setState({inviteMessageServer: addingBreaksMessage})
    };

    handleSignatureChange = (event) => {
      // this.setState({messageSubject: id})
      const { name, value } = event.target;


      this.setState({
        [name]: value
      }, () => {
        this.setState({ [name]: value });;
      });

    // consoles inviteMessage
      console.log(name)
      console.log(value)

      let addingBreaksSignature = value
      addingBreaksSignature = addingBreaksSignature.replace(/(?:\r\n|\r|\n)/g, '</br>');
      this.setState({inviteSignatureServer: addingBreaksSignature})

    };

    handlePreviewChange = (event) => {
      // this.setState({messageSubject: id})
      const { name, value } = event.target;


      this.setState({
        [name]: value
      }, () => {
        this.setState({ [name]: value });;
      });

    // consoles inviteMessage
      console.log(name)
      console.log(value)
    };


  

  render() {
    let addModalClose2 = () => this.setState({ addModalShow2: false });
    let addModalClose = () => this.setState({ addModalShow: false });
 
    let salutations= [
      "Dear", "Hi", "Hello", "Greetings"
    ]

    if (!isEmpty(this.props.dashboards)) {
    var specificDashboard = this.props.dashboards.dashboard.filter(item => item._id === this.props.dashboards.eventName);
    }

    console.log("specificDashboard")
    console.log(specificDashboard)

    let dashboardList = this.props.dashItem;

    let dashboard = (
      <CardElementcontainer>
        <div>
          <h1
            className="eventlist_titles"
            style={{
              textAlign: "center",
              marginTop: 35,
              marginBottom: 15,
            }}
          >
            <b>Send Event Invitations</b>
          </h1>
        </div>
        <hr className="solid"></hr>
        <div
          className="card List"
          style={{
            marginLeft: 30,
            marginRight: 30,
            padding: 5,
            background: "#F8F8F8",
            border: "1px solid darkgray",
          }}
        >
          <div className="card-body">
            <div className="DashHeader">
              <DashHeading
                dashHeadingId={dashboardList._id}
                dashHeadingTime={dashboardList.date}
                dashHeadingName={dashboardList.name}
              />
            </div>
   
          </div>
        </div>
        <div style={{ paddingLeft: 40, paddingTop: 20, paddingBottom: 20 }}>
          <h3>
            <b>Step 2:</b> Send out Invites with QR codes to the Guests in your chosen Event.
          </h3>
        </div>


<div className="row">

        <div className="col form-group messageContainer">
        <label for="messageSubject"><b>Message Subject:</b></label>
        <input
        id="messageSubject"
        className="form-control form-control-md"
            type="text"
            name="messageSubject"
            style= {{flex: '8', padding:  '5px', border: '.5px solid black'}}
            placeholder="Message Subject."
            onChange ={this.handleSubjectChange}
            />
     
        <br/>

        <label for="inviteSalutation"><b>Message Salutation:</b></label>
        <select
          id="inviteSalutation"
          name="inviteSalutation"
          className="form-control" 
           placeholder="Salutation"
           style={{color: "#707070", border: "1px solid #909090"}}
           defaultValue={""}
           value={this.state.inviteSalutation}
           onChange ={this.handleSalutation}
           onClick ={this.handleSalutation}
            >
              <option value="" default selected>Select Salutation..</option>
            {salutations.map((option) => (
              <option  style={{color: "black"}} value={option}>{option}</option>
            ))}
          </select>
              <br/>


        <label htmlFor="inviteMessage">
            <b>Email Message Body:</b>
          </label>

        <textarea
            placeholder="Message Body."
            className="form-control form-control-md"
            id="inviteMessage"
            name="inviteMessage"
            onChange={this.handleMessageChange}
            rows="10"
            required
          ></textarea>
          <br/>


          <label htmlFor="inviteSignature">
            <b>Email Signature/Sign-Off:</b>
          </label>
            <textarea
            placeholder="Signature/Sign-Off."
            className="form-control form-control-md"
            id="inviteSignature"
            name="inviteSignature"
            onChange={this.handleSignatureChange}
            rows="4"
            required
          ></textarea>
        </div>




            {/* Attempt Side Preview */}
        <div className="form-group col messageContainer">
        <label htmlFor="invitePreview">
            <b>Subject Preview: </b>
            {this.state.messageSubject}
          </label>
          <br/><br/>
        <label htmlFor="invitePreview">
            <b>Email Preview:</b>     
          </label>
            <div
            className="form-control form-control-md"
            id="invitePreview"
            name="invitePreview"
            onClick={this.handlePreviewChange}
          >
          {this.state.inviteSalutation}
          {this.state.inviteSalutation === "" ? null : this.state.salutationPreview}<br/> <br/>
          {this.state.inviteMessage}<br/> <br/>

          {this.state.inviteMessage === "" ? null : <div>
          <b>Event Name:</b> {this.props.dashboards.dashboard[0].name}<br/>
          <b>Name:</b> {specificDashboard[0].lists[0].list}<br/>
          <b>Organization:</b> {specificDashboard[0].lists[0].organization}<br/>
          <b>Table/Row #:</b> {specificDashboard[0].lists[0].table_row_number}<br/>
          <b>Seat #:</b> {specificDashboard[0].lists[0].seat_number}<br/>
          <b>Ticket Type:</b> {specificDashboard[0].lists[0].tickettype}<br/>
          <b>Additional Details:</b> {specificDashboard[0].lists[0].guest_details}<br/>
          <b>Confirmation Number:</b> {specificDashboard[0].lists[0]._id}<br/>
          <img src={qrvector} width="100px" style={{marginTop: "2%"}} alt="QR_Code" />
          </div>
            }<br/>
          {this.state.inviteSignature}
           
          </div>
        </div>


        </div>


     <br/>
     <Button
          // disabled={this.state.disableSendInvitation}
          onClick={() => this.sendInvites()}
          className="gobackButton"
          style={{
            marginTop: "3%",
            marginLeft: "42%",
            padding: 20,
            border: "1px solid darkgray",
            width: "25%",
            borderRadius: 25,
          }}
        >
          <b>Send Invites</b>
        </Button>
        <br/>

        <button
          className="gobackButton"
          onClick={() => this.props.noshowEvent()}
          style={{
            margin: 30,
            padding: 10,
            border: "1px solid darkgray",
            width: "15%",
            borderRadius: 20,
          }}
        >
          Go Back
        </button>
      </CardElementcontainer>
    );
    return <Fragment>{dashboard}</Fragment>;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    dashboards: state.dashboard,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    deleteDashboard: (id) => {
      dispatch(action.deleteDashboard(id));
    },
    switchEventFalsy: (id) => {
      dispatch(action.switchEventFalse(id));
    },
    addDashboardList: (
      id,
      text,
      email,
      phone,
      tickettype,
      guest_details,
      organization,
      table_row_number,
      seat_number
    ) => {
      dispatch(
        action.addList(
          id,
          text,
          email,
          phone,
          tickettype,
          guest_details,
          organization,
          table_row_number,
          seat_number
        )
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLists);

const addButton = {
  background: "pink",
  color: "black",
  fontWeight: "bold",
  fontSize: "14px",
  border: "1px solid black",
  padding: "5px 9px",
  borderRadius: "20%",
  width: "100%",
  cursor: "pointer",
};
