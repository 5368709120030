
import {createStore, combineReducers, applyMiddleware, compose} from 'redux'
import { configureStore } from "@reduxjs/toolkit";
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' 
import { composeWithDevTools } from "redux-devtools-extension";
import { dashboardSlice } from './store/reducers/dashboard'
import { userSlice } from './store/reducers/auth'

import auth from './store/reducers/auth'
import user from './store/reducers/auth'
import dashboard from './store/reducers/dashboard'

const rootReducer = combineReducers({auth, dashboard, user, dashboardSlice, userSlice})

const persistConfig = {
  key: 'root',
  storage,
  version: 1
}
// const persistedReducer = persistReducer(persistConfig, rootReducer)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))


const persistedReducer = persistReducer(persistConfig, rootReducer)

  // Store.
  // const store = createStore(
  //   persistReducer(persistConfig, rootReducer),
  //   composeEnhancers(applyMiddleware(thunk))
  // );
  
// export const store = configureStore({
//   reducer: persistedReducer,
//   devTools: process.env.NODE_ENV !== 'production',
//   middleware: [thunk]
// })


// const persistor = persistStore(store)

 export const store = createStore(
    persistedReducer,
    applyMiddleware(thunk),
  );

export default store
