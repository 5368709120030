import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import * as action from "../../../store/actions/index";
import "react-toastify/dist/ReactToastify.css";
// import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "./DeleteListModal.css";

const DelGuestModal = (props) => {
  const dispatch = useDispatch();

  const [currentSortName, setCurrentSortName] = useState("list_b");

  useEffect(() => {
    console.log("Delete List Modal-----------------------------------------");
    console.log("this.props.dashId");
    console.log(props.dashId);
    console.log("this.props.id");
    console.log(props.id);
  }, []);

  let onSubmit = (e) => {
    // e.preventDefault();
    let dashId = props.dashId.toString();
    let id = props.id.toString();
    // this.props.onClick(dashId, id);
    props.deleteList();
    // dispatch(action.deleteList(
    //   dashId,
    //   id,
    //   currentSortName
    //   ));

    console.log("this.props.dashId");
    console.log(props.dashId);
    console.log("this.props.id");
    console.log(props.id);

    toast.warning(
      <div style={{ fontSize: "16px" }}>
        <b>Guest Deleted!</b>
      </div>,
      {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );
    console.log("list delete runnnn");
  };

  return (
    <div>
      {" "}
      <ToastContainer
        position="top-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="deletemodaltitle"
            id="contained-modal-title-vcenter "
          >
            Delete Guest
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container deletemodaltext">
            <form style={{ display: "flex" }}>
              <p>
                <b>Are you Sure you would like to Delete this Guest??</b>
                <br />
                (This Action cannot be undone.)
              </p>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={onSubmit}>
            Confirm
          </Button>
          <Button variant="danger" onClick={props.onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DelGuestModal;
