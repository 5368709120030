// import { blockStatement } from "@babel/types";
// import { FaBlackberry } from "react-icons/fa";
import styled from "styled-components";
import Pricing from "./pricing";

function Prices() {
  return (
    <div className="container">

<div style ={{display: 'block', marginLeft:  'auto', marginRight: 'auto'}}>
<h1 style={{textAlign:"center",  marginTop: "5%", fontFamily:"Georgia, Helvetica"}}>
   <b style={{
     fontFamily: "Verdana, Geneva, Helvetica"
   }}
>Choose your Pricing Plan</b></h1>
</div>

    <MainContainer>

      <div className="pricing-component1">
          
          <Pricing
            data={[
              { text: "Unlimited Events", value: false },
              { text: "Unlimited Guests", value: false },
              { text: "Importing Data", value: true },
              { text: "Reporting and Analytics", value: true },
              { text: "Create Volunteers with Access Levels", value: true },
              { text: "Guest Relationships", value: true },
              { text: "QR Code Scanning", value: false },
            ]}
            price={0}
            duration="m"
            background="linear-gradient(315deg, #63a4ff 0%, #83eaf1 74%)"
            shadow="#63a4ff"
            currency="$"
            buttonContent="Get Started"
            subTitle="For Small Gatherings"
            priceText="Manage small gatherings with less than 25 Guests."
            headerText="Free"
          />
        </div>
        <div className="pricing-component">
          
          <Pricing
            data={[
              { text: "Unlimited Events", value: true },
              { text: "Unlimited Guests", value: true },
              { text: "Importing Data", value: true },
              { text: "Reporting and Analytics", value: true },
              { text: "Create Volunteers with Access Levels", value: true },
              { text: "Guest Relationships", value: true },
              { text: "QR Code Scanning", value: false },
            ]}
            price={199}
            duration="m"
            background="linear-gradient(120deg, #d4fc79 0%, #96e6a1 100%)"
            shadow="#96e6a1"
            currency="$"
            buttonContent="Get Started"
            subTitle="For Large Events"
            priceText="Manage Large Events for Unlimited Guests with Importing."
            headerText="Standard"
          />
        </div>
      <div className="pricing-component">
          
        <Pricing
          data={[
            { text: "Unlimited Events", value: true },
            { text: "Unlimited Guests", value: true },
            { text: "Importing Data", value: true },
            { text: "Reporting and Analytics", value: true },
            { text: "Create Volunteers with Access Levels", value: true },
            { text: "Guest Relationships", value: true },
            { text: "QR Code Scanning", value: true },
          ]}
          price={399}
          duration="m"
          background="linear-gradient(to left, #DA70D6 0%, #E0B0FF 100%);"
          shadow="#BA55D3"
          currency="$"
          buttonContent="Get Started"
          subTitle="For Pro Events"
          priceText="Manage Pro Events for Unlimited Guests with QR Scanning."
          headerText="Professional"
        />
      </div>
    </MainContainer>
    </div>
  );
}

const MainContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media screen and (max-width: 970px) {
    height: 100%;
    .pricing-component {
      margin: 1rem;
    }
  }

  @media (max-width: 900px) {
    .pricing-component1 {
      display: none;
    }
  }

`;

export default Prices;