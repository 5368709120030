import React, { Component } from "react";
import { connect } from "react-redux";
// import { verifyEmail } from "../../../store/actions/userActions";
import {
  Container,
} from 'semantic-ui-react';
// import * as action from "../../../store/actions";
import axios from "axios";
import "./VerifyEmail.css"
import { Alert, Grid } from "@mui/material";

class VerifyEmail extends Component{
  
  state = {
	password: "",
	confirmPassword: "",
	errorAlert: false,
	successAlert: false,
	towerMessage: ""
  };
  
  onChange = (e) => this.setState({
	...this.state, [e.target.name]: e.target.value 
  });

  componentDidMount(){

	const formData = {
	  password: this.state.password,
	  confirmPassword: this.state.confirmPassword,
	  email: this.props.match.params.email,
	  token: this.props.match.params.token
	};

    console.log("form_data")
    console.log(formData)
    console.log("this.props.match.params")
    console.log(this.props.match.params)

	// await axios.post(`/api/users/reset-password`, formData);

    console.log("second tower call run")
	// this.props.verifyEmail(formData);

	axios.post(`/api/users/verify-email`, formData)
	.then((res) => {
		console.log("verify response")
		console.log(res)
		this.setState({towerMessage: res.data.message})
		this.setState({errorAlert: false})
		this.setState({successAlert: true})
	})
	.catch((error) => {
	  console.log(error.response);
	  console.log(error.response.data.message);
	  console.log("error");
	  this.setState({towerMessage: error.response.data.message})
	  this.setState({errorAlert: true})
	  this.setState({successAlert: false})
	});

  }
  
  render(){

	return(
	  <div className="main" style={{marginTop:"3%"}}>

<div
        style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
      >
        <h1
          style={{
            textAlign: "center",
            marginTop: "35px",
            fontFamily: "Georgia, Helvetica",
          }}
        >
          <b
            style={{
              fontFamily: "Verdana, Geneva, Helvetica",
			  color: "#404040"
            }}
          >
            Email Verification
          </b>
        </h1>
      </div>
	  
		<Container style={{marginTop: 35}} text>
		  {/* <h2>Email verification</h2> */}
		  
		  {this.state.errorAlert ?
                    <Alert
                      // className="float-right"
                      style={{
                        // position: "fixed",
						width: "500px",
                        zIndex: 1,
                        border: ".1px solid gray",
                        fontSize: 14,
                      }}
                      severity="error"
                    >
                      <b>{this.state.towerMessage}</b>
                    </Alert>
                    : null}

				  {this.state.successAlert ?
                    <Alert
                      // className="float-right"
                      style={{
                        // position: "fixed",
						width: "500px",
                        zIndex: 1,
                        border: ".1px solid gray",
                        fontSize: 14,
                      }}
                      severity="success"
                    >
                      <b>{this.state.towerMessage}</b>
                    </Alert>
                    : null}
		</Container>
	  </div>
	)
  }
}


const mapStateToProps = (state) => ({
  authInfo: state.authInfo,
  passwordReset: state.passwordReset,
  user: state.auth.user,
});


export default connect(mapStateToProps, null)(VerifyEmail);