import React, { useState, useEffect, MouseEventHandler } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { RootStateOrAny, useDispatch, useSelector } from "react-redux"
import DeleteChapter from "./DeleteChapter";
import EditChapter from "./EditChapter";
import * as actions from "../../../store/actions/index";
// import { Redirect } from "react-router-dom";
import axios from "axios";
import { Alert, Grid } from "@mui/material";
import "./Chapters.css";

type Props = {
}

const Chapters = (props: Props) => {

    // const reduxState = useSelector((state) => state);
    const accountDetails = useSelector((state: RootStateOrAny) => state.auth)
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        chapter_name: "",
        chapter_description: "",
    });

    const [show, setShow] = useState(false)
    const [chapters_array, setChapters_array] = useState<any[]>([])
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [deletingId, setDeletingId] = useState("");
    const [editModalShow, setEditModalShow] = useState(false);

    const [edititemid, setedititemid] = useState("");
    const [chapternames, setchapternames] = useState("");
    const [chapterdescriptions, setchapterdescriptions] = useState("");
    const [requiredAlert, setRequiredAlert] = useState(false);
    const [existsAlert, setExistsAlert] = useState(false);

    let headers = [
        "Chapter Permission",
        "Description",
    ];

    // let authRedirect = null;
    // if (!accountDetails.isAuthenticated) {
    //   authRedirect = <Redirect to="/" />;
    // }

    useEffect(() => {

        //         console.log("accountDetails")

        //        console.log(accountDetails.user.chapter.map((chapter: {
        //            chapter_names: any; name: any; 
        // }) => chapter.chapter_names))

        setFormData({
            chapter_name: "",
            chapter_description: "",
        });

        setChapters_array(accountDetails.user.chapter)

        axios.get('/api/users/')
            .then(res => {
                setChapters_array(res.data.user.chapter)
                console.log("axios res.data.user.chapter")
                console.log(res.data.user.chapter)
            })
            .catch(err => {
                console.log(err.response)
            })


    }, [accountDetails.user.chapter, chapternames, chapterdescriptions])

    const onSubmit = (e: { preventDefault: () => void; }) => {

        const allchapter = chapters_array.map((item) => item.chapter_names);


        try {
            if (formData.chapter_name === "") {
                setRequiredAlert(true);
            }
            else if (allchapter.includes(formData.chapter_name)) {
                setExistsAlert(true);
                console.log('includes it');
            }
            else {
                // e.preventDefault();
                dispatch(actions.postVolunteers(formData));
                setChapters_array(accountDetails.user.chapter)
                setShow(false)
            }
        } catch (e) { console.log(e) }
    }

    let initiateDelete = (item: { _id: React.SetStateAction<string>; id: any; }) => {
        setDeletingId(item._id)
        setDeleteModalShow(true)
    }

    //Single Event Handler Approach:
    const onChange = (e: any) => {
        const { name, value } = e.target;

        if (name === "chapter_name" && value !== "") {
            setRequiredAlert(false);
        }

        setFormData({
            ...formData,
            [name]: value,
        });
    }

    let deleteModalClose = () => {
        setFormData({
            chapter_name: "",
            chapter_description: "",
        });
        setDeleteModalShow(false);
    }

    // let initiateModal = (id: any) => {
    //     console.log("id")
    //     console.log(id)
    //     setDeletingId(id)
    //     setDeleteModalShow(true)
    // }

    let deleteuser = (id: any) => {
        let dashId = deletingId
        dispatch(actions.deleteChapters(dashId));
        //   setChapters_array(accountDetails.user.chapter)
        setDeleteModalShow(false)
    };

    let addModalClose = () => setEditModalShow(false);

    let initiateEditModal = (item: any) => {
        setEditModalShow(true)
        console.log("item")
        console.log(item)

        console.log(item._id);
        console.log(item.chapter_names);
        console.log(item.chapter_descriptions);

        setedititemid(item._id);
        setchapternames(item.chapter_names);
        setchapterdescriptions(item.chapter_descriptions);

    }

    return (
        <div>
            {/* {authRedirect} */}
            <EditChapter
                chaptersarray={chapters_array}
                edititemid={edititemid}
                chapternames={chapternames}
                chapterdescriptions={chapterdescriptions}
                show={editModalShow}
                onHide={addModalClose}
            />
            <Modal
                show={show}
                onHide={() => setShow(false)}
                // {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Chapter Permission
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=''>
                        {requiredAlert ?
                            <Alert
                                style={{
                                    zIndex: 1,
                                    border: ".9px solid darkgreen",
                                    fontSize: 14,
                                    marginBottom: 12,
                                    marginRight: 11
                                }}
                                severity='error'
                            >
                                <b>Chapter Name is required</b>
                            </Alert>
                            : null}

                        {existsAlert ?
                            <Alert
                                style={{
                                    zIndex: 1,
                                    border: ".9px solid darkgreen",
                                    fontSize: 14,
                                    marginBottom: 12,
                                    marginRight: 11
                                }}
                                severity='error'
                            >
                                <b>Chapter Name already exists</b>
                            </Alert>
                            : null}
                        <form onSubmit={onSubmit}>
                            <div >
                                <div className="form-group">
                                    <label htmlFor="chapter_name"><b>Chapter Name:</b><b style={{ color: 'darkred' }}> *</b></label>
                                    <input
                                        id="chapter_name"
                                        className="form-control form-control-md"
                                        type="text"
                                        name="chapter_name"
                                        style={{ flex: '8', padding: '5px', border: '.5px solid black' }}
                                        placeholder="Chapter Name..."
                                        onChange={onChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="chapter_description"><b>Chapter Description:</b></label>
                                    <input
                                        id="chapter_description"
                                        className="form-control form-control-md"
                                        type="text"
                                        name="chapter_description"
                                        style={{ flex: '8', padding: '5px', border: '.5px solid black' }}
                                        placeholder="Chapter Description..."
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={onSubmit}>Submit</Button>
                    <Button variant="danger" onClick={() => setShow(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

            <DeleteChapter
                show={deleteModalShow}
                // id={activeItemId}
                onHide={deleteModalClose}
                deletingid={deletingId}
                deleteuser={deleteuser}
            />
            {chapters_array ?
                <div
                    className="chaptercontainer"
                    style={{
                        paddingTop: 10,
                        border: "1px solid darkgray",
                        padding: 20,
                        paddingLeft: 24,
                        borderRadius: 10,
                        paddingBottom: 24,
                    }}
                >
                    <h3
                        style={{
                            fontFamily: "Verdana",
                            fontWeight: "bold"
                        }}>Create User Chapter Permissions</h3>

                    <button
                        className="createchapterbutton"
                        style={{
                            marginTop: 10,
                            fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                            fontWeight: "bold",
                            marginBottom: 20
                        }}
                        onClick={() => setShow(true)}
                    >Create Chapter Permissions</button>
                    {chapters_array.length !== 0 ?
                        <table className="prTable" style={{ marginRight: -5 }}>
                            {/* <thead style={{backgroundColor: "#F8F8F8"}}> */}
                            <thead>
                                <tr className="prTableHeader">
                                    {headers.map((header) => (
                                        <th
                                            style={{
                                                minWidth: 65,
                                                // color: "#484848",
                                                color: "#FFFFFF",
                                                background: "#404040",
                                                paddingTop: 17,
                                                paddingLeft: 5,
                                                fontFamily: "Verdana",
                                                border: ".9px solid darkgray",
                                            }}
                                        >
                                            {header}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody style={{}}>
                                {chapters_array.map((item) => (
                                    <tr className="prTableRow" style={{ color: "#404040" }}
                                        onClick={() => initiateEditModal(item)}
                                    >
                                        <td
                                            key={item.chapter_names}
                                            className="chapterTableCell chapters_field"
                                            style={{
                                                borderTop: ".9px solid darkgray",
                                                fontSize: "13px",
                                                paddingRight: 18,
                                                paddingTop: 18,
                                                paddingBottom: 18,
                                                paddingLeft: 10,
                                                borderRight: ".9px solid darkgray",
                                                borderBottom: ".9px solid darkgray",
                                                fontFamily:
                                                    "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                                                // background: item.status === "Checked In" ? "#b9ecdb" : null,
                                            }}
                                        >
                                            <div onClick={(e) => console.log(item)}>{item.chapter_names}</div>{" "}
                                        </td>
                                        <td
                                            key={item.chapter_descriptions}
                                            className="chapterTableCell description_field"
                                            style={{
                                                borderTop: ".9px solid darkgray",
                                                fontSize: "13px",
                                                paddingRight: 18,
                                                paddingTop: 18,
                                                paddingBottom: 18,
                                                paddingLeft: 10,
                                                borderBottom: ".9px solid darkgray",
                                                minWidth: 100,
                                                fontFamily:
                                                    "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                                                // background: item.status === "Checked In" ? "#b9ecdb" : null,
                                                // color: item.status === "Checked In" ? "darkgreen" : null,
                                                // fontWeight: item.status === "Checked In" ? "bold" : null,
                                            }}>
                                            <div onClick={(e) => console.log(item)}>{item.chapter_descriptions}</div>{" "}
                                        </td>
                                        <td>
                                            <div>
                                                <i
                                                    style={{ fontSize: 20 }}
                                                    className="fas fa-remove fa-xl edit_volunteers_remove"
                                                    onClick={(e) => {
                                                        initiateDelete(item)
                                                        e.stopPropagation();
                                                        e.preventDefault();
                                                    }
                                                    }
                                                ></i>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        :
                        <div style={{ textAlign: "center", padding: 15 }}>
                            <h2 style={{ marginLeft: 20 }} className="noevents">
                                No Chapter Permissions Created...
                            </h2>
                        </div>
                    }
                </div>
                : null}
        </div>
    )
}

export default Chapters
