import React from 'react'
import Chapters from './Chapters'

type Props = {}

const ChaptersPage = (props: Props) => {
  return (
    <div>
    {" "}
    <h1
      className="eventlist_titles"
      style={{
        textAlign: "center",
        marginTop: 12,
        marginBottom: 15,
      }}
    >
      <b style={{fontWeight:"bold", fontFamily: "Verdana, Geneva, Helvetica" }}>
        Manage your Chapter Permissions
      </b>
    </h1>
    <hr></hr>
    <div style={{ padding: 20, paddingTop: 0, paddingLeft: 40 }}>
      <Chapters/>
    </div>
    </div>
  )
}

export default ChaptersPage