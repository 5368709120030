
import * as actionType from '../actions/actionTypes'
import {updateObject} from '../../utils/Validation'
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dashboard: {},
  switchEvent: false,
  eventName: "",
  eventTitle: "",
  dashMessage: "",
  dashMessageSuccess: "",
  eventdetails: {},
  eventid: 1,
  test: true,
  importing_complete_loading: false,
  importing_complete_updating: false,
  importing_error: false
}

export const dashboardSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    signIn: (state, action) => {
      state.test = true
    }
  }
})


const dashboardCreate = (state, payload) => {
  return updateObject(state, {
    ...payload
  })
}

const userDataSubscribe1 = (state) => {
  return updateObject(state, {
    dashMessage: ""
  })
}

const userDataSubscribe2 = (state) => {
  return updateObject(state, {
    dashMessage: "Subscription Needed to Add more than 25 Guests."
  })
}

const userDataSubscribe3 = (state) => {
  return updateObject(state, {
    dashMessage: "Subscription Needed to Add more than 2 Events."
  })
}

// 
const userDataSubscribe4 = (state) => {
  return updateObject(state, {
    dashMessage: "A Relationship has already been created for this Guest."
  })
}

const switchEventFalse = (state) => {
  return updateObject(state, {
    switchEvent: false
  })
}

const markImportingStarting = (state) => {
  return updateObject(state, {
    importing_complete_loading: true
  })
}

const markImportingComplete = (state) => {
  return updateObject(state, {
    importing_complete_loading: false
  })
}

const markImportingUpdatingStarting = (state) => {
  return updateObject(state, {
    importing_complete_updating: true
  })
}

const markImportingUpdatingComplete = (state) => {
  return updateObject(state, {
    importing_complete_updating: false
  })
}

const markImportingErrorTrue = (state) => {
  return updateObject(state, {
    importing_error: true
  })
}

const markImportingErrorFalse = (state) => {
  return updateObject(state, {
    importing_error: false
  })
}

const authentication = (state = initialState, action) => {
  switch (action.type) {
  
    case (actionType.DASHBOARD_CREATE): return dashboardCreate(state, action.payload);
    case (actionType.CLEAR_DASHMESSAGE): return userDataSubscribe1(state);
    case (actionType.SUBSCRIBE_LIST): return userDataSubscribe2(state);
    case (actionType.SUBSCRIBE_EVENT): return userDataSubscribe3(state);
    case (actionType.RELATIONSHIP_CREATED): return userDataSubscribe4(state);
    
    case (actionType.SWITCH_EVENTFALSE): return switchEventFalse(state);

    case (actionType.IMPORTING_START): return markImportingStarting(state);
    case (actionType.IMPORTING_FINISH): return markImportingComplete(state);
    case (actionType.IMPORTING_UPDATING_START): return markImportingUpdatingStarting(state);
    case (actionType.IMPORTING_UPDATING_FINISH): return markImportingUpdatingComplete(state);
    case (actionType.IMPORTING_ERROR): return markImportingErrorTrue(state);
    case (actionType.IMPORTING_ERROR_OFF): return markImportingErrorFalse(state);
    default:
      return state
  }
}

export default authentication