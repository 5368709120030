import React from "react";
import csvDownload from "json-to-csv-export";
import { func } from "prop-types";
import { ToastContainer, toast } from "react-toastify";
import "./Reports.css";
import moment from "moment";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

const CsvDownload = (props) => {
  const reduxState = useSelector((state) => state);
  const { dashboard, filename, eventName, ...others } = props;

  const runVolunteerReport = () => {
    axios
    .get("/api/users/getadmins")
    .then((res) => {
      console.log("volunteerz");
      console.log(res.data.user);

      // setAssociatedUsers(res.data.user);
      let volunteers = res.data.user;

      let allowed_volunteers = []

    // Chapter Specific Access
    let userChaptersAssigned = reduxState.auth.user.chapter_volunteer.map(
      (arr) => arr.chapters_assigned
    );
    console.log("userChaptersAssigned")
    console.log(userChaptersAssigned)

    let matching_userchapter = res.data.user.map((item)=>item.chapter_volunteer.filter((items)=>items.chapters_assigned.includes(userChaptersAssigned)))
    console.log("matching_userchapter")
    console.log(matching_userchapter)

    let matching_chapter_indexes = []
    for (var i = 0; i < matching_userchapter.length; i++) {
    if(matching_userchapter[i].length !== 0){
    matching_chapter_indexes.push(i)
    }
    }

    console.log("matching_chapter_indexes")
    console.log(matching_chapter_indexes)
 
    for (var j = 0; j < matching_chapter_indexes.length; j++) {
      allowed_volunteers.push(res.data.user[matching_chapter_indexes[j]])
    }

    console.log("allowed_volunteers")
    console.log(allowed_volunteers)


      let volunteerreport = [];

      for (var k = 0; k < volunteers.length; k++) {
        volunteerreport.push({
          "Volunteer Name": "",
        });
      }

      for (var l = 0; l < volunteers.length; l++) {
        let volunteer_chapters = volunteers[j].chapter_volunteer.map((item)=>
        item.chapters_assigned)

        console.log("dashboard.map((item) => item.checkedin_by_username)")
        console.log(dashboard.map((item) => item.checkedin_by_username))
        console.log("dashboard")
        console.log(dashboard)

        console.log("volunteers[j].username")
        console.log(volunteers[l].username)

        volunteerreport[l]["Volunteer Name"] = volunteers[l].first_name + " " + volunteers[l].last_name;
        //   volunteerreport[i]["Relationships"] = (dashboard[i].relationships.map((item) => item.related__type + " of " + dashboard.filter((item2) => item2._id == item.related__id)[0].list)).join(', ')
        volunteerreport[l]["Username"] = volunteers[l].username;
        // volunteerreport[l]["Guests Checked-in"] = dashboard.filter(
        //   (item) => item.checkedin_by === volunteers[l].username
        // ).length;
        volunteerreport[l]["Guests Checked-in"] = dashboard.filter(
          (item) => item.checkedin_by_username === volunteers[l].username
        ).length;
        volunteerreport[l]["Role"] = volunteers[l].role;
        volunteerreport[l]["Associated Email"] = volunteers[l].associatedemail;
        volunteerreport[l]["Chapters"] = volunteer_chapters.join(", ")
        volunteerreport[l]["Volunteer Id"] = volunteers[l]._id;
      }

      let today_date = moment(Date.now()).format("MM-D-YY");
      csvDownload(
        volunteerreport,
        `Volunteer_Report_${eventName}_${today_date}.csv`
      );

      console.log("volunteerreport");
      console.log(volunteerreport);

      toast.success(
        <div style={{ fontSize: "16px" }}><b>Volunteer Report Downloaded!</b></div>,
        {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    })
    .catch((err) => {
      console.log(err.response);
      toast.error(
        <div style={{ fontSize: "16px" }}><b>Error Downloading Report!</b></div>,
        {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    });
  }

  return (
    <div>

      <button
        style={{
          width: "100%",
          fontWeight: "bold",
          border: "1px solid darkgray",
        }}
        className="reportButton"
        onClick={() => runVolunteerReport()}
      >
        {props.children || "Download Volunteer Report"}
      </button>
    </div>
  );
};
export default CsvDownload;
