import React from "react";
import Volunteer from "./Volunteer";

const VolunteersPage = () => {
  return (
    <div>
      {" "}
      <h1
        className="eventlist_titles"
        style={{
          textAlign: "center",
          marginTop: 12,
          marginBottom: 15,
        }}
      >
        <b style={{fontWeight:"bold", fontFamily: "Verdana, Geneva, Helvetica" }}>
          Manage your Volunteers
        </b>
      </h1>
      <hr></hr>
      <div style={{ padding: 20, marginTop: 5 }}>
        <Volunteer />
      </div>
    </div>
  );
};

export default VolunteersPage;
