
import React from "react";
import "./Footer.css"
import facebook from "../Assets/facebook_logo1.png";
import facebook2 from "../Assets/facebook_icon1.png";
import twitterlogotransparent from "../Assets/twitterlogotransparent.png";

const Footer = () => {
  return (

        <footer className="footer">
          <div
          style={{
            paddingBottom: 25
          }}></div>
           <div style={{
             display: "flex",
             justifyContent: "center"
             }}>
           <div style={{marginTop: -4}}>
           <a href="https://www.facebook.com/eventsenseiapp">
            <img
                src={facebook}
                width="85"
                height="48"
                alt="line of guests"
                style={{
                  marginBottom: 15,
                }}
              />
            </a>
            </div>
            <div>
            <a href="https://twitter.com/eventsenseiapp">
            <img
                src={twitterlogotransparent}
                width="41"
                height="41"
                alt="line of guests"
                style={{
                }}
              />
            </a>
            </div>
            </div>
          <h4 className="footerheading">Event Sensei - Event Management Solutions</h4>
          <b>Modern Guest Check-In Phone App</b>
          <p style={{marginTop: 8}}>By Outworld Technologies - Chicago, Illinois</p>
          
          <br/> <br/> <br/>
        </footer>
      );
};
export default Footer;



