import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Highlight from "./Highlight";
import "./NavSearch.css";

const NavSearch = () => {
  const reduxState = useSelector((state) => state);
  const history = useHistory();
  const [guestlists, setGuestlists] = useState([]);
  const [guestlistsCopy, setGuestlistsCopy] = useState([]);
  const [detailSearch, setDetailSearch] = useState(false);
  const [searchaga, setSearchaga] = useState("");
  const [eventids, setEventids] = useState([]);

  useEffect(() => {
    console.log("eventids");
    console.log(eventids);

    let theEventids = new Set(eventids);
    console.log("eventidsaga");
    console.log(new Set(eventids));

    // let links = document.getElementsByTagName("div")
    // for(let j = 0; j < links.length; j++){
    //   links[j].innerHTML = "a"
    // }

    //   for(let i = 0; i < theEventids.length; i++){
    //   let links = document.getElementsByClassName(`detailsearchcontainer${theEventids[i]}`)
    //   for(let j = 0; j < links.length; j++){
    //     links[j].innerHTML = "a"
    //   }
    // }

    // for (let i = 0; i < theEventids.length; i++) {
    //   console.log("eventidsaga loop");
    //   console.log(theEventids[i]);
    //   let links = document.getElementById(
    //     `detailsearchcontainer${theEventids[i]}`
    //   );
    //   for (let j = 0; j < links.length; j++) {
    //     links[j].innerHTML = "a";
    //   }
    // }

    let ChangeInput2 = (e) => {

          console.log("onchange query")
          try {
            let blankingarray = []
            let eventcards = document.querySelectorAll(`div[class='eventsContainer']`);
            for (var p = 0; p < eventcards.length; p ++) {
              eventcards[p].style.color = 'black';
            }
            var lastevent = (eventcards[0].innerHTML);
              for (var i = 0; i < eventcards.length; i++) {
              if (eventcards[i+1].innerHTML === (eventcards[i].innerHTML)) {
                blankingarray.push(eventcards[i + 1])
                // eventcards[i + 1].innerHTML = "";
              }

              console.log("blankingarray")
              console.log(blankingarray)

              for (var p = 0; p < blankingarray.length; p ++) {
                blankingarray[p].style.color = 'whitesmoke';
                blankingarray[p].style.borderTop = 'whitesmoke';
                blankingarray[p].setAttribute('id', 'firsteventcard')
              }
          }
          } catch (e) {
            console.log("event labeling error" + e);
          }
      }
 
    ChangeInput2()

  }, [eventids, reduxState.dashboard.dashboard]);

    console.log("render query")

    
    // try {
    //   let eventcards = document.querySelectorAll(`div[class='eventsContainer']`);
    //   var lastevent = (eventcards[0].innerHTML);
    //     for (var i = 0; i < eventcards.length; i++) {
    //     if (eventcards[i+1].innerHTML === (eventcards[i].innerHTML || lastevent)) {
    //       lastevent = eventcards[i].innerHTML;
    //       eventcards[i + 1].innerHTML = "";
    //     }
    // }
    // } catch (e) {
    //   console.log("event labeling error" + e);
    // }

  let ChangeInput = (e) => {
    setSearchaga(e.target.value.toLowerCase());

    // Get names of guests from each list and event id
    console.log("reduxState");
    console.log(reduxState.dashboard.dashboard);

    console.log("reduxState test");
    console.log(
      reduxState.dashboard.dashboard.filter(
        (item) => item._id === "66306f531a83256996895003"
      )
    );

    try {
      let allguests = [];
      let eventids = [];

      console.log("allguests");
      console.log(allguests);

      console.log("eventids");
      console.log(eventids);

      let eventsArray = reduxState.dashboard.dashboard;

      console.log("eventsArray");
      console.log(eventsArray);

      for (var i = 0; i < eventsArray.length; i++) {
        allguests = [
          ...allguests,
          ...eventsArray[i].lists.map((item) => item.list),
        ];

        let listlength = eventsArray[i].lists.map((item) => item.list).length;
        for (var j = 0; j < listlength; j++) {
          eventids.push(eventsArray[i]._id);
          setEventids(eventids);
        }
      }

      let eventsArrayNames = [];
      let eventsArrayName = reduxState.dashboard.dashboard;
      for (var i = 0; i < eventsArrayName.length; i++) {
        let listlength = eventsArrayName[i].lists.map(
          (item) => item.list
        ).length;
        for (var j = 0; j < listlength; j++) {
          eventsArrayNames.push(eventsArrayName[i].name);
        }
      }

      // Get ids of guests from each list
      let allguestids = [];
      console.log("reduxState");
      console.log(reduxState.dashboard.dashboard);
      for (let i = 0; i < eventsArray.length; i++) {
        allguestids = [
          ...allguestids,
          ...eventsArray[i].lists.map((item) => item._id),
        ];
      }

      // Get Emails of guests from each list
      let allguestemails = [];
      console.log("reduxState");
      console.log(reduxState.dashboard.dashboard);
      for (let i = 0; i < eventsArray.length; i++) {
        allguestemails = [
          ...allguestemails,
          ...eventsArray[i].lists.map((item) => item.email),
        ];
      }

      // Get Phones of guests from each list
      let allguestphones = [];
      console.log("reduxState");
      console.log(reduxState.dashboard.dashboard);
      for (let i = 0; i < eventsArray.length; i++) {
        allguestphones = [
          ...allguestphones,
          ...eventsArray[i].lists.map((item) => item.phone),
        ];
      }

      // Get Organizations of guests from each list
      let allguestorganizations = [];
      console.log("reduxState");
      console.log(reduxState.dashboard.dashboard);
      for (let i = 0; i < eventsArray.length; i++) {
        allguestorganizations = [
          ...allguestorganizations,
          ...eventsArray[i].lists.map((item) => item.organization),
        ];
      }

      // Get Ids of all accountids from each list
      let allguestaccountids = [];
      console.log("reduxState");
      console.log(reduxState.dashboard.dashboard);
      for (let i = 0; i < eventsArray.length; i++) {
        allguestaccountids = [
          ...allguestaccountids,
          ...eventsArray[i].lists.map((item) => item.account_id),
        ];
      }

      let listallguests = [];

      for (let i = 0; i < allguests.length; i++) {
        let allkeys =
          allguestemails[i] +
          allguestphones[i] +
          allguestorganizations[i] +
          allguestaccountids[i] +
          allguests[i];
        let allsoup = allkeys.toLowerCase();
        listallguests.push({
          eventname: eventsArrayNames[i],
          event: eventids[i],
          guest: allguestids[i],
          label: allguestaccountids[i] + " - " + allguests[i],
          value: allsoup,
          phone: allguestphones[i],
          email: allguestemails[i],
          organization: allguestorganizations[i],
        });
      }

      console.log("listallguestssss");
      console.log(listallguests);

      setGuestlists(listallguests);
      setGuestlistsCopy(listallguests);
    } catch {
      console.log("Search error");
    }

    if (e.target.value.length === 0) {
      setDetailSearch(false);
    } else if (e.target.value.length > 0) {
      setDetailSearch(true);
    } else {
      setDetailSearch(true);
    }

    console.log(e.target.value.toLowerCase());
    if (
      guestlists.filter((item) =>
        item.value.includes(e.target.value.toLowerCase())
      )
    ) {
      let filtered_guest_search = guestlists.filter((item) =>
        item.value.includes(e.target.value.toLowerCase())
      );
      // setGuestlistsCopy(filtered_guest_search.slice(0, 4));
      setGuestlistsCopy(filtered_guest_search);
    } else {
      setGuestlistsCopy(guestlists);
    }
  };

  // let ChangeInput2 = (e) => {

  //   setGuestlistsCopy(guestlists);
  //       console.log("onchange query")
  //       try {
  //         let eventcards = document.querySelectorAll(`div[class='eventsContainer']`);
  //         var lastevent = (eventcards[0].innerHTML);
  //           for (var i = 0; i < eventcards.length; i++) {
  //           if (eventcards[i+1].innerHTML === (eventcards[i].innerHTML || lastevent)) {
  //             lastevent = eventcards[i].innerHTML;
  //             eventcards[i + 1].innerHTML = "";
  //           }
  //       }
  //       } catch (e) {
  //         console.log("event labeling error" + e);
  //       }
  //   }

  // ChangeInput2()

  return (
    <div>
      <div
        style={{
          cursor: "pointer",
          borderRadius: 3,
          marginTop: 0,
          paddingTop: 5,
          marginLeft: 20,
        }}
      >
        <input
          className="navsearchInput"
          onBlur={()=>{
            setTimeout(() => {
              setDetailSearch(false)
            }, 200);
          }}
          onChange={ChangeInput}
          style={{
            minInlineSize: 200,
            height: 40,
            borderRadius: 20,
            padding: 12,
            width: 260,
            paddingLeft: 14,
            marginTop: -5,
          }}
          placeholder="Search Guest"
          name="searchinput"
          type="input"
        />

        {detailSearch ? (
          <div className="navsearch-results-displayed">
            {guestlistsCopy.map((item) => (
              <div>
                <div style={{ display: "flex" }}>
                  <div
                    onClick={() => {
                      history.push(`/events/${item.event}`);
                    }}
                    className="eventsContainer"
                    style={{
                      background: "whitesmoke",
                      cursor: "pointer",
                      borderTop: ".9px solid gainsboro",
                      width: 300,
                    }}
                  >
                    <div
                      style={{
                        margin: 5,
                        fontWeight: "bold",
                        // color: "#505050",
                        cursor: "pointer",
                      }}
                    >
                      <label style={{ cursor: "pointer" }}>
                        {item.eventname}
                      </label>
                    </div>
                  </div>

                  {/* Outer Container Guest Cards */}
                  <div
                    style={{
                      className: "outercardquery",
                      width: "auto",
                      maxWidth: 300,
                      borderLeft: ".1px solid gainsboro",
                    }}
                  >
                    {/* Inner Guest Card with 3 Details - Name, Address, and Phone */}
                    <div
                      className="detailsearchcontainer"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <div
                        style={{
                          paddingTop: 1,
                          paddingBottom: 2,
                          paddingRight: 4,
                          // minWidth: "200px",
                        }}
                        onClick={() => {
                          console.log("div pressed");
                          history.push(`/guests/${item.event}/${item.guest}`);
                        }}
                        className="detailsearch"
                      >
                        <div
                          style={{ paddingLeft: 5, marginBottom: -2 }}
                          onClick={() => {
                            console.log("div pressed");
                            history.push(`/guests/${item.event}/${item.guest}`);
                          }}
                        >
                          <Highlight
                            text={`${item.label}`}
                            searchTerm={searchaga}
                          />
                          <br />
                        </div>

                        <div
                          style={{ paddingLeft: 6, fontSize: 13 }}
                          onClick={() => {
                            console.log("div pressed");
                            history.push(`/guests/${item.event}/${item.guest}`);
                          }}
                        >
                          <Highlight
                            text={`${item.email}`}
                            searchTerm={searchaga}
                          />
                          <br />
                        </div>
                        {item.phone ? (
                          <div
                            style={{
                              paddingLeft: 6,
                              fontSize: 13,
                            }}
                            onClick={() => {
                              console.log("div pressed");
                              history.push(
                                `/guests/${item.event}/${item.guest}`
                              );
                            }}
                          >
                            <Highlight
                              text={`${item.phone}`}
                              searchTerm={searchaga}
                            />
                            <br />
                          </div>
                        ) : null}
                        {/* <div>{item.email}</div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default NavSearch;
