import React, { useState, useEffect } from "react";
import "./DashbboardList.css";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
// import { isEmpty } from "../../../utils/Validation";
import * as actions from "../../../store/actions/index";
import ImportCsv from "./ImportCsv";
import { ToastContainer, toast } from "react-toastify";
const DashboardLists = (props) => {
  const [listItem, setListItem] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [guests, setGuests] = useState([]);
  const [lodaded, setLoaded] = useState();
  // const [reduxloaded, setReduxLoaded] = useState()

  const accountDetails = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("accountDetails");
    console.log(accountDetails);
    console.log("props.match.params");
    // console.log(props.match.params.eventId)

    axios
      .get("/api/dashboard/")
      .then((res) => {
        console.log(res.data.dashboard);
        console.log(
          res.data.dashboard.filter(
            (event) => event._id == "6593edba48dea30014fd9cdc"
          )[0]
        );
        setLoaded(
          res.data.dashboard.filter(
            (event) => event._id == props.match.params.eventId
          )[0]
        );
      })
      .catch((err) => {
        console.log(err.response);
      });
  }, []);

  const keys = [
    "listItem",
    "email",
    "phone",
    "tickettype",
    "guest_details",
    "organization",
    "table_row_number",
    "seat_number",
  ];

  const handleImportPost = (items) => {
    // Toast Success Alert. Import
    toast.success(
      <div style={{ fontSize: "16px" }}>
        <b>File Successfully Imported!</b>
      </div>,
      {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        importdata: {},
      }
    );

    dispatch(actions.importList(props.match.params.eventId, items));

    // const MINUTE_MS = 5000;
    // setTimeout(() => {
    //   this.props.clearDashmessage();
    //   console.log("this.props.dashboards.dashMessage !==")
    //   console.log(this.props.dashboards.dashMessage)
    // }, MINUTE_MS);
  };


  const handleImportUpdate = (items) => {
    // Toast Success Alert. Import
    toast.success(
      <div style={{ fontSize: "16px" }}>
        <b>File Successfully Imported!</b>
      </div>,
      {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        importdata: {},
      }
    );

    dispatch(actions.importupdate(props.match.params.eventId, items));

    // const MINUTE_MS = 5000;
    // setTimeout(() => {
    //   this.props.clearDashmessage();
    //   console.log("this.props.dashboards.dashMessage !==")
    //   console.log(this.props.dashboards.dashMessage)
    // }, MINUTE_MS);
  };

  return (
    <div>
      <ToastContainer
        position="top-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <ImportCsv
        eventChosen={props.match.params.eventId}
        keys={keys}
        handleImportPost={handleImportPost}
        handleImportUpdate={handleImportUpdate}
        // onError={handleError}
        // listItem={listItem}
        
        render={(onChange) => (
          // <div
          // style={{
          //   height: 40,
          //   borderRadius: 5,
          //   fontWeight: "bold",
          //   marginBottom: 40,
          //   width: 300,
          //   fontSize: 14,
          //   background: "gainsboro",
          // }}
          // className="fileUpload btn upload"
          // // className="fileUpload btn btn-primary"
          // >
          <div>
            <label style={{fontFamily: "Verdana"}} for="file-upload" class="custom-file-upload">
            Upload Spreadsheet 
        </label>
              <input
                id="file-upload"
                className=""
                type="file"
                style={{
                  padding: 200,
                  background: "red",
                  textDecoration: "none",
                  fontSize: 50,
                  display: "none",
                  width: 200
                }}
                onChange={onChange}
              />
          </div>
          // </div>
        )}
      />
    </div>
  );
};

export default DashboardLists;
