import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as actions from "../../store/actions/index";
import moment from "moment";
import DeleteAPIKey from "./DeleteApiKey";

const ApiAccess = (props) => {
  const reduxState = useSelector((state) => state);
  const dispatch = useDispatch();
//   const history = useHistory();

  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const [deletingId, setDeletingId] = useState("");

  const [formData, setFormData] = useState({
    apikeyname: "",
  });

  useEffect(() => {
    console.log("reduxStated");
    console.log(reduxState);
  }, [reduxState])
  

  const [addApiKeyModalShow, setAddApiKeyModalShow] = useState(false);

  const onHide = () => {
    setAddApiKeyModalShow(false);
  }

  let deleteModalClose = () => setDeleteModalShow(false);

  const onAPIChange = (e) => {
      console.log(e.target.value)
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  const initializeDelete = (id) =>{
    setDeletingId(id)
    setDeleteModalShow(true)
  }

  const deleteapikey = () =>{
    dispatch(actions.deleteAPIKey(deletingId));
  }
  
  const generateAPIKey = (e) => {
      console.log("frontend call")
      console.log(formData.apikeyname)
dispatch(actions.createAPIKey(formData.apikeyname));
setAddApiKeyModalShow(false);
  }

  let headers = ["API Name", "API Key", "Expires"];

  let volunteerList = (
    <div>
      {reduxState.auth.user.api_access.length === 0 ? 
                          <div style={{ textAlign: "center", padding: 15 }}>
                          <h2 style={{ marginLeft: 20 }} className="noevents">
                              No API Keys Created...
                          </h2>
                      </div> : 
      <div>
      <table>
        <thead>
          <tr className="prTableRow" style={{ color: "#404040" }}>
            {headers.map((header) => (
              <th
                key={header}
                style={{
                  minWidth: 65,
                  // color: "#484848",
                  color: "#FFFFFF",
                  background: "#404040",
                  paddingTop: 17,
                  paddingLeft: 5,
                  fontFamily: "Verdana",
                  border: ".9px solid darkgray",
                }}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* {associatedUsers.map((item) => ( */}
          {reduxState.auth.user.api_access.map((item) => (
          <tr
            //   key={item._id}
            //   onClick={(e) => editVolunteer(item._id)}
            className="prTableRow"
            style={{ color: "#404040" }}
          >
            <td
              key={item.api_name}
              className="chapterTableCell chapters_field"
              style={{
                borderTop: ".9px solid darkgray",
                borderBottom: ".9px solid darkgray",
                fontSize: "13px",
                paddingRight: 18,
                paddingTop: 18,
                paddingBottom: 18,
                paddingLeft: 10,
                border: ".9px solid darkgray",
                fontFamily:
                  "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                // background: item.status === "Checked In" ? "#b9ecdb" : null,
              }}
            >
              {item.api_name}{" "}
            </td>
            <td
              key={item.api_key}
              className="chapterTableCell chapters_field"
              style={{
                borderTop: ".9px solid darkgray",
                borderBottom: ".9px solid darkgray",
                fontSize: "13px",
                paddingRight: 18,
                paddingTop: 18,
                paddingBottom: 18,
                paddingLeft: 10,
                border: ".9px solid darkgray",
                fontFamily:
                  "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                // background: item.status === "Checked In" ? "#b9ecdb" : null,
              }}
            >
              {item.api_key}{" "}
            </td>

            <td
              key={item.api_expirationdate}
              className="chapterTableCell chapters_field"
              style={{
                borderTop: ".9px solid darkgray",
                borderBottom: ".9px solid darkgray",
                fontSize: "13px",
                paddingRight: 18,
                paddingTop: 18,
                paddingBottom: 18,
                paddingLeft: 10,
                border: ".9px solid darkgray",
                fontFamily:
                  "Verdana, Arial, Tahoma, Garamond, Trebuchet, Droid Sans",
                // background: item.status === "Checked In" ? "#b9ecdb" : null,
              }}
            >
              {moment(item.api_expirationdate).format("MM/D/YYYY")}
            </td>
            {/* marginBottom is increasing height of table cells here */}
            <td 
            key={item._id}
            >
                  {/* marginBottom is increasing height of table cells here */}
                  <div>
                    <i
                      style={{ fontSize: 20 }}
                      className="fas fa-remove fa-xl edit_volunteers_remove"
                      onClick={(e) => {
                        console.log("hi")
                        console.log(item._id)
                        initializeDelete(item._id);
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    ></i>
                  </div>
                </td>
          </tr>
          ))}
        </tbody>
      </table>
      </div>
      }
    </div>
  );

  return (
    <div>
      <div
        className="verifyemailcontainer"
        style={{
          border: "1px solid darkgray",
          padding: 20,
          paddingLeft: 24,
          borderRadius: 10,
          paddingBottom: 24,
        }}
      >
        <h3
          className="deleteaccounttitle"
          style={{
            marginTop: "1%",
            color: "#505050",
            fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
          }}
        >
          <b>API Access Credentials</b>
        </h3>

        <button
          className="verifybutton"
          onClick={()=>setAddApiKeyModalShow(true)}

          // disabled={this.state.verifyEmailButton}
          style={{
            marginLeft: "1%",
            marginTop: 15,
          }}
        >
          <b
            style={{
              fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
            }}
          >
            Create API Key
          </b>
        </button>

        {volunteerList}
      </div>


      <Modal
          {...props}
          show={addApiKeyModalShow}
          onHide={onHide}
          backdrop="static"
          keyboard={false}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                  <b color="#909090">API Key Generation</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div style={{ fontFamily: "Verdana, Geneva, Helvetica" }}>
                <form
                generateAPIKey={generateAPIKey}
                >
                  <div>
                    <div className="form-group">
                      <label htmlFor="name">
                        <b>API Key Name</b>
                      </label>
                      <input
                        id="apikeyname"
                        type="text"
                        name="apikeyname"
                        className="form-control form-control-md"
                        style={{
                          flex: "8",
                          padding: "5px",
                          border: ".5px solid black",
                        }}
                        placeholder="API Key Name..."
                        // defaultValue={formData.name}
                        onChange={onAPIChange}
                      />
                    </div>
                  </div>
                </form>
              </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              style={{
                fontFamily: "Verdana, Geneva, Helvetica",
              }}
              variant="success"
              onClick={generateAPIKey}
            >
              Submit
            </Button>
            <Button
              style={{
                fontFamily: "Verdana, Geneva, Helvetica",
              }}
              variant="danger"
              onClick={onHide}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <DeleteAPIKey
            show={deleteModalShow}
            // id={activeItemId}
            onHide={deleteModalClose}
            deletingid={deletingId}
            deleteapikey={deleteapikey}
            />

    </div>
  );
};

export default ApiAccess;
