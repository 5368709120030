

import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import styled from "@emotion/styled";
import axios from "axios";
import Row from "./prebuilt/Row";
import BillingDetailsFields from "./prebuilt/BillingDetailsFields";
import SubmitButton from "./prebuilt/SubmitButton";
import CheckoutError from "./prebuilt/CheckoutError";
import { useDispatch, useSelector } from "react-redux"
import * as action from '../../store/actions/index'

const CardElementcontainer = styled.div`
  height: 40px;
  display: flex;
  background: #F5F5F5;

  align-items: center;
  & .StripeElement {
    width: 100%;
    padding: 15px;
  }`;

  const Formcontainer = styled.div`
  width: 50%;
margin: auto;
  @media screen and (max-width: 900px) {
    width: 100%;
    margin: auto;
  }
  
  @media screen and (max-width: 500px) {
    width: 100%;
    margin: auto;
  }
    `;



// const CheckoutForm = ({ price, onSuccessfulCheckout }) => {
  const CheckoutForm = ({ onSuccessfulCheckout }) => {
  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();
  const [price] = useState("150");

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch()

  useEffect(() => {
  // dispatch(action.edityDashboard())
}, [])
  // TIP
  // use the cardElements onChange prop to add a handler
  // for setting any errors:

  const handleCardDetailsChange = ev => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };

  const handleFormSubmit = async ev => {
    ev.preventDefault();

    const billingDetails = {
      name: ev.target.name.value,
      email: ev.target.email.value,
      phone: ev.target.phone.value,
      address: {
        city: ev.target.city.value,
        line1: ev.target.address.value,
        state: ev.target.state.value,
        postal_code: ev.target.zip.value
      }
    };

    //Create Payment Intent on Server- (Client Secret returned from Server)

    setProcessingTo(true);

    const cardElement = elements.getElement("card");

    try {

 

      const paymentMethodReq = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          email: billingDetails.email,
        },
      }).then(console.log("Then"))
      .catch(err => console.log(err));


      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);

        console.log(client_secret)
        return;
      }

      const res  = await axios.post("api/sub", {
        // const { data: client_secret }  = await axios.post("api/sub", {
        'payment_method': paymentMethodReq.paymentMethod.id, 'email': billingDetails.email
        } ).then(console.log("Then"))
        .catch(err => console.log(err)
        );
  
 
        const {client_secret} = res.data;
        // const {client_secret, status} = res.data;

      const { error } = await stripe.confirmCardPayment(client_secret, {
        // payment_method: paymentMethodReq.paymentMethod.id

        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
              name: billingDetails.name,
              email: billingDetails.email,
              phone: billingDetails.phone,
              address: billingDetails.address
          }
      }
      }).then(console.log("Processing..."))
      .catch(err => console.log(err));;

      if (!error) {
 
        setCheckoutError(error.message);
        setProcessingTo(false);
        console.log("Transaction UnSuccessful!")
        // throw error
        return;
      }
      // onSuccessfulCheckout();
      setProcessingTo(false);
       console.log(client_secret)
       console.log("Transaction Successful!")
       setCheckoutError("Transaction Successful");
       dispatch(action.edityDashboard())
    } catch (err) {
      // console.log(err);
      setCheckoutError("Your Card Was Declined");
      setProcessingTo(false);
  }
  };

  // Learning
  // A common ask/bug that users run into is:
  // How do you change the color of the card element input text?
  // How do you change the font-size of the card element input text?
  // How do you change the placeholder color?
  // The answer to all of the above is to use the `style` option.
  // It's common to hear users confused why the card element appears impervious
  // to all their styles. No matter what classes they add to the parent element
  // nothing within the card element seems to change. The reason for this is that
  // the card element is housed within an iframe and:
  // > styles do not cascade from a parent window down into its iframes

  const iframeStyles = {
    base: {
      color: "black",
      fontSize: "16px",
      iconColor: "black",
      "::placeholder": {
        color: "darkgray"
      }
    },
    invalid: {
      iconColor: "#FFC7EE",
      color: "#FFC7EE"
    },
    complete: {
      iconColor: "#cbf4c9"
    }
  };

  const cardElementOpts = {
    iconStyle: "solid",
    style: iframeStyles,
    hidePostalCode: true,
  };


  return (
    <Formcontainer>
    <form onSubmit={handleFormSubmit}>

      <Row>
        <BillingDetailsFields />
      </Row>
      <Row>
        <CardElementcontainer>
          <CardElement
            options={cardElementOpts}
            onChange={handleCardDetailsChange}
          />
        </CardElementcontainer>
      </Row>
      {checkoutError && <CheckoutError>{checkoutError}</CheckoutError>}
      <Row>
        {/* TIP always disable your submit button while processing payments */}
        <SubmitButton disabled={isProcessing || !stripe}>
          {isProcessing ? "Processing..." : `Pay $${price}`}
          {/* {isProcessing ? "Processing..." : `Pay $10/Month`} */}
        </SubmitButton>
      </Row>

    </form>
    </Formcontainer>
  );
};

export default CheckoutForm;
