import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Processor from './Processor'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'

const stripePromise = loadStripe('pk_test_51I2P5DAZzCAMTEUQBQVU4xfRDLMkBD6aUUD1LVQX8RckfEZDIQgKSgaqIT9t3T4z5InPXqyhrKmak9p5EgMAJklk00aAnRo80c');

class PaymentsParent extends Component {

  render() {
  let authRedirect = null
  if(!this.props.isAuthenticated){
    authRedirect = <Redirect to='/' />
  }
  
  
  return (


<div className='container'>

{this.props.user.role === "user" || "admin" ?

<div className="jumbotron">
{authRedirect}


<div style={{textAlign: "center", fontFamily: "Verdana"}}>
<h1 style={{fontFamily: "Verdana"}}>Start a Monthly Subscription.</h1>
<h1>$199/Month</h1>
</div>
<Elements stripe={stripePromise}>
  <Processor />
</Elements>
</div>

:

<div className="jumbotron">
  {authRedirect}

<div  style={{textAlign:"center"}}>
  <h1>This is a Volunteer Account!</h1>
  <h3>Only Admin Accounts can Make Payments.</h3>
  <br/>
  <h3>Use your admin email <b>{this.props.user.associatedemail} </b>
  to manage your account settings or unlink this account in order to manage
  your own events using the current email you are using, <b>{this.props.user.email}</b>.</h3>
</div>

</div>

}

</div>

  );
};

}

const mapStateToProps = (state, ownProps) => {
  return {
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user
}}

export default connect(mapStateToProps)(PaymentsParent)