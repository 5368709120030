import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export class DelGuestModal extends Component {
  onSubmit = (e) => {
    // e.preventDefault();
    let dashId = this.props.dashId.toString();
    let id = this.props.id.toString();
    this.props.deleteGuest(dashId, id);

    toast.warning(
      <div style={{ fontSize: "16px" }}>
        <b>Guest Deleted!</b>
      </div>,
      {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );
  };

  render() {
    let onSubmit = this.onSubmit.bind(this);

    return (
      <div>
        {" "}
        <ToastContainer
          position="top-left"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Delete Guest
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <form style={{ display: "flex" }}>
                <p>
                  <b>Are you Sure you would like to Delete this Guest?</b>
                  <br />
                  (This Action cannot be undone.)
                </p>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={onSubmit}>
              Confirm
            </Button>
            <Button variant="danger" onClick={this.props.onHide}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default DelGuestModal;
