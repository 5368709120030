

import * as actionType from  './actionTypes'
import setAuthToken from '../../utils/setAuthToken'
import jwt_decode from 'jwt-decode'
import {createLog} from '../../utils/Validation'
import axios from 'axios'

export const registerUser = (userData, history) => dispatch =>{
  axios.post('/api/users/register/', userData)
    .then(res => {
      dispatch(authSuccess(history, res.data))
    })
    .catch(err=> {
      dispatch({
        type: actionType.AUTH_ERROR,
        payload: err.response.data
      })
    })
  }

    export const registerAdminUser = (userData) => dispatch =>{
      axios.post('/api/users/adminregister/', userData)
       .then(res => {
          dispatch(authRoleSuccess(res.data))
        })
        .catch(err=> {
          console.log("redux error")
          console.log(err)

        })
      }


  
export const authSuccess = (history, data) => {
  history.push('/login/')
  return {
    type: actionType.AUTH_REGISTER_SUCCESS,
    payload: {
      mesg: data.mesg,
      type: data.type
    }
  }
}

export const auth_message_clear = () => dispatch => {
  dispatch({
    type: actionType.AUTH_MESSAGE_CLEAR
  })
}

export const authRoleSuccess = (data) => {
  return {
    type: actionType.AUTH_REGISTER_SUCCESS,
    payload: {
      mesg: data.mesg,
      type: data.type
    }
  }
}

export const onloading = () => {
  return {
    type: actionType.LOADING
  }
}

export const loginUser = (userData, history) => dispatch => {

  axios.post('/api/users/login/', userData)
    .then(res => {
      // set response data to a token
      const {token} = res.data;
      // save token to localstorage
      localStorage.setItem('jwtToken', token)
      // set token in the request header
      setAuthToken(token)
      // decode the token to extract user id
      const decode = jwt_decode(token)
      // dispatch to create save the token in the state
      // console.log(decode)
      dispatch(setCurrentUser(decode))
    })
    .catch(err => {
      
      dispatch({
        type: actionType.AUTH_ERROR,
        payload: err.response.data
      })
    })
  return {
    type: actionType.LOADING
  }
}

export const masterloginUser = (userData, history) => dispatch => {
  console.log("Redux hit")
  console.log(userData)

  // Bottom Line to remove jwt token is not necessary
  // localStorage.removeItem('jwtToken')

  axios.post('/api/users/masterlogin/', userData)
    .then(res => {
      // set response data to a token
      const {token} = res.data;
      // save token to localstorage
      localStorage.setItem('jwtToken', token)
      // set token in the request header
      setAuthToken(token)
      // decode the token to extract user id
      const decode = jwt_decode(token)
      // dispatch to create save the token in the state
      // console.log(decode)
      dispatch(setCurrentUser(decode))
    })
    .catch(err => {
      
      dispatch({
        type: actionType.AUTH_ERROR,
        payload: err.response.data
      })
    })
  return {
    type: actionType.LOADING
  }
}

export const setCurrentUser = (decoded)=> {
  return {
    type: actionType.AUTH_SET_CURRENT_USER,
    payload: decoded
  }
}



export const logoutUser = () => {
  localStorage.removeItem('jwtToken')
  return {
    type: actionType.AUTH_USER_LOGOUT
  }
}

export const getUser = () => dispatch =>{
  const user = createLog()
  axios.post('/api/users/getUsers/', {user: user})
    .then(res => {
      dispatch({
        type: actionType.USER_DATA
      })
    })
    .catch(err=> {
      dispatch({
        type: actionType.USER_DATA
      })
    })
}



export const edityDashboard = () => dispatch => {
  axios.put('/api/users/updatesubscription/')
    .then(res =>{
      dispatch(setUserState(res.data))
      console.log(res)
    })  
    .catch(err => {
      console.log(err.response)

    })
}


export const editProfile = (userData) => dispatch =>{
  axios.put('/api/users/editprofile/', userData)
   .then(res => {
      dispatch(setUserState(res.data))
    })
    .catch(err=> {
      console.log(err)
      // dispatch({
      //   type: actionType.AUTH_ERROR,
      //   payload: err.response.data
      // })
    })
  }

  export const verifyEmail = (userData) => dispatch =>{
    axios.put('/api/users/editprofile/', userData)
     .then(res => {
        dispatch(setUserState(res.data))
      })
      .catch(err=> {
        console.log(err)
        // dispatch({
        //   type: actionType.AUTH_ERROR,
        //   payload: err.response.data
        // })
      })
    }



  export const editVolunteer = (newUser) => dispatch =>{
    axios.put('/api/users/editvolunteer/', newUser)

     .then(res => {
        dispatch(setUserState(res.data))
      })
      .catch(err=> {
        console.log(err)
        dispatch({
          type: actionType.AUTH_ERROR,
          payload: err.response.data
        })
      })
    }


export const volunteerdelete = (id) => dispatch =>{
  axios.put('/api/users/volunteerdelete/', {id: id})
   .then(res => {
    console.log("redux reponse")
      console.log(res)
      console.log("redux id")
      console.log(id)
    })
    .catch(err=> {
      console.log(err)
      // dispatch({
      //   type: actionType.AUTH_ERROR,
      //   payload: err.response.data
      // })
    })
  }

  export const updateDashboard = (urllink) => dispatch => {
    axios.put('/api/users/profilepic/', {url: urllink})
      .then(res =>{
        dispatch(setUserState(res.data))
        console.log("res")
        console.log(res)
      })  
      .catch(err => {
        console.log(err.response)
  
      })
  }

  export const updateDashboard2 = (urllink) => dispatch => {
    axios.put('/api/users/profilepicdelete/', {url:"https://res.cloudinary.com/outworld-tech/image/upload/v1628575608/PngItem_5578368_hbfckl.png"})
      .then(res =>{
        dispatch(setUserState(res.data))
        console.log("res")
        console.log(urllink)
        console.log(res)
      })  
      .catch(err => {
        console.log(err.response)
  
      })
  }


export const setUserState = (data) => {
  return {
    type: actionType.AUTH_UPDATE,
    payload: {
      user: data.user
    }
  }
}

// export const setUserState2 = (data) => {
//   console.log("data")
//   console.log(data)
//   return {
//     type: actionType.AUTH_UPDATE,
//     payload: data
//   }
// }

export const fetchUser = () => dispatch =>{
  axios.get('/api/users/')
    .then(res => {
      dispatch(setUserState(res.data))

    })
    .catch(err => {
      console.log(err.response)
    })
}

export const fetchAdmins = () => dispatch =>{
  axios.get('/api/users/getadmins')
    .then(res => {
      dispatch(setUserState(res.data))

    })
    .catch(err => {
      console.log(err.response)
    })
}


export const postVolunteers = (formData) => dispatch =>{
  axios.post('/api/users/createchapters', formData)
   .then(res => {
    console.log("Redux runn")
    console.log(res.data)
      dispatch(setUserState(res.data))
    })
    .catch(err=> {
      console.log("Redux error")
      console.log(err)
      // dispatch({
      //   type: actionType.AUTH_ERROR,
      //   payload: err.response.data
      // })
    })
  }

  export const deleteChapters = (dashId) => dispatch =>{
    axios.delete('/api/users/deletechapter/', {params: { deleteId: dashId }})
     .then(res => {
      console.log("Redux runn")
      console.log(res.data)
      console.log(res);
      console.log("delete chapters");
        dispatch(setUserState(res.data))
      })
      .catch(err=> {
        console.log("Redux error")
        console.log(err)
        dispatch({
          type: actionType.AUTH_ERROR,
          payload: err.response.data
        })
      })
    }

    export const editChapters = (formData) => dispatch =>{
      axios.put('/api/users/editchapters/', formData)
       .then(res => {
        console.log("Redux edit runn")
        console.log(res.data)
        console.log(res);
        console.log("edit chapters");
          dispatch(setUserState(res.data))
        })
        .catch(err=> {
          console.log("Redux error")
          console.log(err)
          dispatch({
            type: actionType.AUTH_ERROR,
            payload: err.response.data
          })
        })
      }

      export const createAPIKey = (apikeyname) => dispatch =>{
        axios.post('/api/users/createapikey/', {apikeyname: apikeyname})
         .then(res => {
          console.log("Redux run API Key")
          console.log("apikeyname")
          console.log(apikeyname);
          console.log(res.data)
          console.log(res);
          console.log("Add API Key");
            dispatch(setUserState(res.data))
          })
          .catch(err=> {
            console.log("Redux error")
            console.log(err)
            dispatch({
              type: actionType.AUTH_ERROR,
              payload: err.response.data
            })
          })
        }

        export const deleteAPIKey = (dashId) => dispatch =>{
          axios.delete('/api/users/deleteapikey/', {params: { deleteId: dashId }})
           .then(res => {
            console.log("Redux runn")
            console.log(res.data)
            console.log(res);
            console.log("delete chapters");
              dispatch(setUserState(res.data))
            })
            .catch(err=> {
              console.log("Redux error")
              console.log(err)
              dispatch({
                type: actionType.AUTH_ERROR,
                payload: err.response.data
              })
            })
          }

          export const addEmailSettings = (eventData) => dispatch =>{
            axios.post('/api/users/add_email_settings/', eventData)
             .then(res => {
              console.log("Redux runn mail settings")
              console.log(res.data)
              console.log(res);
              console.log("delete chapters");
                dispatch(setUserState(res.data))
              })
              .catch(err=> {
                console.log("Redux error")
                console.log(err)
                dispatch({
                  type: actionType.AUTH_ERROR,
                  payload: err.response.data
                })
              })
            }

          export const deleteEmailSettings = (dashId) => dispatch =>{
            axios.put('/api/users/delete_email_settings/')
             .then(res => {
              console.log("Redux runn mail settings")
              console.log(res.data)
              console.log(res);
              console.log("delete chapters");
                dispatch(setUserState(res.data))
              })
              .catch(err=> {
                console.log("Redux error")
                console.log(err)
                dispatch({
                  type: actionType.AUTH_ERROR,
                  payload: err.response.data
                })
              })
            }
    
