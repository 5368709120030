import React, { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import GuestTicket from "./guestTicket";
import {Link, Redirect, useHistory} from 'react-router-dom';
import "./EditGuestModal.css";

const EditGuestModal = (props) => {

  const history = useHistory()

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    tickettype: "",
    guest_details: "",
    organization: "",
    table_row_number: "",
    seat_number: "",
  });

  const [currentValue, setCurrentValue] = useState("");
  const [viewTicket, setViewTicket] = useState(false);
  const [printedTicket, setPrintedTicket] = useState(false);

  useEffect(() => {
    setFormData({
      name: props.list,
      email: props.email,
      phone: props.phone,
      tickettype: props.tickettype,
      guest_details: props.guest_details,
      organization: props.organization,
      table_row_number: props.table_row_number,
      seat_number: props.seat_number
    });
  }, [])
  

  //Single Event Handler Approach:
  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    props.editDashBoard(
      props.dashId,
      props.id,
      formData.name,
      formData.email,
      formData.phone,
      formData.tickettype,
      formData.guest_details,
      formData.organization,
      formData.table_row_number,
      formData.seat_number
    );
    props.onHide();

    toast.success(
      <div style={{ fontSize: "16px" }}>
        <b>Guest Edited!</b>
      </div>,
      {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      }
    );
  };

  const closeEditModal = () => {
    props.onHide();
    setViewTicket(false);
  };

  const printDocument = () => {
    const input = document.getElementById("divToPrint");

    try {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        pdf.addImage(imgData, "JPEG", 0, 0);
        // pdf.output('dataurlnewwindow');
        pdf.save("ticket_guest_eventsensei.pdf");
      });

      toast.success(
        <div style={{ fontSize: "16px" }}>
          <b>Guest ticket downloaded!.</b>
        </div>,
        {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    } catch {
      toast.error(
        <div style={{ fontSize: "16px" }}>
          <b>An error occured downloading guest ticket.</b>
        </div>,
        {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  };

  const guestprofile = () => {
    //Redirect to another route
    history.push(`/guests/${props.dashId}/${props.id}`) 
   }

  return (
    <div style={{fontFamily:"Verdana, Geneva, Helvetica"}}>
      <ToastContainer
        position="top-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <b>Edit Guest</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!viewTicket ? (
            <div>
              <form onSubmit={onSubmit}>
                <div>
                  <div className="form-group">
                    <label for="name">
                      <b>Name</b>
                    </label>
                    <input
                      id="name"
                      type="text"
                      name="name"
                      className="form-control form-control-md"
                      style={{
                        flex: "8",
                        padding: "5px",
                        border: ".5px solid black",
                      }}
                      placeholder="Name..."
                      defaultValue={props.list}
                      onChange={onChange}
                    />
                  </div>
                  <div className="form-group">
                    <label for="organization">
                      <b>Organization</b>
                    </label>
                    <input
                      id="organization"
                      type="text"
                      name="organization"
                      className="form-control form-control-md"
                      style={{
                        flex: "8",
                        padding: "5px",
                        border: ".5px solid black",
                      }}
                      placeholder="Organization..."
                      defaultValue={props.organization}
                      onChange={onChange}
                    />
                  </div>
                  <div className="form-group">
                    <label for="email">
                      <b>Email </b>
                    </label>
                    <input
                      id="email"
                      type="text"
                      name="email"
                      className="form-control form-control-md"
                      style={{
                        flex: "8",
                        padding: "5px",
                        border: ".5px solid black",
                      }}
                      placeholder="Email..."
                      defaultValue={props.email}
                      onChange={onChange}
                    />
                  </div>

                  <div className="form-group">
                    <label for="phone">
                      <b>Phone</b>
                    </label>
                    <input
                      id="phone"
                      type="text"
                      className="form-control form-control-md"
                      name="phone"
                      style={{
                        flex: "8",
                        padding: "5px",
                        border: ".5px solid black",
                      }}
                      placeholder="Phone..."
                      defaultValue={props.phone}
                      onChange={onChange}
                    />
                  </div>

                  <div className="form-group">
                    <label for="ticket">
                      <b>Ticket</b>
                    </label>
                    <input
                      id="ticket"
                      type="text"
                      name="tickettype"
                      className="form-control form-control-md"
                      style={{
                        flex: "8",
                        padding: "5px",
                        border: ".5px solid black",
                      }}
                      placeholder="Ticket Type..."
                      defaultValue={props.tickettype}
                      onChange={onChange}
                    />
                  </div>
                  
                  <div style={{display: "flex", justifyContent: "space-between"}}>
                  <div style={{width: "48%"}} className="form-group">
                    <label for="ticket">
                      <b>Table/Row #</b>
                    </label>
                    <input
                      id="table_row_number"
                      type="text"
                      name="table_row_number"
                      className="form-control form-control-md"
                      style={{
                        flex: "8",
                        padding: "5px",
                        border: ".5px solid black",
                      }}
                      placeholder="Table/Row #..."
                      defaultValue={props.table_row_number}
                      onChange={onChange}
                    />
                  </div>
                  <div style={{width: "48%"}} className="form-group">
                    <label for="ticket">
                      <b>Seat #</b>
                    </label>
                    <input
                      id="seat_number"
                      type="text"
                      name="seat_number"
                      className="form-control form-control-md"
                      style={{
                        flex: "8",
                        padding: "5px",
                        border: ".5px solid black",
                      }}
                      placeholder="Seat #..."
                      defaultValue={props.seat_number}
                      onChange={onChange}
                    />
                  </div>
                  </div>
                  <div className="form-group">
                    <label for="guest_details">
                      <b>Guest Details</b>
                    </label>
                    <textarea
                      id="guest_details"
                      type="text"
                      name="guest_details"
                      className="form-control form-control-md"
                      style={{
                        flex: "8",
                        padding: "5px",
                        border: ".5px solid black",
                      }}
                      placeholder="Additional Details..."
                      defaultValue={props.guest_details}
                      onChange={onChange}
                    />
                  </div>
                </div>
              </form>
              <div style={{ display: "flex", justifyContent:"space-evenly" }}>
                <button
                  className="viewTicket"
                  onClick={() => setViewTicket(true)}
                  style={{ float: "left" }}
                >
                  <b>View Ticket</b>
                </button>
                <button
                  className="viewTicket"
                  onClick={()=> guestprofile()}
                  style={{ float: "left" }}
                >
                  <b>View Profile</b>
                </button>
              </div>
            </div>
          ) : (
            <div>
              <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header>
                  <Modal.Title id="contained-modal-title-vcenter">
                    View Guest Ticket
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div id="divToPrint">
                    <GuestTicket guest={props} />
                  </div>
                  <div className="mb5">
                    <button
                      className="viewTicketButton"
                      onClick={printDocument}
                    >
                      <b>Download Ticket</b>
                    </button>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="info"
                    onClick={() => setViewTicket(false)}
                  >
                    Go back to Editing
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => closeEditModal()}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={onSubmit}>
            Submit
          </Button>
          <Button variant="danger" onClick={() => closeEditModal()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditGuestModal;
