import React, { useEffect, useState } from "react";
import ReactTable from "react-table";

const ReactTables = ({ columns, data, ariaLabels, filterable, defaultPageSize, minRows, getTrProps, className, loading, collapseOnDataChange, SubComponent }) => {

  const [uniqueTable] = useState(Math.floor(Math.random() * 10000));

  window.onclick = (e) => {

    // Identify the exact unique table and it's headers on the page which the user is interacting with
    let reactTablesHeaders = document.querySelectorAll(`div#uniqueTable${e.target.getAttribute('unique')} button.reacttableheading`);
    for (let i = 0; i < reactTablesHeaders.length; i++) {
      if (reactTablesHeaders[i].innerHTML) {
        reactTablesHeaders[i].setAttribute('aria-label', reactTablesHeaders[i].id)
        reactTablesHeaders[i].parentNode.setAttribute('aria-label', reactTablesHeaders[i].id)
        reactTablesHeaders[i].innerHTML = reactTablesHeaders[i].id + " &#11139"
      }
    }

    try {
      let currentClass = e.target.getAttribute('class');
      let currentClassParent;

      if (e.target.parentNode != null) {
        currentClassParent = e.target.parentNode.getAttribute('class');
      }

      // Identify the exact unique table and it's headers on the page which the user is interacting with
      // Here we reset the aria-labels of all headers (To remove ascending or descending from the header's aria-labels)
      // If element clicked is a React-Table Header, append the header's aria-label with "ascending/descending" sort order
      // Also we reset sorting arrows beside headers upon another being clicked

      // This handles Clicking Headers with Enter!
      if (e.target.innerText != null && e.target.innerText != "") {
        if (currentClass != null && currentClass.includes('reacttableheading')) {
          if (currentClassParent.includes("-sort-asc")) {
            e.target.setAttribute('aria-label', e.target.getAttribute('id') + ' ascending');
            let key = e.target.getAttribute('key');
            e.target.innerHTML = key + " &#10506";
          }
          else if (currentClassParent.includes("-sort-desc")) {
            e.target.setAttribute('aria-label', e.target.getAttribute('id') + ' descending');
            let key = e.target.getAttribute('key');
            e.target.innerHTML = key + " &#10507"
          }
        }
      }

      // This handles Clicking Headers with your Mouse
      if (e.target.firstChild.innerText != null && e.target.firstChild.innerText != "") {
        if (currentClass != null && currentClass.includes('rt-resizable-header')) {
          if (currentClass.includes("-sort-asc")) {
            e.target.setAttribute('aria-label', `${e.target.firstChild.id} ascending`)
            e.target.setAttribute('aria-live', 'polite')
            e.target.setAttribute('aria-atomic', 'true')
            e.target.innerHTML = `<button tabIndex= '0' href='#' class='reacttableheading rt-resizable-header-content' style='display: block; color: black; pointer-evnts: none; background: none; padding: 0; border: none;' aria-label='${e.target.firstChild.id} ascending' aria-live='polite' aria-atomic='true' key='${e.target.firstChild.id}' id= '${e.target.firstChild.id}' unique='${uniqueTable}'>${e.target.firstChild.id} &#10506</button>`;
          }
          else if (currentClass.includes("-sort-desc")) {
            e.target.setAttribute('aria-label', `${e.target.firstChild.id} descending`)
            e.target.setAttribute('aria-live', 'polite')
            e.target.setAttribute('aria-atomic', 'true')
            e.target.innerHTML = `<button tabIndex= '0' href='#' class='reacttableheading rt-resizable-header-content' style='display: block; color: black; pointer-evnts: none; background: none; padding: 0; border: none;' aria-label='${e.target.firstChild.id} descending' aria-live='polite' aria-atomic='true' key='${e.target.firstChild.id}' id= '${e.target.firstChild.id}' unique='${uniqueTable}'>${e.target.firstChild.id} &#10507</button>`;
          }
        }
      }

      // Allow Enter working with React Table Expanders
      if (e.target.getAttribute('class') === 'rt-expander') {
        e.target.setAttribute('class', 'rt-expander -open')
      }
      else if (e.target.getAttribute('class') === 'rt-expander -open') {
        e.target.setAttribute('class', 'rt-expander')
      }
    } catch (e) {
      console.error(`An error occurred resetting aria-labels upon sorting headers: ${e}`);
    }
  }

  useEffect(() => {
    // React Table Aria Labels. React table doesn't announce the associated header when the focus is on the table filters.
    // So we are manualy adding the associated aria-labels to the tables inputs dynamically.
    let reactTableAriaLabels = () => {
      let ariaLabelsInputs = ariaLabels;
      const reactTableAriaFilters = document.querySelectorAll(`div[class='uniqueTable${uniqueTable}'] div[class='rt-th']`);

      try {
        for (var i = 0; i < ariaLabelsInputs.length; i++) {
          reactTableAriaFilters[i].setAttribute("aria-label", ariaLabelsInputs[i]);
        }
      } catch (e) {
        console.error("aria-label setAttribute error", e);
      }
    };

    reactTableAriaLabels();

    // React Tab Index - Make Headers of React tables be tabbable and clickable woth Enter
    //Replacing div element with anchor that has a default href='#', so headers can be clicked using Enter.
    let tabIndexReactTableHeaders = () => {
      const reactTableHeaderFilters = document.querySelectorAll(`div[class='uniqueTable${uniqueTable}'] div[class='rt-resizable-header-content']`);

      try {
        for (var i = 0; i < reactTableHeaderFilters.length; i++) {
          reactTableHeaderFilters[i].parentNode.setAttribute('unique', `${uniqueTable}`)
          var newElement = document.createElement("button");
          newElement.setAttribute('tabIndex', 0);
          newElement.setAttribute('href', '#');
          newElement.setAttribute('style', "display: block; color: black; pointer-events: none; padding:0; border: none; background: none;");
          newElement.setAttribute('aria-label', reactTableHeaderFilters[i].innerHTML);
          newElement.setAttribute('aria-live', 'polite');
          newElement.setAttribute('key', reactTableHeaderFilters[i].innerHTML);
          newElement.setAttribute('id', reactTableHeaderFilters[i].innerHTML);
          newElement.setAttribute('class', "reacttableheading rt-resizeable-header-content");
          newElement.setAttribute('unique', `${uniqueTable}`);
          newElement.innerHTML = reactTableHeaderFilters[i].innerHTML;
          reactTableHeaderFilters[i].parentNode.replaceChild(newElement, reactTableHeaderFilters[i]);
        }
      } catch (e) {
        console.error("tabIndex setAttribute error for React Table Headers", e);
      }
    };

    tabIndexReactTableHeaders();

    let tabIndexReactTableCells = () => {
      const reactTableCellFilters = document.querySelectorAll(`div[class='uniqueTable${uniqueTable}'] div[class='rt-td']`);

      try {
        for (var i = 0; i < reactTableCellFilters.length; i++) {
          reactTableCellFilters[i].setAttribute("tabindex", 0);
        }
      } catch (e) {
        console.error("tabIndex setAttribute error for React Table Cells", e);
      }
    };

    tabIndexReactTableCells();

    // React Table Tab Indexes - Make React Expanders be tabbable
    let tabIndexReactExpanders = () => {
      const reactTableExpandersFilters = document.querySelectorAll(`div[class='uniqueTable${uniqueTable}'] div[class='rt-expander']`);

      try {
        for (let i = 0; i < reactTableExpandersFilters.length; i++) {
          let uniqueExpanders = `<div class='rt-expander' tabIndex='0'>●</div>`
          var newElement = document.createElement("button");
          newElement.setAttribute('tabIndex', -1);
          newElement.setAttribute('style', "background: none; border: none; padding: 0;");
          newElement.setAttribute('class', 'reactTableExpander');
          newElement.setAttribute('aria-label', reactTableExpandersFilters[i].innerHTML);
          newElement.innerHTML = uniqueExpanders;
          reactTableExpandersFilters[i].parentNode.replaceChild(newElement, reactTableExpandersFilters[i]);
        }
      } catch (e) {
        console.error("tabIndex setAttrribute error for React Table Cells", e);
      }
    }

    tabIndexReactExpanders()

    let allHeaders = document.querySelectorAll(`div[class='uniqueTable${uniqueTable}'] button.reacttableheading`);

    for (let i = 0; i < allHeaders.length; i++) {
      if (!allHeaders[i].innerHTML.includes('⮃')) {
        if (allHeaders[i].innerHTML) {
          allHeaders[i].innerHTML = allHeaders[i].innerHTML + " &#11139";
        }
      }
    }

    // Don't allow headers that are blank to be tabbed on.
    let reactHeadersThatBlank = Array.from(document.querySelectorAll(`button`)).find(el => el.innerHTML == '');
    if(reactHeadersThatBlank != null){
      reactHeadersThatBlank.setAttribute('tabIndex', -1);
    }

    // Dependencies added as per Lint Error
  }, [ariaLabels, uniqueTable]);

  return (
    <div className={`uniqueTable${uniqueTable}`} id={`uniqueTable${uniqueTable}`}>
      <ReactTable
        filterable={filterable}
        defaultPageSize={defaultPageSize}
        minRows={minRows}
        getTrProps={getTrProps}
        columns={columns}
        data={data}
        className={className}
        loading={loading}
        collapseOnDataChange={collapseOnDataChange}
        SubComponent={SubComponent}
      />
    </div>
  );
};

export default ReactTables;