import React, {useState, useEffect} from 'react'
import QRCode from "react-qr-code";

const GuestTicket = (props) => {

  const [qrobject, setQrObject] = useState({
    id: props.guest.id,
    dashId: props.guest.dashId
  })


  useEffect(() => {
    // const dashId = props.guest.dashId;
    // const id = props.guest.id;
    // const qrObjecto = {};
    // qrObjecto.dashId = dashId;
    // qrObjecto.id = id;

    // setQrObject("qrobject")
    // setQrObject(qrObjecto)



    // setQrObject("qrobject")
    // setQrObject(qrobject)
  }, [])
  
 


    return (
      <div>
        <div style={{ paddingLeft: 100, paddingBottom: 10, paddingTop: 10 }}>
          <h4 style={{ fontFamily: "Georgia, Helvetica" }}>
            <b>Guest Ticket Details</b>
          </h4>
        </div>

        <div
          style={{
            paddingLeft: 10,
            marginBottom: 10,
            fontFamily: "Georgia, Helvetica",
          }}
        >
          <b>Named:</b> {props.guest.list}
        </div>

        <div
          style={{
            paddingLeft: 10,
            marginBottom: 10,
            fontFamily: "Georgia, Helvetica",
          }}
        >
          <b>Organization:</b> {props.guest.organization}
        </div>
        <div
          style={{
            paddingLeft: 10,
            marginBottom: 10,
            fontFamily: "Georgia, Helvetica",
          }}
        >
          <b>Table #:</b> {props.guest.table_row_number}
        </div>
        <div
          style={{
            paddingLeft: 10,
            marginBottom: 10,
            fontFamily: "Georgia, Helvetica",
          }}
        >
          <b>Seat #:</b> {props.guest.seat_number}
        </div>
        <div
          style={{
            paddingLeft: 10,
            marginBottom: 10,
            fontFamily: "Georgia, Helvetica",
          }}
        >
          <b>Ticket Type:</b> {props.guest.tickettype}
        </div>
        <div
          style={{
            paddingLeft: 10,
            marginBottom: 10,
            fontFamily: "Georgia, Helvetica",
          }}
        >
          <b>Guest Details:</b> {props.guest.guest_details}
        </div>
        <div
          style={{
            paddingLeft: 10,
            marginBottom: 10,
            fontFamily: "Georgia, Helvetica",
          }}
        >
          <b>Confirmation Number:</b> {props.guest.id}
        </div>
        <hr></hr>
        <div
          style={{
            paddingLeft: 130,
            marginBottom: 10,
            paddingBottom: 20,
            fontFamily: "Georgia, Helvetica",
          }}
        >
          <QRCode size={130} value={JSON.stringify({
    qrobject
  })} />
        </div>
      </div>
    );
}

export default GuestTicket
