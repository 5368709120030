import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import axios from "axios";
// import Volunteer from "../Auth/Volunteer/Volunteer";
import moment from "moment";
import CancelSubscription from "./CancelSubscription";
import * as action from "../../store/actions/index";
import { Link } from "react-router-dom";
import "./Profile.css";
// import { isEmpty } from "../../utils/Validation";
// import { WidgetLoader, Widget } from "react-cloudinary-upload-widget";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Chapters from "../Auth/Chapters/Chapters";
import DeleteAccount from "./DeleteAccount";
import ApiAccess from "./ApiAccess";
import MailSettings from "./MailSettings";
import BarLoader from "react-spinners/BarLoader";
import ESToolkit from "../../utils/ESToolkit";

import {
  container,
  Form,
  Message,
  Segment,
  Dimmer,
  Loader,
} from "semantic-ui-react";

// const Context = React.createContext();

// const divStyle = {
//   border: ".8px solid black",
//   fontSize: "16px",
//   padding: 1,
// };

class Profile extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.blurPassword = this.blurPassword.bind(this);
  }

  state = {
    modalCancelSubscription: false,
    profileEdit: false,
    first_name: "",
    last_name: "",
    email: "",
    username: "",
    associatedemail: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    roleUppercase: "",
    associatedUsers: [],
    changePassword: false,
    password: "",
    confirmpassword: "",
    verifyEmailContainer: false,
    verifyEmailButton: false,
    verifyEmailError: false,
    verifyEmailLoading: false,
    showDeleteAccountModal: false,
    passwordMatches: false,
    passwordNumber: false,
    passwordLength: false,
    disableNewPassword: true,
    passwordUppercase: false,
    showPassword: false,
  };

  componentDidMount() {
    let authRedirect = null;
    if (!this.props.isAuthenticated) {
      authRedirect = <Redirect to="/" />;
    }

    // Thinking about removing this API call.
    // Removeable API Call to check if user is verified.
    // Can just use this.props.user.verified instead of this API call and this.state.verifyEmailContainer beloe
    axios
      .get("/api/users/")
      .then((res) => {
        const verifiedUserCheck = res.data.user.verified;
        if (verifiedUserCheck === true || this.props.user.verified === true) {
          this.setState({ verifyEmailContainer: true });
        } else {
          this.setState({ verifyEmailContainer: false });
        }
      })
      .catch((err) => {
        console.log(err.response);
      });

    try {
      let upperCaseRole =
        this.props.user.role.charAt(0).toUpperCase() +
        this.props.user.role.slice(1);

      this.setState({
        first_name: this.props.user.first_name,
        last_name: this.props.user.last_name,
        email: this.props.user.email,
        username: this.props.user.username,
        associatedemail: this.props.user.associatedemail,
        phone: this.props.user.phone,
        address: this.props.user.address,
        city: this.props.user.city,
        state: this.props.user.state,
        zip: this.props.user.zip,
        country: this.props.user.country,
        roleUppercase: upperCaseRole,
      });
    } catch {
      console.log("uppercase error");
    }

  }

  putRequest = (urllink) => {
    this.props.updateDashboard(urllink);
  };

  deleteProfilePic = () => {
    this.props.updateDashboard2();
  };

  deleteUser = (id) => {
    console.log("known");
    console.log(id);
    this.props.volunteerdelete(id);
  };
  

  cancelSubscription = () => {
    axios
      .post(`api/sub/cancel`, {})
      .then((res) => res(console.log(res)))
      .catch((error) => {
        console.log(error);
        console.log("error");
      });
    this.props.fetchUser();
    window.location.reload();
  };

  emailVerify = () => {
    this.setState({ verifyEmailLoading: true });
    console.log("tower call verified");
    axios
      .post(`/api/users/verifyrequest/${this.state.email}`)
      .then((res) => {
        console.log("verify email response1");
        console.log(res);

        if (res.data.success == true) {
          this.setState({ verifyEmailButton: true });
          this.setState({ verifyEmailError: false });
          this.setState({ verifyEmailLoading: false });
        }

        toast.success(
          <div style={{ fontSize: "16px" }}>
            <b>A verification request email was sent</b>
          </div>,
          {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      })
      .catch((error) => {
        console.log(error);
        console.log("error");
        this.setState({ verifyEmailError: true });
        this.setState({ verifyEmailButton: false });
        this.setState({ verifyEmailLoading: false });

        toast.error(
          <div style={{ fontSize: "16px" }}>
            <b>An error has occurred</b>
          </div>,
          {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      });

  };
  btnEnable(value) {
    if (value == true && this.state.password == this.state.confirmpassword) {
      return true;
    }
  }

  onSubmitPassword = () => {
    axios
      .post("/api/users/changepassword", { password: this.state.password })
      .then((res) => {
        console.log(res);
        console.log("all users");
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  onHideDeleteAccountModal = () => {
    this.setState({ showDeleteAccountModal: false });
  };

  handleClick = (evt) => {
    evt.preventDefault();
    return fetch("/cancel-subscription", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify({
      //   subscriptionId: subscriptionId,
      // }),
    })
      .then((response) => {
        return response.json();
      })
      .then((cancelSubscriptionResponse) => {
        // Display to the user that the subscription has been cancelled.
      })
      .catch(console.error("error"));
  };

  editProfile = () => {
    this.setState({ profileEdit: true });
  };

  cancelEditProfile = () => {
    this.setState({ profileEdit: false });
  };

  onChange = (e) => {
    console.log("edit profile onchange");
    this.setState({ [e.target.name]: e.target.value });

    console.log("here we go onchange");
    console.log(this.state);
    console.log("state");

    let hasNumber = /\d/;

    // Does the Password Match?
    if (
      this.state.password !== this.state.confirmpassword
      // this.state.password === this.state.confirmpassword
      // && this.state.confirmpassword != null
    ) {
      this.setState({ passwordMatches: false });
    } else if (this.state.password !== e.target.value) {
      this.setState({ passwordMatches: false });
    } else if (this.state.confirmpassword !== e.target.value) {
      this.setState({ passwordMatches: false });
    }

    if (this.state.password === e.target.value) {
      this.setState({ passwordMatches: true });
    } else if (this.state.confirmpassword === e.target.value) {
      this.setState({ passwordMatches: true });
    } else if (this.state.confirmpassword === this.state.password) {
      this.setState({ passwordMatches: true });
    }

    if (this.state.password !== e.target.value) {
      this.setState({ passwordMatches: false });
    }

    // Does the Password Have a Number?
    if (
      hasNumber.test(e.target.value) === true
      // hasNumber.test(this.state.password) == true
    ) {
      this.setState({ passwordNumber: true });
    } else if (hasNumber.test(e.target.value) === false) {
      this.setState({ passwordNumber: false });
    }

    // Is the Password over 5 characters?
    if (e.target.value.length > 5) {
      {
        this.setState({ passwordLength: true });
      }
    } else if (e.target.value.length < 5) {
      this.setState({ passwordLength: false });
    }

    // Does the Password Have an UpperCase Character?
    function hasUpperCase(str) {
      return str !== str.toLowerCase();
    }
    if (hasUpperCase(e.target.value) === true) {
      this.setState({ passwordUppercase: true });
    } else if (hasUpperCase(e.target.value) === false) {
      this.setState({ passwordUppercase: false });
    }

    // Do All 3 Match? Disable Submit Button

    if (
      e.target.value.length > 5 &&
      hasNumber.test(e.target.value) === true &&
      this.state.password === e.target.value &&
      hasUpperCase(e.target.value) === true
    ) {
      this.setState({ disableNewPassword: false });
      this.setState({ passwordMatches: true });
    } else {
      this.setState({ disableNewPassword: true });
    }

    // Do All 3 Match Backwards. Disable Submit Button
    if (
      e.target.value.length > 5 &&
      hasNumber.test(e.target.value) === true &&
      this.state.confirmpassword === e.target.value &&
      hasUpperCase(e.target.value) === true
    ) {
      this.setState({ disableNewPassword: false });
      this.setState({ passwordMatches: true });
    }
  };

  blurPassword = (e) => {
    console.log("onBlue");
    console.log(e.target.value);
  };

  onSubmit = (e) => {
    const editedUser = {
      email: this.state.email,
      password: this.state.password,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      username: this.state.username,
      associatedemail: this.state.associatedemail,
      phone: this.state.phone,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
      country: this.state.country,
    };

    try {
      this.props.editProfile(editedUser);

      toast.success(
        <div style={{ fontSize: "16px" }}>
          <b>Your profile was updated!</b>
        </div>,
        {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    } catch {
      toast.error(
        <div style={{ fontSize: "16px" }}>
          <b>An error occurred updating your profile.</b>
        </div>,
        {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
    this.cancelEditProfile();
  };
  render() {

    let addModalClose = () => this.setState({ modalCancelSubscription: false });

    let authRedirect = null;
    if (!this.props.isAuthenticated) {
      authRedirect = <Redirect to="/" />;
    }

    return (
      <div className="container">

        {/* Testing Aggregations Mongo */}
        {/* <div style={{ marginTop: "3%", marginBottom: "3%" }}>
            <button
              className="subscribebutton"
              onClick={() =>
                {
                  console.log("frontend call")

                  axios.get('/api/aggregate')
                  .then(res =>{
                      console.log("API Access")
                      console.log(res)
                  })  
                  .catch(err => {
                    console.log(err.response)
                  })}
              }
              style={{
                marginTop: 5,
                fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                fontWeight: "bold",
                marginLeft: 10,
              }}
            >
                Test Aggregations
        </button>
          </div> */}

        <ToastContainer
          position="top-left"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        {!this.state.profileEdit ? (
          <div className="profilecontainer">
            {authRedirect}
            <div style={{ textAlign: "center" }}>
              <div className="profiletitlefont">
                <b>Profile Information</b>
                {/* <button onClick={()=>{
                      axios.get('/api/dashboard/apiaccess/test@test.com/xa2syv4ytd8/aaabbb1/')
                      .then(res =>{
                          console.log("API Access")
                          console.log(res)
                      })  
                      .catch(err => {
                        console.log(err.response)
                      })
                }}> run api test</button> */}
                <i
                  className="fas fa-pen fa-xl edit_profile"
                  onClick={() => this.editProfile()}
                ></i>
              </div>

              <br />
              {/* <img
                className="profileimage"
                style={{
                  overflow: "hidden",
                  border: "1px solid gray",
                  borderRadius: "45%",
                }}
                src={this.props.user.pic}
                alt="profie pic"
              ></img> */}
            </div>
            <br />
            <div className="profilefontinput">
              <b>First Name: </b> {this.props.user.first_name}
            </div>{" "}
            <br />
            <div className="profilefontinput">
              <b>Last Name: </b> {this.props.user.last_name}
            </div>{" "}
            <br />
            <div className="profilefontinput">
              <b>Email: </b> {this.props.user.email}
            </div>{" "}
            <br />
            <div className="profilefontinput">
              <b>Username: </b> {this.props.user.username}
            </div>
            <br />
            <div className="profilefontinput">
              <b>Phone: </b> {this.props.user.phone}
            </div>{" "}
            <br />
            <div className="profilefontinput">
              <b>Address: </b> {this.props.user.address}
            </div>{" "}
            <br />
            <div className="profilefontinput">
              <b>City: </b> {this.props.user.city}
            </div>
            <br />
            <div className="profilefontinput">
              <b>State: </b> {this.props.user.state}
            </div>
            <br />
            <div className="profilefontinput">
              <b>Zip Code: </b> {this.props.user.zip}
            </div>
            <br />
            <div className="profilefontinput">
              <b>Country: </b> {this.props.user.country}
            </div>
            <br />
            <div className="profilefontinput">
              <b>Role: </b> {this.state.roleUppercase}
            </div>
            <br />
            <div className="profilefontinput">
              <b>Associated Account: </b> {this.props.user.associatedemail}
            </div>
            <br />
            <div className="profilefontinput">
              <b>Registration Date: </b>
              {moment(this.props.user.registration_date).format("MM-DD-YYYY")}
            </div>
            <br />
            <div className="profilefontinput">
              <b>Subscription End Date: </b>
              {this.props.user.subscription_date != null ?
                <span>
                  {moment(this.props.user.subscription_date).format("MM-DD-YYYY")}
                </span>
                : null}
            </div>
            <br />
            {/* {(this.props.user.registration_date.substring(0, 7))}
              {this.props.ußßser.subscription_date.substring(0, 7)} */}
            {Date.parse(
              this.props.user.registration_date &&
              this.props.user.registration_date.substring(0, 7)
            ) !==
              Date.parse(
                this.props.user.subscription_date &&
                this.props.user.subscription_date.substring(0, 7)
              ) ? (
              <div className="profilefontinput">
                <b>Subscription: </b>
                Subscribed
              </div>
            ) : (
              <div className="profilefontinput">
                <b>Subscription Status: </b>
                <i style={{ color: "darkred" }}>Not Subscribed</i>
              </div>
            )}
          </div>
        ) : (
          // Edited Profile Page ---- divs changed to inputs
          <div className="profilecontainer_edit">
            {authRedirect}
            <div style={{ marginBottom: 20, textAlign: "center" }}>
              <div className="profiletitlefont">
                <b>Profile Information</b>
                <i
                  className="fas fa-remove fa-xl edit_profile_remove"
                  onClick={() => this.cancelEditProfile()}
                ></i>
              </div>

              {/* <br />
              <img
                className="profileimage"
                style={{
                  overflow: "hidden",
                  border: "1px solid gray",
                  borderRadius: "45%",
                }}
                src={this.props.user.pic}
                alt="profie pic"
              ></img>

              <i
                style={{ position: "relative", top: "-20px", left: "0px" }}
                className="fas fa-remove fa-xl edit_pic"
                onClick={() => this.deleteProfilePic()}
              ></i> */}
            </div>
            {/* <div style={{ textAlign: "center", marginTop: "20px" }}>
              <>
                <WidgetLoader />
                <Widget
                  sources={["local", "camera", "dropbox"]} // set the sources available for uploading -> by default
                  // all sources are available. More information on their use can be found at
                  // https://cloudinary.com/documentation/upload_widget#the_sources_parameter
                  sourceKeys={{
                    dropboxAppKey: "1dsf42dl1i2",
                    instagramClientId: "d7aadf962m",
                  }} // add source keys
                  // and ID's as an object. More information on their use can be found at
                  // https://cloudinary.com/documentation/upload_widget#the_sources_parameter
                  resourceType={"image"} // optionally set with 'auto', 'image', 'video' or 'raw' -> default = 'auto'
                  cloudName={"dxun6gsre"} // your cloudinary account cloud name.
                  // Located on https://cloudinary.com/console/
                  uploadPreset={"oehbwgkv"} // check that an upload preset exists and check mode is signed or unisgned
                  buttonText={"Change Profile Picture"} // default 'Upload Files'
                  style={{
                    color: "white",
                    border: "none",
                    width: "190px",
                    backgroundColor: "green",
                    borderRadius: "4px",
                    height: "25px",
                  }} // inline styling only or style id='cloudinary_upload_button'
                  folder={"my_folder"} // set cloudinary folder name to send file
                  cropping={true} // set ability to crop images -> default = true
                  // https://support.cloudinary.com/hc/en-us/articles/203062071-How-to-crop-images-via-the-Upload-Widget-#:~:text=Click%20on%20the%20%22Edit%22%20link,OK%22%20and%20Save%20the%20changes.
                  // more information here on cropping. Coordinates are returned or upload preset needs changing
                  multiple={true} // set to false as default. Allows multiple file uploading
                  // will only allow 1 file to be uploaded if cropping set to true
                  autoClose={false} // will close the widget after success. Default true
                  onSuccess={(res) => this.putRequest(res.info.url)} // add success callback -> returns result
                  // onFailure={failureCallBack} // add failure callback -> returns 'response.error' + 'response.result'

                  logging={false} // logs will be provided for success and failure messages,
                  // set to false for production -> default = true
                  customPublicId={"sample"} // set a specific custom public_id.
                  // To use the file name as the public_id use 'use_filename={true}' parameter
                  eager={"w_400,h_300,c_pad|w_260,h_200,c_crop"} // add eager transformations -> deafult = null
                  use_filename={false} // tell Cloudinary to use the original name of the uploaded
                  // file as its public ID -> default = true,

                  widgetStyles={{
                    palette: {
                      window: "#737373",
                      windowBorder: "#FFFFFF",
                      tabIcon: "#FF9600",
                      menuIcons: "#D7D7D8",
                      textDark: "#DEDEDE",
                      textLight: "#FFFFFF",
                      link: "#0078FF",
                      action: "#FF620C",
                      inactiveTabIcon: "#B3B3B3",
                      error: "#F44235",
                      inProgress: "#0078FF",
                      complete: "#20B832",
                      sourceBg: "#909090",
                    },
                    fonts: {
                      default: null,
                      "'Fira Sans', sans-serif": {
                        url: "https://fonts.googleapis.com/css?family=Fira+Sans",
                        active: true,
                      },
                    },
                  }} // ability to customise the style of the widget uploader
                  destroy={true} // will destroy the widget on completion
                />
              </>
            </div> */}
            <br />
            <div style={{ marginBottom: -7 }} className="profilefontinput">
              <label>
                <b>First Name:</b>{" "}
              </label>
              <input
                name="first_name"
                className="profileinput"
                onChange={this.onChange}
                type="text"
                defaultValue={this.props.user.first_name}
                style={{
                  border: "1px solid #888888",
                }}
              />
            </div>{" "}
            <br />
            <div style={{ marginBottom: -7 }} className="profilefontinput">
              <label>
                <b>Last Name:</b>{" "}
              </label>
              <input
                name="last_name"
                className="profileinput"
                onChange={this.onChange}
                type="text"
                defaultValue={this.props.user.last_name}
                style={{
                  border: "1px solid #888888",
                }}
              />
            </div>{" "}
            <br />
            <div className="profilefontinput">
              <b>Email: </b> {this.props.user.email}
            </div>
            <br />
            <div className="profilefontinput">
              <b>Username: </b> {this.props.user.username}
            </div>
            <br />
            <div className="profilefontinput">
              <b>Phone: </b>
              <input
                name="phone"
                className="profileinput"
                onChange={this.onChange}
                type="text"
                defaultValue={this.props.user.phone}
                style={{
                  border: "1px solid #888888",
                }}
              />
            </div>{" "}
            <br />
            <div className="profilefontinput">
              <b>Address: </b>
              <input
                name="address"
                className="profileinput"
                onChange={this.onChange}
                type="text"
                defaultValue={this.props.user.address}
                style={{
                  border: "1px solid #888888",
                }}
              />
            </div>{" "}
            <br />
            <div className="profilefontinput">
              <b>City: </b>
              <input
                name="city"
                className="profileinput"
                onChange={this.onChange}
                type="text"
                defaultValue={this.props.user.city}
                style={{
                  border: "1px solid #888888",
                }}
              />
            </div>
            <br />
            <div className="profilefontinput">
              <b>State: </b>
              <input
                name="state"
                className="profileinput"
                onChange={this.onChange}
                type="text"
                defaultValue={this.props.user.state}
                style={{
                  border: "1px solid #888888",
                }}
              />
            </div>
            <br />
            <div className="profilefontinput">
              <b>Zip Code: </b>
              <input
                name="zip"
                className="profileinput"
                onChange={this.onChange}
                type="text"
                defaultValue={this.props.user.zip}
                style={{
                  border: "1px solid #888888",
                }}
              />
            </div>
            <br />
            <div className="profilefontinput">
              <b>Country: </b>
              {/* <input
                name="country"
                className="profileinput"
                onChange={this.onChange}
                type="text"
                defaultValue={this.props.user.country}
                style={{
                  border: "1px solid #888888",
                }}
              /> */}

              <select
                name="country"
                className="profileinput"
                placeholder="Country"
                style={{ border: "1px solid #888888" }}
                defaultValue={this.props.user.country}
                onChange={this.onChange}
              >
                <option className="profileinput" value="" default selected>
                  Select Country..
                </option>
                {ESToolkit.countries.map((option) => (
                  <option
                    className="profileinput"
                    style={{ color: "black" }}
                    value={option}
                  >
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <br />
            <div className="profilefontinput">
              <b>Role: </b>
              {this.state.roleUppercase}
            </div>
            <br />
            <div className="profilefontinput">
              <b>Associated Account: </b>
              {this.props.user.associatedemail}
            </div>
            <br />
            <div className="profilefontinput">
              <b>Registration Date: </b>
              {moment(this.props.user.registration_date).format("MM-DD-YYYY")}
            </div>
            <br />
            <div className="profilefontinput">
              <b>Subscription End Date: </b>
              {moment(this.props.user.subscription_date).format("MM-DD-YYYY")}
            </div>
            <br />
            {/* {(this.props.user.registration_date.substring(0, 7))}
            {this.props.ußßser.subscription_date.substring(0, 7)} */}
            {Date.parse(
              this.props.user.registration_date &&
              this.props.user.registration_date.substring(0, 7)
            ) !==
              Date.parse(
                this.props.user.subscription_date &&
                this.props.user.subscription_date.substring(0, 7)
              ) ? (
              <div className="profilefontinput">
                <b>Subscription: </b>
                Subscribed
              </div>
            ) : (
              <div className="profilefontinput">
                <b>Subscription Status: </b>
                <i style={{ color: "darkred" }}>Not Subscribed</i>
              </div>
            )}
            <div style={{ textAlign: "center" }}>
              <Button
                id="eventcancelbutton"
                onClick={this.cancelEditProfile}
                variant="danger"
                className="eventcreatebuttons"
                style={{
                  height: 40,
                  marginTop: 25,
                  marginBottom: 28,
                  marginRight: "20%",
                  borderRadius: 29,
                  width: "30%",
                  border: "1px solid black",
                  boxShadow: "0px 2px 4px black",
                }}
              >
                <i className="fas fa-remove fa-sm"></i>
                <b> Cancel</b>
              </Button>
              <Button
                disabled={this.state.btnEnabled}
                onClick={this.onSubmit}
                variant="light"
                style={{
                  height: 40,
                  marginTop: 25,
                  marginBottom: 28,
                  borderRadius: 29,
                  width: "35%",
                  border: "1px solid black",
                  boxShadow: "0px 2px 4px black",
                }}
              >
                <div id="eventcreatebutton" className="eventcreatebuttons">
                  <i className="fas fa-save fa-sm"></i>
                  <b> Save Changes</b>
                </div>
              </Button>
            </div>
          </div>
        )}

        <hr></hr>

        {/* Change Password Module */}
        <div
          className="verifyemailcontainer"
          style={{
            paddingTop: 10,
            border: "1px solid darkgray",
            padding: 20,
            paddingLeft: 24,
            borderRadius: 10,
            paddingBottom: 24,
          }}
        >
          <h3 className="changepasstitle">
            <b>Click Below to Change Your Password</b>
          </h3>
          <div style={{ marginTop: "3%", marginBottom: "3%" }}>
            <button
              className="subscribebutton"
              onClick={() =>
                this.setState({ changePassword: !this.state.changePassword })
              }
              style={{
                marginTop: 5,
                fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                fontWeight: "bold",
                marginLeft: 10,
              }}
            >
              <h4
                className="changePassword"
                style={{
                  fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                }}
              >
                <b>Change Password</b>
              </h4>
            </button>
            {/* (Passwords must match and include a number) */}
            {this.state.changePassword ? (
              <div
                className="changepassprofile"
                style={{
                  paddingTop: 10,
                  padding: 12,
                  paddingLeft: 22,
                  borderRadius: 10,
                  paddingBottom: 0,
                }}
              >
                <div id="changepassprofile" className="form-group">
                  <label htmlFor="password">
                    <b style={{ fontFamily: "Verdana, Geneva, Helvetica" }}>
                      New Password
                    </b>
                  </label>

                  <div style={{ display: "flex" }}>
                    <input
                      id="password"
                      type={this.state.showPassword ? "text" : "password"}
                      className="form-control-md"
                      name="password"
                      style={{
                        width: "275px",
                        padding: "8px",
                        border: ".5px solid black",
                        fontFamily: "Verdana, Geneva, Helvetica",
                      }}
                      // placeholder="Phone..."
                      // defaultValue
                      onChange={this.onChange}
                      onBlur={this.blurPassword}
                    />
                    <div
                      onClick={() => this.setState({ showPassword: true })}
                      style={{
                        textAlign: "center",
                        cursor: "pointer",
                        border: ".5px solid black",
                        padding: 8,
                        fontFamily: "Verdana, Geneva, Helvetica",
                        width: "60px",
                      }}
                    >
                      Show
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="confirmpassword">
                    <b style={{ fontFamily: "Verdana, Geneva, Helvetica" }}>
                      Confirm New Password
                    </b>
                  </label>
                  <div style={{ display: "flex" }}>
                    <input
                      id="confirmpassword"
                      type={this.state.showPassword ? "text" : "password"}
                      className="form-control-md"
                      name="confirmpassword"
                      style={{
                        width: "275px",
                        padding: "8px",
                        border: ".5px solid black",
                        fontFamily: "Verdana, Geneva, Helvetica",
                      }}
                      // placeholder="Phone..."
                      // defaultValue
                      onChange={this.onChange}
                    />
                    <div
                      onClick={() => this.setState({ showPassword: true })}
                      style={{
                        textAlign: "center",
                        cursor: "pointer",
                        border: ".5px solid black",
                        padding: 8,
                        fontFamily: "Verdana, Geneva, Helvetica",
                        width: "60px",
                      }}
                    >
                      Show
                    </div>
                  </div>
                </div>
                {this.state.passwordLength ? (
                  <div
                    style={{
                      color: "darkgreen",
                      fontWeight: "bold",
                      fontFamily: "Verdana, Geneva, Helvetica",
                    }}
                  >
                    <i className="fas fa-check fa-xl"></i> Atleast 5 Characters
                  </div>
                ) : (
                  <div
                    style={{
                      color: "darkred",
                      fontWeight: "bold",
                      fontFamily: "Verdana, Geneva, Helvetica",
                    }}
                  >
                    <i className="fas fa-remove fa-xl"></i> Atleast 5 Characters
                  </div>
                )}

                {this.state.passwordNumber ? (
                  <div
                    style={{
                      color: "darkgreen",
                      fontWeight: "bold",
                      fontFamily: "Verdana, Geneva, Helvetica",
                    }}
                  >
                    <i className="fas fa-check fa-xl"></i> Includes Number
                  </div>
                ) : (
                  <div
                    style={{
                      color: "darkred",
                      fontWeight: "bold",
                      fontFamily: "Verdana, Geneva, Helvetica",
                    }}
                  >
                    <i className="fas fa-remove fa-xl"></i> Includes Number
                  </div>
                )}

                {this.state.passwordUppercase ? (
                  <div
                    style={{
                      color: "darkgreen",
                      fontWeight: "bold",
                      fontFamily: "Verdana, Geneva, Helvetica",
                    }}
                  >
                    <i className="fas fa-check fa-xl"></i> Includes Uppercase
                  </div>
                ) : (
                  <div
                    style={{
                      color: "darkred",
                      fontWeight: "bold",
                      fontFamily: "Verdana, Geneva, Helvetica",
                    }}
                  >
                    <i className="fas fa-remove fa-xl"></i> Includes Uppercase
                  </div>
                )}

                {this.state.passwordMatches ? (
                  <div
                    style={{
                      color: "darkgreen",
                      fontWeight: "bold",
                      fontFamily: "Verdana, Geneva, Helvetica",
                      marginBottom: 0,
                    }}
                  >
                    <i className="fas fa-check fa-xl"></i> Password Matches
                  </div>
                ) : (
                  <div
                    style={{
                      color: "darkred",
                      fontWeight: "bold",
                      fontFamily: "Verdana, Geneva, Helvetica",
                      marginBottom: 0,
                    }}
                  >
                    <i className="fas fa-remove fa-xl"></i> Password Matches
                  </div>
                )}

                <div style={{ display: "flex", marginTop: "4%" }}>
                  <Button
                    style={{
                      borderRadius: 20,
                      width: "120px",
                      border: "1px solid DimGray",
                      fontSize: "13px",
                      fontWeight: "bold",
                      marginRight: 20,
                      fontFamily: "Verdana, Geneva, Helvetica",
                    }}
                    disabled={this.state.disableNewPassword}
                    variant="success"
                    onClick={this.onSubmitPassword}
                  >
                    Submit
                  </Button>
                  <Button
                    style={{
                      borderRadius: 20,
                      width: "120px",
                      border: "1px solid DimGray",
                      fontSize: "14px",
                      fontWeight: "bold",
                      fontFamily: "Verdana, Geneva, Helvetica",
                    }}
                    onClick={() => this.setState({ changePassword: false })}
                    variant="danger"
                  >
                    Close
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {/* <hr></hr> */}
        {this.props.user.role === "Owner Original" ||
          this.props.user.role === "Owner" ? (
          <div>
            {/* <Volunteer />
            <hr></hr>
              <Chapters /> */}

            {/* 
            <div className="volunteerscontainer">
              <h3>Registered Volunteers</h3>
              {volunteerList}
            </div> */}

            <h3 className="profiletitlesub">
              <b>User Account Status</b>
            </h3>
            <hr className="my-4" />
            {this.props.user.subscription_date != null &&
              Date.parse(
                this.props.user.registration_date &&
                this.props.user.registration_date.substring(0, 7)
              ) !==
              Date.parse(
                this.props.user.subscription_date &&
                this.props.user.subscription_date.substring(0, 7)
              ) ? (
              <div style={{ marginTop: "6%", marginBottom: "10%" }}>
                <h3 className="profiletitlesub">
                  <b>Cancel your Monthly Subscription</b>
                </h3>
                {/* <Button style={{fontSize:"25px"}} onClick={()=>this.cancelSubscription()} variant="danger">Cancel Subscription</Button> */}

                <br />
                <br />
                <Button
                  style={{ fontSize: "25px" }}
                  variant="danger"
                  onClick={() =>
                    this.setState({ modalCancelSubscription: true })
                  }
                  aria-label="Close"
                >
                  Cancel Subscription
                </Button>

                <CancelSubscription
                  cancel={() => this.cancelSubscription()}
                  show={this.state.modalCancelSubscription}
                  dashId={this.props.dashId}
                  id={this.props.listId}
                  onHide={addModalClose}
                />
              </div>
            ) : (
              <div style={{ marginBottom: "10%" }}>
                <div
                  className="verifyemailcontainer"
                  style={{
                    paddingTop: 10,
                    border: "1px solid darkgray",
                    padding: 20,
                    paddingLeft: 24,
                    borderRadius: 10,
                    paddingBottom: 24,
                  }}
                >
                  <h3
                    className="profiletitlesub"
                    style={{
                      fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                      fontSize: 20,
                    }}
                  >
                    <b>You are Not currently subscribed</b>
                  </h3>

                  <Link className="nav-link" to="/subscription">
                    <button
                      className="subscribebutton"
                      style={{
                        marginTop: 10,
                        fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                        fontWeight: "bold",
                        marginLeft: -10,
                      }}
                    >
                      Subscribe Now
                    </button>
                  </Link>
                </div>
                {/* <hr></hr> */}

                {/* Note: Here is the Verification of email container */}
                {this.state.verifyEmailContainer === true ||
                  this.props.user.verified === true ? (
                  <div
                    className="verifyemailcontainer"
                    style={{
                      paddingTop: 10,
                      border: "1px solid darkgray",
                      padding: 20,
                      paddingLeft: 24,
                      borderRadius: 10,
                      paddingBottom: 24,
                    }}
                  >
                    <h3
                      className="profiletitlesub"
                      style={{ marginTop: "3%", color: "darkgreen" }}
                    >
                      <b>Your email has been verified.</b>
                    </h3>
                  </div>
                ) : (
                  <Form className="submitForm">
                    <div
                      className="verifyemailcontainer"
                      style={{
                        paddingTop: 10,
                        border: "1px solid darkgray",
                        padding: 20,
                        paddingLeft: 24,
                        borderRadius: 10,
                        paddingBottom: 24,
                      }}
                    >
                      {!this.state.verifyEmailLoading ? (
                        <div>
                          <h3
                            className="profiletitlesub"
                            style={{
                              fontSize: 20,
                              marginTop: "1%",
                              fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                              color: "$606060",
                            }}
                          >
                            <b>
                              Your email is currently{" "}
                              <span style={{ color: "darkred" }}>NOT</span>{" "}
                              verified.
                            </b>
                          </h3>

                          <button
                            className="verifybutton"
                            onClick={this.emailVerify}
                            disabled={this.state.verifyEmailButton}
                            style={{
                              marginLeft: "1%",
                              marginTop: 15,
                            }}
                          >
                            <b
                              style={{
                                fontSize: 18,
                                fontFamily:
                                  "Verdana, Geneva, Tahoma, sans-serif",
                              }}
                            >
                              Resend Verification Email
                            </b>
                          </button>

                          <br />
                          {this.state.verifyEmailButton ? (
                            <div style={{ textAlign: "center" }}>
                              <small style={{ color: "darkgreen" }}>
                                <b style={{ fontSize: "16px" }}>
                                  Verification email sent!
                                </b>
                              </small>
                            </div>
                          ) : null}
                          {this.state.verifyEmailError ? (
                            <div style={{ textAlign: "center" }}>
                              <small style={{ color: "darkred" }}>
                                <b style={{ fontSize: "16px" }}>
                                  An error has occurred!
                                </b>
                              </small>
                            </div>
                          ) : null}
                        </div>
                      ) :

                        <div>
                          <div
                            style={{ display: "flex", justifyContent: "center" }}
                          >
                            <h2 style={{ marginTop: 5 }}>Sending verification email...</h2>
                          </div>
                          <div
                            style={{ marginTop: 20, display: "flex", justifyContent: "center" }}
                          >
                            <BarLoader
                              color={"#073980"}
                              // loading={loading}
                              size={300}
                              width={400}
                              thickness={100}
                              height={20}
                              aria-label="Loading Spinner"
                              data-testid="loader"
                              speedMultiplier={1}
                              margin={0}
                            />
                          </div>
                        </div>
                      }
                    </div>
                  </Form>
                )}
                {/* <hr></hr> */}
                <MailSettings />
                <ApiAccess />

                <div
                  className="verifyemailcontainer"
                  style={{
                    border: "1px solid darkgray",
                    padding: 20,
                    paddingLeft: 24,
                    borderRadius: 10,
                    paddingBottom: 24,
                  }}
                >
                  <h3
                    className="deleteaccounttitle"
                    style={{
                      marginTop: "1%",
                      color: "#505050",
                      fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                    }}
                  >
                    <b>View the Activity Log for your account</b>
                  </h3>

                  <Link
                    to='/activity'>
                    <button
                      className="verifybutton"
                      disabled={this.state.verifyEmailButton}
                      style={{
                        marginLeft: "1%",
                        marginTop: 15,
                      }}
                    >
                      <b
                        style={{
                          fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                        }}
                      >
                        Activity Log
                      </b>
                    </button>
                  </Link>
                </div>


                <div
                  className="verifyemailcontainer"
                  style={{
                    border: "1px solid darkgray",
                    padding: 20,
                    paddingLeft: 24,
                    borderRadius: 10,
                    paddingBottom: 24,
                  }}
                >
                  <h3
                    className="deleteaccounttitle"
                    style={{
                      marginTop: "1%",
                      color: "#505050",
                      fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                    }}
                  >
                    <b>Delete Account and All Data</b>
                  </h3>

                  <button
                    className="verifybutton"
                    onClick={() =>
                      this.setState({ showDeleteAccountModal: true })
                    }
                    disabled={this.state.verifyEmailButton}
                    style={{
                      marginLeft: "1%",
                      marginTop: 15,
                    }}
                  >
                    <b
                      style={{
                        fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
                      }}
                    >
                      Delete Account
                    </b>
                  </button>
                </div>





              </div>
            )}
          </div>
        ) : null}
        <DeleteAccount
          show={this.state.showDeleteAccountModal}
          onHide={this.onHideDeleteAccountModal}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editProfile: (userData) => {
      dispatch(action.editProfile(userData));
    },
    fetchUser: () => {
      dispatch(action.fetchUser());
    },
    updateDashboard: (urllink) => {
      dispatch(action.updateDashboard(urllink));
    },
    updateDashboard2: () => {
      dispatch(action.updateDashboard2());
    },
    volunteerdelete: (id) => {
      dispatch(action.volunteerdelete(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
// export default connect(mapStateToProps, null)(index)
