import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { resetPassword } from "../../../store/actions/userActions";
// import * as actions from "../../../store/actions/index";
import { Alert } from "@mui/material";

import {
  Button,
  Container,
  Form,
  Icon,
  Message,
  Segment, Dimmer, Loader
} from 'semantic-ui-react';
import axios from "axios";

const ResetPassword = (props) => {
	const dispatch = useDispatch();
	const [passwordLength, setPasswordLength] = useState(false);
	const [passwordMatches, setPasswordMatches] = useState(false);
	const [passwordNumber, setPasswordNumber] = useState(false);
	const [passwordUppercase, setpasswordUppercase] = useState(false);
	const [disableNewPassword, setDisableNewPassword] = useState(true);
	const [successAlertmessage, setSuccessAlertmessage] = useState("");
	const [errorAlertmessage, setErrorAlertmessage] = useState("");
	const [loading, setLoading] = useState(false);

	const [showPassword, setShowPassword] = useState(false);

	const [formData, setFormData] = useState({
		password: "",
		confirmPassword: ""
	  });

	const onChange = (e) => {
		// this.setState({
		// ...this.state, [e.target.name]: e.target.value });

		const { name, value } = e.target;

		setFormData({
		  ...formData,
		  [name]: value,
		});

		let hasNumber = /\d/;

		// Does the Password Match?
		if (
		  formData.password !== formData.p
		  // formData.password === formData.p
		  // && formData.p != null
		) {
		  setPasswordMatches(false);
		} else if (formData.password !== e.target.value) {
		  setPasswordMatches(false);
		} else if (formData.p !== e.target.value) {
		  setPasswordMatches(false);
		}
	
		if (formData.password === e.target.value) {
		  setPasswordMatches(true);
		} else if (formData.p === e.target.value) {
		  setPasswordMatches(true);
		} else if (formData.p === formData.password) {
		  setPasswordMatches(true);
		}
	
		if (formData.password !== e.target.value) {
		  setPasswordMatches(false);
		}
	
		// Does the Password Have a Number?
		if (
		  hasNumber.test(e.target.value) === true
		  // hasNumber.test(formData.password) == true
		) {
			setPasswordNumber(true)
		} else if (hasNumber.test(formData.password) == false) {
		  setPasswordNumber(false);
		}
	
		// Does the Password Have an UpperCase Character?
		function hasUpperCase(str) {
		  return str !== str.toLowerCase();
		  }
	
		if (
		  hasUpperCase(e.target.value) === true
		) {
		  setpasswordUppercase(true)
	
		} else if (hasUpperCase(e.target.value) === false) {
		  setpasswordUppercase(false);
		}
	
	
		// Is the Password over 5 characters?
		if (e.target.value.length > 5) {
		  {
			setPasswordLength(true);
		  }
		} else if (e.target.value.length < 5) {
			setPasswordLength(false);
		}
	
		// Do All 3 Match? Disable Submit Button
	
		if (
		  e.target.value.length > 5 &&
		  hasNumber.test(e.target.value) === true &&
		  (formData.password === e.target.value
		  || formData.confirmPassword === e.target.value) &&
		  hasUpperCase(e.target.value) === true
		) {
			setDisableNewPassword(false)
		} else {
			setDisableNewPassword(true)
		}
	
	}
  
	const onSubmit = (e) => {
		setErrorAlertmessage("")
		setSuccessAlertmessage("")
		console.log("on submit")

		e.preventDefault();
		const resetData = {
		password: formData.password,
		confirmPassword: formData.confirmPassword,
		email: props.match.params.email,
		token: props.match.params.token
		};

	// await axios.post(`/api/reset/reset-password`, formData);
	// dispatch(actions.resetPassword(formData, props.history))
	try {
	axios.post(`/api/users/reset-password`, resetData)
	.then((res) => {
		setLoading(true)
		console.log("response Data");
		console.log(res);
		setLoading(false)

		if(res.data.message == "User not found"){
			setErrorAlertmessage("This Email/User does not exist.")
		}
		else if(res.data.message == "An error occurred"){
			setErrorAlertmessage("An unknown error has occurred.")
		}
		else if(res.data.message == "Incorrect token"){
			setErrorAlertmessage("Token has been used or is not correct. Please request another link.")
		}
		else if(res.data.message == "Token expired"){
			setErrorAlertmessage("Your token has expired. Please request another link.")
		}
		else if(res.data.message == "Your password has been reset"){
			setErrorAlertmessage("")
			setSuccessAlertmessage("Your password has successfully been reset.")
		}
		
	  })
	  .catch((err) => {
		console.log(err);
		setLoading(false)
	  });
	  } 
	  catch (error) {
		setLoading(false)
	  }
  };
	
	return(
	  <div className="main" style={{marginTop:"3%"}}>
		<div
        style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
      >
        <h1
          style={{
            textAlign: "center",
            marginTop: "3.5%",
            fontFamily: "Georgia, Helvetica",
          }}
        >
          <b
            style={{
              fontFamily: "Verdana, Geneva, Helvetica",
			  color: "#404040"
            }}
          >
            Create Your New Password
          </b>
        </h1>
      </div>

		  
		  <div
        style={{
          background: "#F8F8F8",
          width: "600px",
          marginLeft: 50,
		  border: "1px solid gray",
		  marginTop: 30,
		  padding: 25,
		  paddingLeft: 30,
		  paddingTop: 40,
        }}
      >
		<h2 className="innerdivtitles" style={{marginTop:-20}}>Enter your new password</h2>
		{!successAlertmessage == "" ? (
              <Alert
                // className="float-right"
                style={{
                  // position: "fixed",
				  border: ".9px solid darkgreen",
				  marginTop: 10,
                  zIndex: 1,
                  fontSize: 14,
                  marginBottom: 12,
				  marginRight: 11
                }}
                severity="success"
              >
                <b>{successAlertmessage}</b>
              </Alert>
            ) : null}
		  <Form onSubmit={onSubmit} className="" style={{width:"600px", marginTop:"3%"}}>
			{loading ? (<Dimmer active inverted size="massive">
			<Loader inverted>Loading...</Loader></Dimmer>)
			: null}

            {!errorAlertmessage == "" ? (
              <Alert
                style={{
				  border: ".9px solid darkred",
                  zIndex: 1,
                  fontSize: 14,
                  marginBottom: 12,
                }}
                severity="error"
              >
                <b>{errorAlertmessage}</b>
              </Alert>
            ) : null}

			<Segment style={{border: ".9px solid #505050"}}>
				<div style={{display: "flex"}}>
			  <Form.Input
			  style={{width:"420px", borderRadius:0}}
				fluid
				required
				icon="key"
				iconPosition="left"
				label="Password"
				placeholder="Password..."
				name="password"
				type={showPassword? "text" : "password"}
				onChange={onChange}
			  />
			  <Button onClick={()=>setShowPassword(!showPassword)} style={{marginLeft:-4, height: 37, marginTop: 24}}>{showPassword ? "Hide" : "Show"}</Button>
			  </div>
			  <div style={{display: "flex"}}>
			  <Form.Input
			  style={{width:"420px", borderRadius:0}}
				fluid
				required
				icon="key"
				iconPosition="left"
				label="Confirm Password"
				placeholder="Confirm password..."
				name="confirmPassword"
				type={showPassword? "text" : "password"}
				onChange={onChange}
			  />
			  <Button onClick={()=>setShowPassword(!showPassword)} style={{marginLeft:-4, height: 37, marginTop: 24}}>{showPassword ? "Hide" : "Show"}</Button>
			  </div>
			  <Button
			    style={{float: "right", marginRight: 20, marginTop: 10}} 
			    primary
			  	disabled={disableNewPassword}
				icon="signup"
				type="submit"
				content="Submit"
			  />
<div style={{marginTop:10, marginLeft:10}}>
	{passwordLength ? (
        <div
          style={{
            color: "darkgreen",
            fontWeight: "bold",
            fontFamily: "Verdana, Geneva, Helvetica",
          }}
        >
          <i className="fas fa-check fa-xl"></i> Atleast 5 Characters
        </div>
      ) : (
        <div
          style={{
            color: "darkred",
            fontWeight: "bold",
            fontFamily: "Verdana, Geneva, Helvetica",
          }}
        >
          <i className="fas fa-remove fa-xl"></i> Atleast 5 Characters
        </div>
      )}

      {passwordNumber ? (
        <div
          style={{
            color: "darkgreen",
            fontWeight: "bold",
            fontFamily: "Verdana, Geneva, Helvetica",
          }}
        >
          <i className="fas fa-check fa-xl"></i> Includes Number
        </div>
      ) : (
        <div
          style={{
            color: "darkred",
            fontWeight: "bold",
            fontFamily: "Verdana, Geneva, Helvetica",
          }}
        >
          <i className="fas fa-remove fa-xl"></i> Includes Number
        </div>
      )}

		{passwordUppercase ? (
        <div
          style={{
            color: "darkgreen",
            fontWeight: "bold",
            fontFamily: "Verdana, Geneva, Helvetica",
          }}
        >
          <i className="fas fa-check fa-xl"></i> Includes Uppercase Character
        </div>
      ) : (
        <div
          style={{
            color: "darkred",
            fontWeight: "bold",
            fontFamily: "Verdana, Geneva, Helvetica",
          }}
        >
          <i className="fas fa-remove fa-xl"></i> Includes Uppercase Character
        </div>
      )}

      {passwordMatches ? (
        <div
          style={{
            color: "darkgreen",
            fontWeight: "bold",
            fontFamily: "Verdana, Geneva, Helvetica",
            marginBottom: 0,
          }}
        >
          <i className="fas fa-check fa-xl"></i> Password Matches
        </div>
      ) : (
        <div
          style={{
            color: "darkred",
            fontWeight: "bold",
            fontFamily: "Verdana, Geneva, Helvetica",
            marginBottom: 0,
          }}
        >
          <i className="fas fa-remove fa-xl"></i> Password Matches
        </div>
      )}
	  </div>
			</Segment>
		  </Form>
		  </div>
	  </div>
	)
}

export default ResetPassword;