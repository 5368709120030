import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { isEmpty } from "../../utils/Validation";
import Fragment from "../../utils/Fragment";
import "./Dashboard.css";
import * as actions from "../../store/actions/index";
// import Alert from "react-bootstrap/Alert";
import "./Reports.css";
import ExportReport from "./ExportReport";
import ExportVolunteerReport from "./ExportVolunteerReport";
// import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import moment from "moment";
const Reports = (props) => {
  const reduxState = useSelector((state) => state);
  const dispatch = useDispatch();

  const [eventName, setEventName] = useState(false);

  let authRedirect = null;
  if (!reduxState.auth.isAuthenticated) {
    authRedirect = <Redirect to="/" />;
  }

  let dashboard = reduxState.dashboard;
  let dashboardItems = null;

try{
    dashboardItems = (
      <Fragment>
        <div className="col-sm-6 offset-sm-3">
          <div className="card Empty">
            <h5 className="card-header">
              {dashboard.mesg ? dashboard.mesg.message : ""}
            </h5>
            <div></div>
          </div>
        </div>
        <div className="col-sm-3"></div>
      </Fragment>
    );
  } 
catch{
  console.log("Error loading component")
}

  let events = (
    <div className="noevents" style={{ textAlign: "center", padding: 30 }}>
      <h2 style={{ marginLeft: 20 }} className="noevents">
        No Events Created or Accesible...
      </h2>
    </div>
  );

  if (!isEmpty(reduxState.dashboard.dashboard) && reduxState.auth.isAuthenticated) {
    events = <h2 className="display-4 None">Not Empty.</h2>;

   // Chapter Specific Access
   let userChaptersAssigned = reduxState.auth.user.chapter_volunteer.map(
    (arr) => arr.chapters_assigned
  );

  let allowedevents = [];

  if(reduxState.auth.user.role === "Owner Original") {
    allowedevents = reduxState.dashboard.dashboard;
  }
  else if(reduxState.auth.user.chapter_volunteer.filter((item)=>item.chapters_assigned === "All Events").length > 0){
    allowedevents = reduxState.dashboard.dashboard;
  }
  else{
  let filteredevents = reduxState.dashboard.dashboard.filter(
    (item) => item.chapter_event
  );

  let dashboardchapters = reduxState.dashboard.dashboard.filter(
    (item) => item.chapter_dashboard.map((items)=>items.chapter_dash_names).some(r=> userChaptersAssigned.includes(r)))
  // arr1.some(r=> arr2.includes(r))
  console.log("dashboardchapters")
  console.log(dashboardchapters)

  allowedevents = dashboardchapters

  allowedevents.push(
    reduxState.dashboard.dashboard.filter(
      (item) => item.chapter_dashboard.map((items)=>items.chapter_dash_names).some(r => r === "All Users"))[0]
  );

  allowedevents.push(
    filteredevents.filter((item) => item.chapter_event === "All Users")[0]
  );

  // Remove any undefined
  let undefinedfilter = allowedevents.filter((item)=>item !== undefined)
    allowedevents = undefinedfilter
  }
    events = allowedevents.map((item) => (
      <div
        className="d-flex flex-row mb-3"
        style={{
          margin: 10,
          paddingLeft: 30,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <Button
          id="eventlist"
          variant="light"
          className="eventlist"
          style={{
            paddingRight: 30,
            paddingLeft: 15,
            paddingBottom: 10,
            opacity: 0.8,
            boxShadow: "3px 3px 9px dimgray",
            border: ".9px solid gray",
            borderRadius: 10,
            minWidth: 280,
            fontFamily: "Verdana",
          }}
          // onClick={() => showEvent(item._id)}
        >
          <div
            style={{
              paddingRight: 5,
              paddingBottom: 3,
              paddingTop: 3,
              fontSize: "13px",
            }}
          >
            <div className="row" style={{ marginLeft: 3 }}>
              <b className="">Event Name: </b>
              {item.name}
            </div>
            <div style={{ marginLeft: 3 }}>
              <b>Location:</b>{" "}
              {item.location.length < 35
                ? item.location
                : item.location.slice(0, 35) + "..."}
            </div>
            {item.startdate == null ? (
              <div style={{ marginLeft: 3 }}>
                <b>Start Date:</b>
              </div>
            ) : (
              <div style={{ marginLeft: 3 }}>
                <b>Start Date:</b>{" "}
                {moment(item.startdate).utc().format("MM/D/YY")}
              </div>
            )}
            {item.enddate == null ? (
              <div style={{ marginLeft: 3 }}>
                <b>End Date:</b>
              </div>
            ) : (
              <div style={{ marginLeft: 3 }}>
                <b>End Date:</b> {moment(item.enddate).utc().format("MM/D/YY")}
              </div>
            )}
            {item.timezone === "" ? (
              <div className="additionaldetails_label">
                <b>Timezone:</b> {item.timezone}
              </div>
            ) : (
              <div className="additionaldetails_label">
                <b>Timezone:</b>{" "}
                {item.timezone.length < 35
                  ? item.timezone
                  : item.timezone.slice(0, 35) + "..."}
              </div>
            )}

            <ExportReport dashboard={item.lists} eventName={item.name} />
          </div>
        </Button>
      </div>
    ));
  }

  let reportevents = (
    <div className="noevents" style={{ textAlign: "center", padding: 30 }}>
      <h2 style={{ marginLeft: 20 }} className="noevents">
        No Events Created or Accesible...
      </h2>
    </div>
  );

  if (!isEmpty(reduxState.dashboard.dashboard) && reduxState.auth.isAuthenticated) {
    reportevents = <h2 className="display-4 None">Not Empty.</h2>;
     // Chapter Specific Access
     let userChaptersAssigned = reduxState.auth.user.chapter_volunteer.map(
      (arr) => arr.chapters_assigned
    );

    let allowedevents = [];

    if(reduxState.auth.user.role === "Owner Original") {
      allowedevents = reduxState.dashboard.dashboard;
    }
    else if(reduxState.auth.user.chapter_volunteer.filter((item)=>item.chapters_assigned === "All Events").length > 0){
      allowedevents = reduxState.dashboard.dashboard;
    }
    else{
    let filteredevents = reduxState.dashboard.dashboard.filter(
      (item) => item.chapter_event
    );

    let dashboardchapters = reduxState.dashboard.dashboard.filter(
      (item) => item.chapter_dashboard.map((items)=>items.chapter_dash_names).some(r=> userChaptersAssigned.includes(r)))
    // arr1.some(r=> arr2.includes(r))
    console.log("dashboardchapters")
    console.log(dashboardchapters)

    allowedevents = dashboardchapters

    allowedevents.push(
      reduxState.dashboard.dashboard.filter(
        (item) => item.chapter_dashboard.map((items)=>items.chapter_dash_names).some(r => r === "All Users"))[0]
    );

    allowedevents.push(
      filteredevents.filter((item) => item.chapter_event === "All Users")[0]
    );

    // Remove any undefined
    let undefinedfilter = allowedevents.filter((item)=>item !== undefined)
      allowedevents = undefinedfilter
    }

    console.log("prerender allowedevents")
console.log(allowedevents)

if(allowedevents != undefined || allowedevents != null ){
    reportevents = allowedevents.map((item) => (
      <div
        className="d-flex flex-row mb-3"
        style={{
          margin: 10,
          paddingLeft: 30,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <Button
          id="eventlist"
          variant="light"
          className="eventlist"
          style={{
            paddingRight: 30,
            paddingLeft: 15,
            paddingBottom: 10,
            opacity: 0.8,
            boxShadow: "3px 3px 9px dimgray",
            border: ".9px solid gray",
            borderRadius: 10,
            minWidth: 280,
            fontFamily: "Verdana",
          }}
          // onClick={() => showEvent(item._id)}
        >
          <div
            style={{
              paddingRight: 5,
              paddingBottom: 3,
              paddingTop: 3,
              fontSize: "13px",
            }}
          >
            <div className="row" style={{ marginLeft: 3 }}>
              <b className="">Event Name: </b>
              {item.name !== undefined || item.name !== undefined ?
               item.name : null}
            </div>

            <div style={{ marginLeft: 3 }}>
              <b>Location:</b>{" "}
              {item.location.length < 35
                ? item.location
                : item.location.slice(0, 35) + "..."}
            </div>
            {item.startdate == null ? (
              <div style={{ marginLeft: 3 }}>
                <b>Start Date:</b>
              </div>
            ) : (
              <div style={{ marginLeft: 3 }}>
                <b>Start Date:</b>{" "}
                {moment(item.startdate).utc().format("MM/D/YY")}
              </div>
            )}
            {item.enddate == null ? (
              <div style={{ marginLeft: 3 }}>
                <b>End Date:</b>
              </div>
            ) : (
              <div style={{ marginLeft: 3 }}>
                <b>End Date:</b> {moment(item.enddate).utc().format("MM/D/YY")}
              </div>
            )}
            {item.timezone === "" ? (
              <div className="additionaldetails_label">
                <b>Timezone:</b> {item.timezone}
              </div>
            ) : (
              <div className="additionaldetails_label">
                <b>Timezone:</b>{" "}
                {item.timezone.length < 35
                  ? item.timezone
                  : item.timezone.slice(0, 35) + "..."}
              </div>
            )}

            <ExportVolunteerReport dashboard={item.lists} eventName={item.name} />
          </div>
        </Button>
      </div>
    ));
   }
  }

  return (
    <div>
       <ToastContainer
        position="top-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div>
        {/* {reduxState.dashboard.dashMessage !== "" ? (
          <Alert variant="danger">
            <div style={{ textAlign: "center" }}>
              {reduxState.dashboard.dashMessage}
            </div>
          </Alert>
        ) : null} */}

        {authRedirect}
          <div>
            {reduxState.auth.user.role === "user" || "admin" ? (
              <div>
                <h1
                  className="eventlist_titles"
                  style={{
                    textAlign: "center",
                    marginTop: 30,
                    marginBottom: 15,
                  }}
                >
                  <b style={{ fontFamily: "Verdana, Geneva, Helvetica" }}>
                    Reports Center
                  </b>
                </h1>
              </div>
            ) : null}
            <hr className="solid"></hr>
            <div style={{ paddingLeft: 40, paddingTop: 15, paddingBottom: 10 }}>
              <h2 style={{ color: "#505050" }}>Event Reports</h2>
            </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {events}{" "}
              </div>
            <hr className="solid"></hr>
            <div style={{ paddingLeft: 40, paddingTop: 15, paddingBottom: 10 }}>
              <h2 style={{ color: "#505050" }}>Volunteer Reports</h2>
            </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {reportevents}{" "}
              </div>
          </div>
      </div>
    </div>
  );
};

export default Reports;
