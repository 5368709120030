

import axios from 'axios'
import * as actionType from './actionTypes'

export const setErrorMessageEvents = (data) => {
  return {
    type: actionType.SUBSCRIBE_EVENT,
    payload: {
      data: data
    }
  }
}

export const fetchDashboard = (currentSortName) => dispatch => {
  axios.get('/api/dashboard/', {params: { sortfield: currentSortName }})
    .then(res => {
      dispatch(setDashboard(res.data))

    })
    .catch(err => {
      console.log(err.response)
    })
}

export const createDashboard = (userData) => dispatch => {
  axios.post('/api/dashboard/create', userData)
    .then(res => {
      dispatch(setDashboard(res.data))
    })
    .catch(err => {
      console.log(err.response)
      dispatch({
        type: actionType.SUBSCRIBE_EVENT
      })
    })
}


export const setDashboard = (data) => {
  return {
    type: actionType.DASHBOARD_CREATE,
    payload: {
      dashboard: data.dashboard,
      mesg: data.mesg
    }
  }
}

export const editDashboard = (dashId, dashName, location, startdate, enddate, starttime, endtime, timezone, additionaldetails, chapter_dashboard) => dispatch => {
  axios.put('/api/dashboard/update/'+ dashId, {name: dashName, location: location, startdate: startdate, enddate: enddate,
  starttime: starttime, endtime: endtime, timezone: timezone, additionaldetails: additionaldetails, chapter_dashboard: chapter_dashboard })
    .then(res =>{
      console.log("redux chapter_dashboard")
      console.log(chapter_dashboard)
      dispatch(setDashboard(res.data))
    })  
    .catch(err => {
      console.log(err.response)
    })
}

export const deleteDashboard = (dashId) => dispatch => {
  axios.delete('/api/dashboard/delete/'+ dashId)
    .then(res =>{
        dispatch(setDashboard(res.data))
    })  
    .catch(err => {
      console.log(err.response)
    })
}

export const cloneDashboard = (dashId) => dispatch => {
  axios.post('/api/dashboard/clone/'+ dashId)
    .then(res =>{
        dispatch(setDashboard(res.data))
    })  
    .catch(err => {
      console.log(err.response)
    })
}

export const onLoadApp = () =>{
  
}