import React, { Component } from 'react';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import setAuthToken from './utils/setAuthToken'
import jwt_decode from 'jwt-decode'
import {logoutUser, setCurrentUser, fetchDashboard} from './store/actions/index'
import store from './store'
// import Backdrop from './components/Backdrop/Backdrop'
import Fragment from  './utils/Fragment'
import Navbar from './containers/Navbar/Navbar'
import Dashboard from './containers/Dashboard/DashboardPage'
import Reports from './containers/Reports/Reports'
import Activity from './containers/Activity/Activity'
import Login from './components/Auth/Login/Login'
import Signup from './components/Auth/Signup/Signup'
import Landing from './components/Landing/Landing'
import './App.css';
import * as action from './store/actions/index'
import CheckoutForm from './components/CheckOutForm/PaymentsParentdonate'
import SubscriptionForm from './components/SubscriptionForm/PaymentsParent'
import PasswordReset from './components/Auth/Reset/PasswordReset'
import PasswordForgot from './components/Auth/Reset/PasswordForgot'
import ResetPassword from './components/Auth/Reset/ResetPassword'
// import LatestPassword from './componentss/Auth/Reset/LatestPassword'
import ProfilePage from './components/Profile/Profile'
import VolunteersPage from './components/Auth/Volunteer/VolunteersPage'
import ChaptersPage from './components/Auth/Chapters/ChaptersPage'
import PricingPage from './components/Pricing'
import Privacy from './components/Privacy'
import Analyticz from './containers/Analyticz/DashboardPage'
import Import from './containers/NewImport/DashboardPage'
import Contact from './containers/Contact/Contact'
import NotFoundPage from './containers/NotFoundPage/NotFoundPage'
import Invite from './containers/Invite/Invite'
import VerifyEmail from './components/Auth/Reset/VerifyEmail';
import Office from './containers/Office/Office'
import Guests from './containers/Guests/Guests.js'
import DashboardLists from './containers/Dashboard/DashboardLists/DashboardLists';
import AnalyticsLists from './containers/Analyticz/DashboardLists/DashboardLists';
import ImportLists from './containers/NewImport/DashboardLists/DashboardLists';
// import PrintTickets from './containers/Invite/PrintTickets'
import Subscribe from './components/Subscribe/Subscribe'
import Scrolling from './containers/Scrolling/Scroll'
import "react-table/react-table.css";

if(localStorage.jwtToken){
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded))
  store.dispatch(fetchDashboard())

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = '/login';
  }
}


class App extends Component {

  render() {
    let routes = (
      <Switch>
        <Route exact path='/signup' component={Signup} />
        <Route exact path='/login' component={Login} />
        <Route exact path='/' component={Landing} />
        <Route exact path='/dashboard' component={Dashboard} />
        <Route exact path='/analytics' component={Analyticz} />
        <Route exact path='/analytics/:eventId' component={AnalyticsLists} />
        {/* <Route exact path='/printtickets/:eventId' component={PrintTickets} /> */}
        <Route exact path='/import' component={Import}/>
        <Route exact path='/import/:eventId' component={ImportLists} />
        {/* <Route exact path='/printtickets/:eventId' component={PrintTickets} /> */}
        <Route exact path='/reports' component={Reports} />
        <Route exact path='/activity' component={Activity} />
        <Route exact path='/donation' component={CheckoutForm} />
        <Route exact path='/subscribe' component={Subscribe} />
        <Route exact path='/scrolling' component={Scrolling} />
        <Route exact path='/subscription' component={SubscriptionForm} />
        <Route exact path='/resetPassword/:email/:token' component={PasswordReset} />
        <Route exact path='/guests/:eventId/:guestId' component={Guests} />
        <Route exact path='/events/:eventId' component={DashboardLists} />
        <Route exact path='/forgotpassword' component={PasswordForgot} />
        <Route exact path='/resetpassword' component={ResetPassword} />
        <Route exact path='/verifyemail/:email/:token' component={VerifyEmail} />
        <Route exact path='/office' component={Office} />
        {/* <Route exact path='/resetpassword/:token' component={LatestPassword}/> */}
        <Route exact path='/profile' component={ProfilePage} />
        <Route exact path='/volunteers' component={VolunteersPage} />
        <Route exact path='/chapters' component={ChaptersPage} />
        <Route exact path='/pricing' component={PricingPage} />
        <Route exact path='/privacy' component={Privacy} />
        <Route exact path='/contact' component={Contact} />
        <Route exact path='/invite' component={Invite} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    )
    if(this.props.isAuthenticated){
      routes = (
        <Switch>
          <Route exact path='/' component={Landing} />
          <Route exact path='/dashboard' component={Dashboard} />
          <Route exact path='/contact' component={Contact} />
          <Route path="/404" component={NotFoundPage} />
          <Route exact path='/verifyemail/:email/:token' component={VerifyEmail} />
          <Redirect to='/' />
        </Switch>
      )
    }

    return (
    <BrowserRouter>
      <div className="App">
        {/* <Backdrop /> */}
        <Fragment className="Content">
          {!this.props.isAuthenticated ? 
            <Navbar  /> : null}
          <div>
            {routes}
          </div>
        </Fragment>
      </div>
    </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.isAuthenticated
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    user: () => { dispatch(action.getUser()) },
  }
 
}
export default connect(mapStateToProps, mapDispatchToProps)(App);