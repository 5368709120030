import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { connect } from "react-redux";
import * as action from "../../store/actions/index";
import csvDownload from "json-to-csv-export";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";

export class DeleteEventMod extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  state = {
    deleteId: 0,
    deletebutton: true,
    deletebuttontext: "",
  };

  componentDidMount() {
    if (this.state.deletebuttontext === "delete") {
      this.setState({ deletebutton: false });
    }

  }

  onSubmit = () => {

    let today_date = moment(Date.now()).format("MM-D-YY")
    console.log('help')
    if ((this.props.dashboards.dashboard.filter((item) => item._id === this.props.dashboards.eventid)[0].lists).length > 0) {
    csvDownload(this.props.dashboards.dashboard.filter((item) => item._id === this.props.dashboards.eventid)[0].lists, `Backup_Event_Report_${this.props.dashboards.dashboard.filter((item) => item._id === this.props.dashboards.eventid)[0].name}_${today_date}.csv`)
    }

    try {
      this.props.deleteDashboard(this.props.dashboards.eventid);
      // this.props.deleteDashboard(this.props.itemId);

      toast.success(
        <div style={{ fontSize: "16px" }}>
          <b>Event successfully deleted!</b>
        </div>,
        {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    } catch {
      toast.error(
        <div style={{ fontSize: "15px" }}>
          <b>An error has occured with deleting the event.</b>
        </div>,
        {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
    this.props.onHide();
  };

  onChange = (e) => {
    this.setState({ deletebuttontext: e.target.value });
 
    if (e.target.value.toLowerCase() === "delete") {
      this.setState({ deletebutton: false });
    } else {
      this.setState({ deletebutton: true });
    }
  };

  render() {
    let onSubmit = this.onSubmit.bind(this);

    const { dashboard, filename, ...others } = this.props;

    // return (
    //   <button
    //   style={{width:"100%", fontWeight: 'bold', border: '1px solid darkgray', backgroundColor:"whitesmoke"}}
    //   className="btn"
    //   onClick={() => csvDownload(dashboard, filename)} {...others}>
    //     {props.children || 'Download Event Report - CSV'}
    //   </button>
    // )

    return (
      <div>
        <ToastContainer
          position="top-left"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Delete Event
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <form style={{ display: "flex" }}>
                <div>
                  <p>
                    <b>Are you Sure you would like to Delete this Event?</b>
                    <br />
                    (This Action cannot be undone. A report will automatically
                    be downloaded upon deletion for backup.)
                  </p>

                  <br />
                  <p>
                    You must type <b>Delete</b> in the input below in order to
                    Delete.
                  </p>
                  <input
                    type="text"
                    name="password"
                    className="form-control"
                    onChange={this.onChange}
                  />
                </div>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={this.state.deletebutton}
              variant="success"
              onClick={onSubmit}
            >
              Confirm Deletion
            </Button>

            <Button variant="danger" onClick={this.props.onHide}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    dashboards: state.dashboard,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
};

const mapFetchToProps = (dispatch) => {
  return {
    deleteDashboard: (id) => {
      dispatch(action.deleteDashboard(id));
    },
  };
};

export default connect(mapStateToProps, mapFetchToProps)(DeleteEventMod);
